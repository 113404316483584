import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import HeaderDesktop from "../../../components/Desktop/Header/Header";
import Footer from "../../../components/Footer/Footer";
import LoginDesktop from "../../../components/Login/Login/Login";

class Login extends Component {

  render() {

    var previousInCheckout = false;
    var pageToRedirectAfterLogin = "/";

    var state = this.props.location.state;
    if (typeof state !== 'undefined' && state != null) {
      if (typeof state.previousInCheckout !== 'undefined' && state.previousInCheckout === true) {
        previousInCheckout = true;
      }

      if (typeof state.pageToRedirectAfterLogin !== 'undefined' && state.pageToRedirectAfterLogin !== '') {
        pageToRedirectAfterLogin = state.pageToRedirectAfterLogin;
      }
    }

    return (
      <div className="App">
        <HeaderDesktop />
        <Container className="mainContentContainer">
          <Row style={{ textAlign: "center" }} >
            <Col xs="12" sm="12" md="12" lg="12">
              <LoginDesktop history={this.props.history} previousInCheckout={previousInCheckout} pageToRedirectAfterLogin={pageToRedirectAfterLogin} />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default connect(mapStateToProps)(Login);
