import { FormattedMessage } from "react-intl";
import React from "react";
import { Row, Col } from "react-bootstrap";
import ArrowStatus from "../../components/Desktop/MyIHTP/Conteudo/Dashboard/IndicadoresDashboard/ArrowStatus";

import "moment/locale/pt";
var moment = require("moment");
moment.locale("pt");

class InformacoesPessoaisConfig {
  constructor() {
    this.contactos = {
      columns: [
        {
          Header: "ID",
          accessor: "ID_ClienteContacto",
          show: false,
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.infopessoal.contactos.tipoContacto" />
          ),
          accessor: "TipoContacto",
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.infopessoal.contactos.Descricao" />
          ),
          accessor: "Descricao",
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.infopessoal.contactos.Principal" />
          ),
          accessor: "Principal",
          resizable: false,
          Cell: row => {
            return (
              <span>
                {row.original.Principal.startsWith("S") === true ? (
                  <FormattedMessage id="myihtp.infopessoal.moradas.sim" />
                ) : (
                  <FormattedMessage id="myihtp.infopessoal.moradas.nao" />
                )}
              </span>
            )
          }
        },
        {
          Header: "",
          accessor: "ID_ContactCliente",
          resizable: false,
          width: 50,
          Cell: row => {
            return (
              <div>
                <a>
                  <svg
                    height="15px"
                    viewBox="0 0 512 512.00115"
                    width="15px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m441.378906 70.621094h-105.929687v-34.691406c0-19.808594-16.121094-35.929688-35.929688-35.929688h-140.003906c-19.808594 0-35.929687 16.121094-35.929687 35.929688v34.691406h-105.929688c-9.75 0-17.65625 7.894531-17.65625 17.65625 0 9.757812 7.90625 17.652344 17.65625 17.652344h18.40625l14.652344 336.96875c1.679687 38.757812 33.386718 69.101562 72.175781 69.101562h213.257813c38.785156 0 70.488281-30.34375 72.171874-69.101562l14.652344-336.96875h18.40625c9.75 0 17.65625-7.894532 17.65625-17.652344 0-9.761719-7.90625-17.65625-17.65625-17.65625zm-281.863281-35.3125h140.003906l.617188 35.3125h-141.03125zm213.527344 406.070312c-.863281 19.792969-17.066407 35.3125-36.898438 35.3125h-213.257812c-19.828125 0-36.035157-15.519531-36.898438-35.3125l-14.585937-335.449218h316.226562zm0 0" />
                    <path d="m229.515625 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                    <path d="m300.136719 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                    <path d="m158.898438 406.070312c-9.753907 0-17.65625-7.898437-17.65625-17.65625v-211.863281c0-9.757812 7.902343-17.652343 17.65625-17.652343 9.75 0 17.652343 7.894531 17.652343 17.652343v211.863281c0 9.757813-7.902343 17.65625-17.652343 17.65625zm0 0" />
                  </svg>
                </a>
              </div>
            );
          }
        }
      ]
    };

    this.moradas = {
      columns: [
        {
          Header: (
            <FormattedMessage id="myihtp.infopessoal.moradas.ID_TipoMorada" />
          ),
          accessor: "TipoMorada"
        },
        {
          Header: <FormattedMessage id="myihtp.infopessoal.moradas.Morada" />,
          accessor: "Morada"
        },
        {
          Header: (
            <FormattedMessage id="myihtp.infopessoal.moradas.Localidade" />
          ),
          accessor: "Localidade"
        },
        {
          Header: (
            <FormattedMessage id="myihtp.infopessoal.moradas.CodigoPostal" />
          ),
          accessor: "CodigoPostal"
        },
        {
          Header: <FormattedMessage id="myihtp.infopessoal.moradas.Pais" />,
          accessor: "Pais"
        },
        {
          Header: (
            <FormattedMessage id="myihtp.infopessoal.moradas.Principal" />
          ),
          accessor: "PrincipalDesc",
          Cell: row => {
            return (
              <span>
                {row.original.PrincipalDesc.startsWith("S") ? (
                  <FormattedMessage id="myihtp.infopessoal.moradas.sim" />
                ) : (
                  <FormattedMessage id="myihtp.infopessoal.moradas.nao" />
                )}
              </span>
            )
          }
        },
        {
          Header: "",
          accessor: "ID_MoradaCliente",
          width: 50,
          Cell: row => {
            return (
              <div>
                <a>
                  <svg
                    height="15px"
                    viewBox="0 0 512 512.00115"
                    width="15px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m441.378906 70.621094h-105.929687v-34.691406c0-19.808594-16.121094-35.929688-35.929688-35.929688h-140.003906c-19.808594 0-35.929687 16.121094-35.929687 35.929688v34.691406h-105.929688c-9.75 0-17.65625 7.894531-17.65625 17.65625 0 9.757812 7.90625 17.652344 17.65625 17.652344h18.40625l14.652344 336.96875c1.679687 38.757812 33.386718 69.101562 72.175781 69.101562h213.257813c38.785156 0 70.488281-30.34375 72.171874-69.101562l14.652344-336.96875h18.40625c9.75 0 17.65625-7.894532 17.65625-17.652344 0-9.761719-7.90625-17.65625-17.65625-17.65625zm-281.863281-35.3125h140.003906l.617188 35.3125h-141.03125zm213.527344 406.070312c-.863281 19.792969-17.066407 35.3125-36.898438 35.3125h-213.257812c-19.828125 0-36.035157-15.519531-36.898438-35.3125l-14.585937-335.449218h316.226562zm0 0" />
                    <path d="m229.515625 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                    <path d="m300.136719 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                    <path d="m158.898438 406.070312c-9.753907 0-17.65625-7.898437-17.65625-17.65625v-211.863281c0-9.757812 7.902343-17.652343 17.65625-17.652343 9.75 0 17.652343 7.894531 17.652343 17.652343v211.863281c0 9.757813-7.902343 17.65625-17.652343 17.65625zm0 0" />
                  </svg>
                </a>
              </div>
            );
          }
        }
      ]
    };
  }
}

var Config = new InformacoesPessoaisConfig();

export default Config;
