import { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedDate, FormattedNumber, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer } from "react-toastr";
import { getOrderDetails, getOrders } from "../../../../../actions/myihtpActions";
// import { refund } from "../../../../../Analytics/DataLayer";
import Configs from "../../../../../helpers/Configs";
import OrderDetail from "../../../../Common/Order/OrderDetail";
import CancelarEncomendaBotao from "./CancelarEncomendaBotao";
import "./Encomendas.css";
import FaturaBotao from "./FaturaBotao";
import { cancelOrder } from "./Helper";
import NotaCreditoBotao from "./NotaCreditoBotao";
import PagamentoBotao from "./PagamentoBotao";
import ReciboBotao from "./ReciboBotao";

let container;
class Encomendas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      details: {},
      itens: [],
      estados: null,
      expanded: {},
      loadingDetails: false
    };

    this.cancelOrder = this.cancelOrder.bind(this);
  }

  componentDidMount() {
    getOrders().then(data => {
      if (!data.data.error) {
        this.setState({
          orders: data.data.orders
        });
      }
    });
  }

  cancelOrder = id => {
    var confirm = window.confirm(
      this.props.intl.formatMessage({
        id: "myihtp.encomenda.cancelar"
      })
    );
    if (confirm) {
      cancelOrder(id).then(result => {

        //Se der sucesso ao cancelar a encomenda
        if (result.success === true) {

          //Cancela no GA4
          // refund(id);

          //Vamos buscar as encomendas atualizadas
          getOrders().then(data => {
            if (!data.data.error) {
              this.setState({
                orders: data.data.orders
              });
            }
          });

          container.clear();
          container.success(result.message);
        } else {
          container.clear();
          container.error(result.message);
        }
      });
    }
  };

  updateOrders() {
    getOrders().then(data => {
      if (!data.data.error) {
        this.setState({
          orders: data.data.orders
        });
      }
    });
  }

  handleRowExpanded(rowsState, index) {
    this.setState({
      expanded: {
        [index[0]]: !this.state.expanded[index[0]]
      }
    });
  }

  getOrderDetailsData = (id, id_cliente) => async e => {

    this.setState(prevState => ({
      ...prevState,
      loadingDetails: true
    }));

    getOrderDetails(id, id_cliente)
      .then(data => {

        this.setState(prevState => ({
          ...prevState,
          loadingDetails: false
        }));

        if (data.success === true) {
          this.setState({ details: data.obj.Detalhes[0] });
          this.setState({ itens: data.obj.Itens });
          this.setState({ estados: data.obj.Estados });
        } else {
          container.clear();
          container.error(data.message);
        }
      })
      .catch(error => {
        console.log(error);
        this.setState(prevState => ({
          ...prevState,
          loadingDetails: false
        }));
      });
  };


  getStates = () => {
    let result = [];

    if (this.state.estados == null || this.state.estados.length <= 0) return result;

    result = this.state.estados.map(e => {
      return {
        date: e.DataMudancaEstado,
        status: e.EstadoEncomenda,
        notes: e.Observacao,
        sentEmail: e.EnviadoMail
      }
    })

    return result;
  }

  getShippingAddress = () => {
    if (this.state.details.moradaEnvioMorada == null) return null;

    return {
      address: this.state.details.moradaEnvioMorada,
      addressName: this.state.details.moradaEnvioNome,
      postalCode: this.state.details.moradaEnvioCodigoPostal,
      location: this.state.details.moradaEnvioLocalidade,
      country: this.state.details.moradaEnvioPais
    }
  }

  render() {
    const { currencies, user } = this.props;
    const columns = [
      {
        Header:
          Configs.myIHTP.tableConfig.encomendas.columns.idEncomenda.Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.idEncomenda.accessor
      },
      {
        Header:
          Configs.myIHTP.tableConfig.encomendas.columns.idEstadoEncomenda
            .Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.idEstadoEncomenda
            .accessor,
        show:
          Configs.myIHTP.tableConfig.encomendas.columns.idEstadoEncomenda.show
      },
      {
        Header:
          Configs.myIHTP.tableConfig.encomendas.columns.estadoEncomenda.Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.estadoEncomenda
            .accessor,
        show: !isMobile
      },
      {
        Header:
          Configs.myIHTP.tableConfig.encomendas.columns.dataEncomenda.Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.dataEncomenda.accessor,
        show: !isMobile,
        Cell: row => {
          return row.original.DataEncomenda ? (
            <FormattedDate value={new Date(row.original.DataEncomenda)} />
          ) : (
            "-"
          );
        }
      },
      {
        Header: Configs.myIHTP.tableConfig.encomendas.columns.precoFinal.Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.precoFinal.accessor,
        Cell: row => {
          return (
            <FormattedNumber
              value={row.original.PrecoFinal}
              style="currency"
              currency={row.original.ID_Moeda}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          );
        }
      },
      {
        Header: "F",
        accessor: "fatura",
        width: 50,
        Cell: row => {
          if (row.original.UrlFatura) {
            return (
              <div>
                <FaturaBotao url={row.original.UrlFatura} />
              </div>
            );
          } else {
            return <div />;
          }
        },
        resizable: false
      },
      {
        Header: "R",
        accessor: "recibo",
        width: 50,
        Cell: row => {
          if (row.original.UrlRecibo) {
            return <ReciboBotao url={row.original.UrlRecibo} />;
          } else {
            return <div />;
          }
        },
        resizable: false
      },
      {
        Header: "NC",
        accessor: "notaCredito",
        width: 50,
        Cell: row => {
          if (row.original.UrlNotaCredito) {
            return <NotaCreditoBotao url={row.original.UrlNotaCredito} />;
          } else {
            return <div />;
          }
        },
        resizable: false
      },
      {
        Header: "P",
        accessor: "pagamento",
        width: 50,
        Cell: row => {
          if (
            !row.original.UrlFatura &&
            !row.original.UrlRecibo &&
            !row.original.NotaCredito
          ) {
            return <PagamentoBotao hash={row.original.HashEncomenda} />;
          } else {
            return <div />;
          }
        },
        resizable: false
      },
      {
        Header: "C",
        accessor: "cancelarEncomenda",
        width: 50,
        Cell: row => {
          if (row.original.ID_EstadoEncomenda === "AP") {
            return (
              <CancelarEncomendaBotao
                id={row.original.ID_Encomenda}
                cancelOrder={this.cancelOrder}
              />
            );
          } else {
            return <div />;
          }
        },
        resizable: false
      }
    ];
    return (
      <Row>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <Col xs="12" sm="12" md="12" lg="12">
          <Row style={{ textAlign: "left" }}>
            <Col xs="12" sm="12" md="12" lg="12">
              <ReactTable
                className="-highlight tableReact"
                data={this.state.orders}
                expanded={this.state.expanded}
                onExpandedChange={(newExpanded, index, event) =>
                  this.handleRowExpanded(newExpanded, index, event)
                }
                getTheadProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                      fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                      height: Configs.myIHTP.tableConfig.heightHeader,
                      color: Configs.myIHTP.tableConfig.colorHeader,
                      backgroundColor:
                        Configs.myIHTP.tableConfig.backgroundColorHeader
                    }
                  };
                }}
                getPaginationProps={state => {
                  return {
                    style: {
                      color: Configs.myIHTP.tableConfig.colorHeader,
                      backgroundColor:
                        Configs.myIHTP.tableConfig.backgroundColorHeader,
                      fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
                    }
                  };
                }}
                getTrProps={(state, rowInfo, column, instance) => {
                  var id_encomenda = null;
                  var id_cliente = null;
                  if (rowInfo) {
                    id_encomenda = rowInfo.original.ID_Encomenda;
                    id_cliente = rowInfo.original.ID_Cliente;
                  }

                  return {
                    onClick: this.getOrderDetailsData(id_encomenda, id_cliente),
                    style: {
                      "text-align": "center",
                      "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
                      background:
                        (rowInfo && rowInfo.row.ID_EstadoEncomenda === "AP"
                          ? Configs.myIHTP.tableConfig.encomendas
                            .backgroundColor.yellow
                          : null) ||
                        (rowInfo &&
                          (rowInfo.row.ID_EstadoEncomenda === "PA" ||
                            rowInfo.row.ID_EstadoEncomenda === "PR" ||
                            rowInfo.row.ID_EstadoEncomenda === "EC")
                          ? Configs.myIHTP.tableConfig.encomendas
                            .backgroundColor.green
                          : null) ||
                        (rowInfo &&
                          (rowInfo.row.ID_EstadoEncomenda === "CC" ||
                            rowInfo.row.ID_EstadoEncomenda === "PC")
                          ? Configs.myIHTP.tableConfig.encomendas
                            .backgroundColor.red
                          : null) ||
                        (rowInfo && rowInfo.row.ID_EstadoEncomenda === "FE"
                          ? Configs.myIHTP.tableConfig.encomendas
                            .backgroundColor.cyan
                          : null)
                    }
                  };
                }}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    style: {
                      "text-align": "center",
                      "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
                    }
                  };
                }}
                defaultSorted={[
                  {
                    id: "ID_Encomenda",
                    desc: true
                  }
                ]}
                collapseOnDataChange={
                  Configs.myIHTP.tableConfig.collapseOnDataChange
                }
                defaultPageSize={20}
                showPagination={
                  this.state.orders.length >
                    Configs.myIHTP.tableConfig.defaultPageSize
                    ? Configs.myIHTP.tableConfig.showPagination
                    : false
                }
                previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
                nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
                noDataText={Configs.myIHTP.tableConfig.noDataText}
                pageText={Configs.myIHTP.tableConfig.pageText}
                ofText={Configs.myIHTP.tableConfig.ofText}
                rowsText={this.props.intl.formatMessage({ id: "table.config.rowsText" })}
                columns={columns}
                SubComponent={row => {

                  return (
                    <OrderDetail
                      loading={this.state.loadingDetails}
                      orderId={row.original.ID_Encomenda}
                      orderDate={this.state.details.DataEncomenda}
                      state={this.state.details.EstadoEncomenda}
                      transportTypeDescription={this.state.details.DescricaoTipoTransporte}
                      paymentTypeId={this.state.details.ID_TipoPagamento}
                      paymentTypeDescription={this.state.details.DescricaoTipoPagamento}
                      paymentDate={this.state.details.DataPagamento}
                      sentDate={this.state.details.DataEnvio}
                      address={this.getShippingAddress()}
                      billingAddress={{
                        address: this.state.details.moradaFaturacaoMorada,
                        addressName: this.state.details.moradaFaturacaoNome,
                        vat: this.state.details.moradaFaturacaoNif,
                        postalCode: this.state.details.moradaFaturacaoCodigoPostal,
                        location: this.state.details.moradaFaturacaoLocalidade,
                        country: this.state.details.moradaFaturacaoPais
                      }}
                      currency={this.state.details.ID_Moeda}
                      totalPrice={this.state.details.PrecoTotal}
                      totalDiscount={this.state.details.DescontoTotal}
                      totalTax={this.state.details.IvaTotal}
                      transportCost={this.state.details.CustoTransporte}
                      userType={user.userType}
                      creditDiscount={this.state.details.DescontoCredito}
                      finalPrice={this.state.details.PrecoFinal}
                      personalNetPoints={this.state.details.NetPointsPessoal}
                      notes={this.state.details.Observacao}
                      items={this.state.itens.map(i => {
                        return {
                          id: i.Id,
                          description: i.Descricao,
                          taxPercentage: i.TaxaIva,
                          quantity: i.Quantidade,
                          unitPriceWithoutTax: i.PrecoUnitarioSemIva,
                          unitPriceWithTax: i.PrecoUnitarioComIva
                        }
                      })}
                      states={this.getStates()}
                      mbEntity={this.state.details.EntidadeMB}
                      mbReference={this.state.details.ReferenciaMB}
                    />
                  );
                }}
              />
            </Col>
          </Row>
        </Col >
      </Row >
    );
  }
}

const mapStateToProps = state => {
  return {
    currencies: state.currencies,
    user: state.user
  };
};

export default injectIntl(withRouter(connect(mapStateToProps)(Encomendas)));
