import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap";
import { TextField, Button } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { addConsultantUnavailablePeriod } from "../../../../../../../actions/myihtpActions";
import { ToastContainer } from "react-toastr";
import { injectIntl, FormattedMessage } from 'react-intl';
import IHTPButton from '../../../../../../Common/IHTPButton';
let container;

class ConsultorPeriodoIndisponivel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id_consultor: '',
            date: [null, null],
            isAddingUnavailablePeriod: false
        }

        this.handleInput = this.handleInput.bind(this);
        this.handleChangeAvailablePeriod = this.handleChangeAvailablePeriod.bind(this);
    };

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    handleChangeAvailablePeriod = date => {
        this.setState(prevState => {
            return {
                ...prevState,
                date: date
            }
        })
    }

    //Função para adicionar o periodo de indisponibilidade a um Consultor 
    addUnavailablePeriodButtonClick = () => async e => {

        //Verificar Id de Consultor preenchido
        if (this.state.id_consultor == null || this.state.id_consultor.trim().length <= 0) {
            container.clear();
            container.error(this.props.intl.formatMessage({ id: "ferramentasUtilizador.periodoIndisponibilidade.consultorVazio" }));
            return;
        }

        //Verificar data vazia
        if (this.state.date[0] == null || this.state.date[1] == null) {
            container.clear();
            container.error(this.props.intl.formatMessage({ id: "ferramentasUtilizador.periodoIndisponibilidade.periodoVazio" }));
            return;
        }

        //Verificar se data fim é menor ou igual a data inicial
        if (this.state.date[1] <= this.state.date[0]) {
            container.clear();
            container.error(this.props.intl.formatMessage({ id: "ferramentasUtilizador.periodoIndisponibilidade.datas" }));
            return;
        }

        this.setState({
            isAddingUnavailablePeriod: true
        });

        addConsultantUnavailablePeriod(this.state.date[0], this.state.date[1], this.state.id_consultor)
            .then(result => {

                container.clear();
                if (result.success === true) {
                    container.success(result.message);

                    //Limpar dados de adicionar
                    this.setState({
                        date: [null, null],
                        id_consultor: ''
                    });

                } else {

                    if (typeof result.message === 'undefined') {
                        container.error(this.props.intl.formatMessage({ id: "ferramentasUtilizador.periodoIndisponibilidade.erroServidor" }));
                    } else {
                        container.error(result.message);
                    }
                }
            })
            .catch(err => {
                container.clear();
                container.error(this.props.intl.formatMessage({ id: "ferramentasUtilizador.periodoIndisponibilidade.erroServidor" }));
            })
            .finally(() => {
                this.setState({
                    isAddingUnavailablePeriod: false
                });
            });
    }


    render() {
        return (
            <div>

                <ToastContainer
                    ref={ref => (container = ref)}
                    className="toast-bottom-right"
                />

                {/* ROW para Adicionar Periodo de Indisponibilidade*/}
                <Row style={{ textAlign: "left" }}>
                    <Col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        style={{ textAlign: "center" }}
                    >
                        <span style={{ textAlign: "center" }}>
                            <b><FormattedMessage id="ferramentasUtilizador.periodoIndisponibilidade.titulo" /> </b>
                        </span>
                        <div className="form-group">
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <TextField
                                        placeholder={this.props.intl.formatMessage({ id: "ferramentasUtilizador.periodoIndisponibilidade.idConsultor" })}
                                        name={"id_consultor"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }}
                                        type="text"
                                        fullWidth={true}
                                        value={this.state.id_consultor}
                                        onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center", display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }} >
                                    <label style={{ marginBottom: "0px", marginRight: "10px" }}> <FormattedMessage id="ferramentasUtilizador.periodoIndisponibilidade.texto" /></label>
                                    <br /><br />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <DateTimeRangePicker value={this.state.date}
                                        name="date" id="date" onChange={this.handleChangeAvailablePeriod} />
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row style={{ justifyContent: "center" }}>
                                <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{ textAlign: " center" }}>
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "ferramentasUtilizador.periodoIndisponibilidade.adicionarBotao" })}
                                        toUpper={true}
                                        onClick={this.addUnavailablePeriodButtonClick()}
                                        loading={this.state.isAddingUnavailablePeriod} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div >
        )
    }
}

export default injectIntl(ConsultorPeriodoIndisponivel);