import React from 'react'
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface IHTPButtonProps {
    text: string,
    onClick: () => void,
    loading?: boolean,
    disabled?: boolean,
    toUpper?: boolean,
    backgroundColor?: string
}

export default function IHTPButton(props: IHTPButtonProps) {

    const isDisabled = (): boolean => {
        if (typeof props.loading !== 'undefined' && props.loading === true) return true;
        if (typeof props.disabled !== 'undefined' && props.disabled === true) return true;
        return false;
    }

    const isLoading = (): boolean => {
        if (typeof props.loading !== 'undefined' && props.loading === true) return true;
        return false;
    }

    return (
        <Button
            style={{
                backgroundColor: props.backgroundColor ? props.backgroundColor : "#CA006A",
                color: "white",
                width: "100%",
                fontFamily: "Montserrat",
                fontWeight: "500",
                textTransform: "inherit",
                fontSize: "13px",
                opacity: isDisabled() ? 0.6 : 1
            }}
            disabled={isDisabled()}
            onClick={props.onClick}>
            {props.toUpper != null && props.toUpper == true ?
                props.text.toUpperCase() :
                props.text
            }

            {isLoading() === true && (
                <CircularProgress size={18} style={{ marginLeft: "1rem", color: "white" }} />
            )}
        </Button>
    )
}
