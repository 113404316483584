import React, { forwardRef, useState } from 'react';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Input, InputGroup, InputGroupText } from "reactstrap";

export interface IHTPInputProps {
    label: string,
    labelExtraOption?: JSX.Element,
    placeholder: string,
    onChange: (text: string) => void,
    onEnter?: () => void,
    type: "text" | "password" | "date",
    controlValue?: boolean,
    value?: string | number | undefined,
    disabled?: boolean
}

function IHTPInput(props: IHTPInputProps, ref) {

    const [visible, setVisible] = useState<boolean>(false);

    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            if (typeof props.onEnter !== 'undefined') {
                props.onEnter();
            }
        }
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ fontWeight: "600", fontSize: "13px", fontFamily: "Montserrat", color: "rgba(71, 73, 79, 1)" }}>{props.label}</span>
                {typeof props.labelExtraOption != 'undefined' && (
                    props.labelExtraOption
                )}
            </div>
            <InputGroup>
                <Input {...props.controlValue == true && { value: props.value }} disabled={props.disabled} innerRef={ref} placeholder={props.placeholder} type={visible === true ? "text" : props.type} onChange={(e) => props.onChange(e.target.value)} onKeyPress={onKeyPress} style={{ fontSize: "13px" }} />

                {typeof props.type != 'undefined' && props.type == "password" && (
                    <InputGroupText className='ihtpInputAction'>
                        {visible ? <MdVisibility onClick={() => setVisible(false)} size={20} /> : <MdVisibilityOff onClick={() => setVisible(true)} size={20} />}
                    </InputGroupText>
                )}

            </InputGroup>
        </>
    )
}

export default forwardRef(IHTPInput);
