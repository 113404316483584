import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import Configs from "../../../../../../helpers/Configs";
import { getPremium } from "../../../../../../actions/myihtpActions";
import { FormattedNumber, FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

class ListagemPremios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Premios: [],
      noDataGetPremios: null,
      showPaginationGetPremios: false,
      defaultPageSizeGetPremios: 5
    };
  }

  componentDidMount() {
    getPremium().then(data => {
      if (!data.premios.error) {
        this.setState({ Premios: data.premios.Premios });
      }
    });
  }

  render() {
    const { currencies } = this.props;
    const listagempremios = {
      columns: [
        {
          Header: <FormattedMessage id="myihtp.premios.descricao" />,
          accessor: "Descricao",
          resizable: false,
          minWidth: 810
        },
        {
          Header: () => (
            <div>
              <FormattedMessage id="myihtp.premios.volume" />
            </div>
          ),
          accessor: "Valor",
          resizable: false,
          width: 170,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.Valor}
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        },
        {
          Header: () => (
            <div>
              <FormattedMessage id="myihtp.premios.quantofalta" />
            </div>
          ),
          accessor: "ValorRestante",
          resizable: false,
          width: 170,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.ValorRestante}
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        }
      ]
    };
    return (
      <div>
        <hr />
        <ReactTable
          className="-highlight tableReact"
          data={this.state.Premios}
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                minHeight: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader
              }
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTheadThProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }
            };
          }}
          defaultPageSize={10}
          showPagination={false}
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={this.props.intl.formatMessage({ id: "table.config.rowsText" })}
          columns={listagempremios.columns}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    currencies: state.currencies
  };
};

export default injectIntl(connect(mapStateToProps)(ListagemPremios));
