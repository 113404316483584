import React, { Component } from "react";
import { FormattedDate, FormattedMessage, injectIntl } from "react-intl";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { registosNaoConcluidos } from "../../../../../../actions/myihtpActions";
import Configs from "../../../../../../helpers/Configs";
import InformationIcon from "../../Comissoes/Helper/InformationIcon";
import DialogInformation from "../../Helper/DialogInformation";

class ListaRegistosNaoConcluidos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Registers:[]
      latestRecords: [],
      noDataGetRecords: null,
      showPaginationGetRecords: false,
      defaultPageSizeGetRecords: 5,
    };
  }

  componentDidMount() {
    registosNaoConcluidos().then(data => {
      if (
        data.Registos.message !== "Não tem registos disponiveis." &&
        !data.Registos.error
      ) {
        this.setState({ latestRecords: data.Registos });
      }
    });
  }

  getUserContacts(email) {
    this.setState({
      user: { data: [{ TipoContacto: "Email", Descricao: email }] },
    });
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
  };

  render() {
    const columnsRegistos = [
      {
        Header: "",
        resizable: false,
        width: 32,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo && rowInfo.original.ID_Cliente
                  ? "rgba(180, 206, 239, 0.54)"
                  : null,
            },
          };
        },
        Cell: row => {
          return (
            <span
              className="fake-link"
              id="fake-link-1"
              onClick={() => {
                this.handleClickOpen();
                this.getUserContacts(row.original.Email);
              }}
            >
              <InformationIcon />
            </span>
          );
        },
      },
      {
        Header: (
          <FormattedMessage id="myihtp.listaRegistosMaisRecentes.table.nome" />
        ),
        accessor: "NomeCompleto",
        resizable: false,
      },
      {
        Header: (
          <FormattedMessage id="myihtp.listaRegistosMaisRecentes.table.dataRegisto" />
        ),
        accessor: "DataRegisto",
        resizable: false,
        id: "DataRegisto",
        sortable: true,
        Cell: row => {
          return <FormattedDate value={new Date(row.original.DataRegisto)} />;
        },
      },
      {
        Header: (
          <FormattedMessage id="myihtp.listaRegistosMaisRecentes.table.nomeConsultor" />
        ),
        accessor: "nomeConsultor",
        resizable: false,
      },
    ];

    return (
      <div>
        <hr />
        <DialogInformation
          open={this.state.open}
          onClose={this.handleClose}
          data={this.state.user}
          useCustomData={true}
        />
        <ReactTable
          className="-highlight tableReact"
          data={this.state.latestRecords}
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                height: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
              },
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody,
              },
            };
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
              },
            };
          }}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.latestRecords.length >
              Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={this.props.intl.formatMessage({ id: "table.config.rowsText" })}
          columns={columnsRegistos}
        />
      </div>
    );
  }
}
export default injectIntl(ListaRegistosNaoConcluidos);
