import React, { Component } from 'react'

export default class LoginIcon extends Component {
    render() {
        return (
            <svg version="1.1" id="Layer_1" x="0px" y="0px"
                width="22px" height="22px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30">
                <g>
                    <g>
                        <g>
                            <path fill="#FFFFFF" d="M14.958,25.383H5.614c-0.573,0-1.039-0.466-1.039-1.039V5.656c0-0.573,0.465-1.039,1.039-1.039h9.344
                       c0.574,0,1.039-0.464,1.039-1.038c0-0.575-0.464-1.039-1.039-1.039H5.614C3.896,2.541,2.5,3.938,2.5,5.656v18.688
                       c0,1.719,1.396,3.115,3.114,3.115h9.344c0.574,0,1.039-0.465,1.039-1.039S15.532,25.383,14.958,25.383z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path fill="#FFFFFF" d="M10.805,15c0,0.277,0.112,0.544,0.309,0.738l6.314,6.229c0.199,0.199,0.465,0.298,0.727,0.298
                       c0.27,0,0.537-0.104,0.74-0.309c0.4-0.408,0.398-1.064-0.011-1.468l-4.511-4.453H26.46c0.575,0,1.039-0.463,1.039-1.038
                       c0-0.574-0.464-1.038-1.039-1.038H14.374l4.511-4.452c0.409-0.403,0.413-1.06,0.01-1.468c-0.401-0.408-1.061-0.413-1.467-0.01
                       l-6.313,6.229C10.916,14.457,10.805,14.722,10.805,15z"/>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}
