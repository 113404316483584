import { NativeSelect, TextField } from "@material-ui/core";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastr";
import IHTPButton from "../../../../../../Common/IHTPButton";
import { setPackDefaultCurrency, setProductDefaultCurrency } from "../../../../../../../actions/myihtpActions";

let container;
export default function SetDefaultCurrency() {
    const [productId, setProductId] = useState<string>("");
    const [selectedCurrency, setSelectedCurrency] = useState<string>("");
    const [selectedType, setSelectedType] = useState<string>("");
    const intl = useIntl();

    const currencies = useSelector((state: any) => {
        return state.currencies;
    });

    const onClick = async () => {
        if (productId == "") {
            container.clear();
            container.error("O id do produto/pack necessita de estar preenchido.");
            return;
        }

        if (selectedCurrency == "") {
            container.clear();
            container.error("A moeda necessita de estar preenchida.");
            return;
        }

        if (selectedType == "") {
            container.clear();
            container.error("O tipo necessita de estar preenchido.");
            return;
        }

        var response;
        if (selectedType.toUpperCase() == "PRODUCT") {
            response = await setProductDefaultCurrency(productId, selectedCurrency);
        }

        if (selectedType.toUpperCase() == "PACK") {
            response = await setPackDefaultCurrency(productId, selectedCurrency);
            console.log(response)
        }

        if (response.success == false) {
            container.clear();
            container.error(response.message);
            return;
        }

        container.clear();
        container.success("Associação feita com sucesso");
    }

    return (
        <div>
            <ToastContainer
                ref={ref => (container = ref)}
                className="toast-bottom-right"
            />

            {/* ROW Adicionar Subscrição de Leads a consultor*/}
            <Row style={{ textAlign: "left" }}>
                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                    <span style={{ textAlign: "center" }}>
                        <b><FormattedMessage id="myihtp.ferramentasBackOffice.definirMoedaDefeitoProduto.title" /></b>
                    </span>
                    <div className="form-group">

                        <br />

                        <Row xs={12} sm={12} md={12} lg={12}>
                            <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                <TextField placeholder={intl.formatMessage({ id: "myihtp.ferramentasBackOffice.productId.placeholder" })} name={"productId"} style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                    value={productId} onChange={(e) => setProductId(e.target.value)}
                                />
                            </Col>
                        </Row>

                        <Row xs={12} sm={12} md={12} lg={12} style={{ marginTop: "10px" }}>
                            <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                <NativeSelect name={"selectedCurrency"} style={{ maxWidth: isMobile ? "100%" : "50%" }} fullWidth={true}
                                    onChange={(e) => setSelectedCurrency(e.target.value)} value={selectedCurrency}>
                                    <option value="">{intl.formatMessage({ id: "myihtp.ferramentasBackOffice.selectCurrency.option" })}</option>
                                    {
                                        currencies.list.length > 0 ?
                                            (currencies.list.map((currency: any) => {
                                                return (<option value={currency.ID_Moeda}>{currency.Descricao}</option>)
                                            }))
                                            : null
                                    }
                                </NativeSelect>
                            </Col>
                        </Row>

                        <Row xs={12} sm={12} md={12} lg={12} style={{ marginTop: "10px" }}>
                            <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                <NativeSelect name={"selectedType"} style={{ maxWidth: isMobile ? "100%" : "50%" }} fullWidth={true}
                                    onChange={(e) => setSelectedType(e.target.value)} value={selectedType}>
                                    <option value="">{intl.formatMessage({ id: "myihtp.ferramentasBackOffice.selectType.option" })}</option>
                                    <option value="product">{intl.formatMessage({ id: "myihtp.ferramentasBackOffice.selectType.option.product" })}</option>
                                    <option value="pack">{intl.formatMessage({ id: "myihtp.ferramentasBackOffice.selectType.option.pack" })}</option>
                                </NativeSelect>
                            </Col>
                        </Row>

                        <Row style={{ justifyContent: "center", marginTop: "1em" }}>
                            <Col xs={6} sm={6} md={6} lg={4} xl={3} style={{ textAlign: "center" }}>
                                <IHTPButton text={intl.formatMessage({ id: "myihtp.ferramentasBackOffice.setDefaultCurrency.button" })}
                                    toUpper={true}
                                    onClick={onClick}
                                    loading={false} />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div >
    )
}