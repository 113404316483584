import { TextField } from "@material-ui/core";
import NativeSelect from "@material-ui/core/NativeSelect";
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";
import { Label } from "reactstrap";
import { getConsultantById } from "../../../../../../../actions/consultantActions";
import { addSubscriptionToReceiveLeads, dispatchAllCampaigns, dispatchNewSubscription, getCampaigns } from "../../../../../../../actions/myihtpActions";
import IHTPButton from "../../../../../../Common/IHTPButton";

let container
class ConsultorSubscreverLeads extends Component {

    constructor(props) {
        super(props);

        this.state = {
            consultantName: "",
            consultantNameFound: false,
            id_consultor: '',
            campaigns: [],
            date: [null, null],
            id_campaign: '',
            isSubscribing: false
        }

        this.handleInput = this.handleInput.bind(this);
        this.subscribeConsultantToReceiveLeadsButtonClick = this.subscribeConsultantToReceiveLeadsButtonClick.bind(this);
        this.handleChangeSubscriptionPeriod = this.handleChangeSubscriptionPeriod.bind(this);
        this.getConsultantInfo = this.getConsultantInfo.bind(this);
    };

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    componentDidUpdate(prevProps) {

        if (prevProps.isTabActive === false && this.props.isTabActive === true) {
            this.loadCampaigns();
        }
    }

    loadCampaigns() {
        getCampaigns()
            .then(result => {
                if (result.success === true) {
                    this.setState(
                        prevState => ({
                            ...prevState,
                            campaigns: result.obj
                        })
                    );

                    this.props.dispatch(dispatchAllCampaigns(result.obj));
                }
            })
    }

    handleChangeSubscriptionPeriod = date => {
        this.setState(prevState => {
            return {
                ...prevState,
                date: date
            }
        })
    }
    //Função para subscrever consultor a uma lead
    subscribeConsultantToReceiveLeadsButtonClick = () => async e => {

        //Validar se campo consultor está preenchido
        if (this.state.id_consultor == null || this.state.id_consultor.trim().length <= 0) {
            container.clear();
            container.error(this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.subscricaoLeads.consultorVazio" }));
            return;
        }

        //Validar campo Campanha está preenchido
        if (this.state.id_campaign == null || this.state.id_campaign.trim().length <= 0) {
            container.clear();
            container.error(this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.subscricaoLeads.campanhaVazio" }));
            return;
        }

        //Validar se periodo para receber leads está preenchido
        if (this.state.date[0] == null || this.state.date[1] == null) {
            container.clear();
            container.error(this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.subscricaoLeads.leadsVazio" }));
            return;
        }

        this.setState({
            isSubscribing: true
        });

        addSubscriptionToReceiveLeads(this.state.date[0], this.state.date[1], this.state.id_consultor, this.state.id_campaign)
            .then(result => {

                container.clear();
                if (result.success === true) {
                    container.success(result.message);

                    this.setState(
                        prevState => ({
                            ...prevState,
                            id_consultor: '',
                            date: [null, null],
                            id_campaign: ''
                        })
                    );

                    this.props.dispatch(dispatchNewSubscription(result.obj));
                } else {

                    if (typeof result.message === 'undefined') {
                        container.clear();
                        container.error(this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.subscricaoLeads.erroLeads" }));
                    } else {
                        container.error(result.message);
                    }
                }
            })
            .catch(err => {
                container.clear();
                container.error(this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.subscricaoLeads.erroLeads2" }));
            })
            .finally(() => {
                this.setState({
                    isSubscribing: false
                });
            })
    }

    //Buscar a Informação do consultor
    getConsultantInfo() {
        getConsultantById(this.state.id_consultor)
            .then(result => {
                console.log(result);
                if (result.success === true) {
                    this.setState(prevState => ({
                        ...prevState,
                        consultantName: result.obj[0].NomeCompleto,
                        consultantNameFound: true
                    }));
                } else {
                    this.setState(prevState => ({
                        ...prevState,
                        consultantName: result.message,
                        consultantNameFound: false
                    }));
                }
            }).catch(err => {
                this.setState(prevState => ({
                    ...prevState,
                    consultantName: this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.subscricaoLeads.verificarConsultor" }),
                    consultantNameFound: false
                }));
            })
    }

    render() {
        return (
            <div>

                <ToastContainer
                    ref={ref => (container = ref)}
                    className="toast-bottom-right"
                />

                {/* ROW Adicionar Subscrição de Leads a consultor*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b><FormattedMessage id="myihtp.ferramentasBackOffice.subscricaoLeads.title" /></b>
                        </span>
                        <div className="form-group">

                            <br />

                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <TextField placeholder="ID Consultor" name={"id_consultor"} style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        value={this.state.id_consultor} onChange={this.handleInput} onBlur={this.getConsultantInfo}
                                    />
                                </Col>
                            </Row>

                            {/* Mostrar nome de consultor*/}
                            <Row xs={12} sm={12} md={12} lg={12} hidden={this.state.consultantName.length <= 0} style={{ display: "flex", justifyContent: "center" }}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ padding: "5px 0px 0px 6px", margin: "0px", textAlign: "left", maxWidth: isMobile ? "100%" : "50%" }} >
                                    <Label style={{ textAlign: "left", margin: "0px", padding: "0px", color: (this.state.consultantNameFound === true ? "green" : "#dc3545"), fontWeight: "400" }}>{this.state.consultantName}</Label>
                                </Col>
                            </Row>

                            <Row xs={12} sm={12} md={12} lg={12} style={{ marginTop: "10px" }}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <NativeSelect name={"id_campaign"} style={{ maxWidth: isMobile ? "100%" : "50%" }} fullWidth={true} onChange={this.handleInput} value={this.state.id_campaign}>
                                        <option value=""> {this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.subscricaoLeads.campanhas" })}</option>
                                        {
                                            this.state.campaigns.length > 0 ?
                                                (this.state.campaigns.map(type => {
                                                    return (<option value={type.Campanha}>{type.Descricao}</option>)
                                                }))
                                                : null
                                        }
                                    </NativeSelect>
                                </Col>
                            </Row>

                            <br />

                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center", display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }} >
                                    <label style={{ marginBottom: "0px", marginRight: "10px" }}> Período para receber leads: </label>
                                    <br /><br />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <DateTimeRangePicker value={this.state.date} name="date" id="date" onChange={this.handleChangeSubscriptionPeriod} />
                                </Col>
                            </Row>

                            <br />
                            <br />

                            <Row style={{ justifyContent: "center" }}>
                                <Col xs="6" sm="6" md="6" lg="4" xl="3" style={{ textAlign: "right" }} >
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.subscricaoLeads.botaoSubscrever" })}
                                        toUpper={true}
                                        onClick={this.subscribeConsultantToReceiveLeadsButtonClick()}
                                        loading={this.state.isSubscribing} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

export default injectIntl(connect(mapStateToProps)(ConsultorSubscreverLeads));