import { TextField } from "@material-ui/core";
import NativeSelect from "@material-ui/core/NativeSelect";
import { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { injectIntl } from "react-intl";
import { ToastContainer } from "react-toastr";
import { RefreshTipoConsultor } from "../../../../../../../actions/myihtpActions";
import IHTPButton from "../../../../../../Common/IHTPButton";

let container;
class MudarPercentagemDesconto extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id_consultor: '',
            tipo_desconto: '',
            isChangingConsultantType: false
        }

        this.handleInput = this.handleInput.bind(this);
    };

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    // Funcao para mudar desconto do consultor
    changeDiscount = () => async e => {
        e.stopPropagation();

        //Validar se consultor esta preenchido
        if (!this.state.id_consultor || this.state.id_consultor == null || this.state.id_consultor.trim().length <= 0) {
            container.clear();
            container.error("ID de Consultor deve estar preenchido");
            return;
        }

        //Validar se tipo de consultor esta preenchido
        if (!this.state.tipo_desconto || this.state.tipo_desconto == null || this.state.tipo_desconto.trim().length <= 0) {
            container.clear();
            container.error("Tipo de Desconto deve estar preenchido");
            return;
        }

        this.setState({
            isChangingConsultantType: true
        });

        RefreshTipoConsultor(this.state)
            .then(data => {
                if (data.success === true) {
                    container.clear();
                    container.success(data.message);

                    this.setState({
                        id_consultor: '',
                        tipo_desconto: ''
                    })
                } else {
                    container.clear();
                    container.error(data.message);
                }
            })
            .catch(error => {
                container.clear();
                container.error("Erro ao Mudar % de Desconto de Consultor");
            })
            .finally(() => {
                this.setState({
                    isChangingConsultantType: false
                });
            });
    };

    render() {
        return (
            <div>

                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                {/* ROW de mudar de tipo de conta*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }} >
                        <span style={{ textAlign: "center" }}>
                            <b>Mudar Percentagem de Desconto de Consultor</b>
                        </span>
                        <div className="form-group">
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <TextField
                                        placeholder="ID Consultor para alterar" name={"id_consultor"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        value={this.state.id_consultor} onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <NativeSelect
                                        name={"tipo_desconto"} style={{ maxWidth: isMobile ? "100%" : "50%" }} fullWidth={true}
                                        value={this.state.tipo_desconto} onChange={this.handleInput}>
                                        <option value="">Tipo de Desconto de Consultor</option>
                                        <option value="1">Consultor 20%</option>
                                        <option value="0">Consultor 10%</option>
                                        <option value="-1">Consultor sem desconto</option>
                                    </NativeSelect>
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row style={{ justifyContent: "center" }}>
                                <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{ textAlign: "center" }} >
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarPercentagemConsultor.botao" })}
                                        toUpper={true}
                                        onClick={this.changeDiscount()}
                                        loading={this.state.isChangingConsultantType} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default injectIntl(MudarPercentagemDesconto);