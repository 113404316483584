import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap";
import { TextField, Button } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { changeLeadConsultant } from "../../../../../../../actions/myihtpActions";
import { ToastContainer } from "react-toastr";
import { injectIntl, FormattedMessage } from 'react-intl';
import IHTPButton from '../../../../../../Common/IHTPButton';

let container
class MudarConsultorLead extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id_lead: null,
            id_consultor: null,
            isChangingLeadConsultant: false
        }

        this.handleInput = this.handleInput.bind(this);
        this.changeLeadConsultantButtonClick = this.changeLeadConsultantButtonClick.bind(this);
    };

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    //Função para mudar o Consultor de uma lead
    changeLeadConsultantButtonClick = () => async e => {
        e.stopPropagation();

        this.setState({
            isChangingLeadConsultant: true
        });

        changeLeadConsultant(this.state.id_lead, this.state.id_consultor)
            .then(result => {

                container.clear();
                if (result.success === true) {
                    container.success(result.message);
                    this.setState({
                        id_lead: '',
                        id_consultor: ''
                    })
                } else {

                    if (typeof result.message === 'undefined') {
                        container.error(this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarConsultorLead.erro" }));
                    } else {
                        container.error(result.message);
                    }
                }
            })
            .catch(err => {
                container.clear();
                container.error(this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarConsultorLead.erro" }));
            })
            .finally(() => {
                this.setState({
                    isChangingLeadConsultant: false
                });
            })
    }


    render() {
        return (
            <div>

                <ToastContainer
                    ref={ref => (container = ref)}
                    className="toast-bottom-right"
                />

                {/* ROW para mudar consultor de lead*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b><FormattedMessage id="myihtp.ferramentasBackOffice.mudarConsultorLead.title" /></b>
                        </span>
                        <div className="form-group">
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <TextField
                                        placeholder={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarConsultorLead.leadId" })}
                                        name={"id_lead"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }}
                                        type="text"
                                        fullWidth={true}
                                        value={this.state.id_lead}
                                        onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <TextField
                                        placeholder={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarConsultorLead.consultantId" })}
                                        name={"id_consultor"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }}
                                        type="text"
                                        fullWidth={true}
                                        value={this.state.id_consultor}
                                        onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row style={{ justifyContent: "center" }}>
                                <Col xs="12" sm="12" md="6" lg="4" xl="3"
                                    style={{ textAlign: "center" }}
                                >
                                    {/* <Button variant="contained" color="secondary" style={{ minWidth: "20%" }} onClick={this.changeLeadConsultantButtonClick()}>
                                        <FormattedMessage id="myihtp.ferramentasBackOffice.mudarConsultorLead.botao" />
                                    </Button> */}

                                    <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarConsultorLead.botao" })}
                                        toUpper={true}
                                        onClick={this.changeLeadConsultantButtonClick()}
                                        loading={this.state.isChangingLeadConsultant} />

                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default injectIntl(MudarConsultorLead);