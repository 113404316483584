import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { FormattedMessage, FormattedDate, injectIntl } from "react-intl";
import Configs from "../../../../../../helpers/Configs";
import { expirateDateDownline } from "../../../../../../actions/myihtpActions";
import DialogInformation from "../../Helper/DialogInformation";
import InformationIcon from "../../Comissoes/Helper/InformationIcon";

class DownlinesExpirar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Registers:[]
      Registos: [],
      noDataGetRecords: null,
      showPaginationGetRecords: false,
      defaultPageSizeGetRecords: 5,
      clientId: null
    };
  }

  componentDidMount() {
    expirateDateDownline().then(data => {
      if (
        data.Registos.message !== "Não tem registos disponiveis." &&
        !data.Registos.error
      ) {
        this.setState({ Registos: data.Registos });
      }
    });
  }

  openContactInformationDialog = (clientId) => {
    this.setState({
      clientId: clientId,
      open: true
    });
  }

  handleClose = () => {
    this.setState({ clientId: null, open: false });
  };

  calculateColor = (rowInfo) => {
    if (typeof rowInfo === 'undefined') return null;
    if (typeof rowInfo.original === 'undefined') return null;

    if (rowInfo.original.colorValorEnc === "Red") return "rgba(252, 178, 184, 0.6)";
    if (rowInfo.original.colorValorEnc === "Green") return "rgba(178, 252, 182, 0.6)";

    //Cor para o amarelo => "rgba(255, 250, 174, 0.6)"

    return null;
  }

  render() {
    const columnsLastRecords = [
      {
        Header: "",
        resizable: false,
        width: 32,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo && rowInfo.original.ID_Cliente
                  ? "rgba(180, 206, 239, 0.54)"
                  : null
            }
          };
        },
        Cell: row => {
          return (
            <span
              className="fake-link"
              id="fake-link-1"
              onClick={() => {
                this.openContactInformationDialog(row.original.ID_Cliente);
              }}
            >
              <InformationIcon />
            </span>
          );
        }
      },
      {
        Header: (
          <FormattedMessage id="myihtp.listaRegistosMaisRecentes.table.nome" />
        ),
        accessor: "NomeCompleto",
        resizable: false
      },
      {
        Header: (
          <FormattedMessage id="myihtp.listaRegistosMaisRecentes.table.tipoCliente" />
        ),
        accessor: "Descricao",
        resizable: false
      },
      {
        Header: (
          <FormattedMessage id="myihtp.listaRegistosMaisRecentes.table.dataValidade" />
        ),
        accessor: "DataValidade",
        resizable: false,
        id: "DataValidade",
        sortable: true,
        Cell: row => {
          return <FormattedDate value={new Date(row.original.DataValidade)} />;
        }
      }
    ];

    return (
      <div>
        <hr />
        <DialogInformation
          open={this.state.open}
          onClose={this.handleClose}
          clientId={this.state.clientId}
          useCustomData={false}
        />
        <ReactTable
          className="-highlight tableReact"
          data={this.state.Registos}
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                height: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader
              }
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
                background: this.calculateColor(rowInfo)
              }
            };
          }}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.Registos.length >
              Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={this.props.intl.formatMessage({ id: "table.config.rowsText" })}
          columns={columnsLastRecords}
        />
      </div>
    );
  }
}
export default injectIntl(DownlinesExpirar);
