import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import GoogleLoginComponent from './GoogleLoginComponent';
import FacebookLoginComponent from './FacebookLoginComponent';

export interface SocialLoginProps {
    previousInCheckout: boolean
}

export default function SocialLogin(props: SocialLoginProps) {

    const [googleErrorMessage, setGoogleErrorMessage] = useState<string>("");

    return (
        <>
            <Row style={{ display: "flex", alignItems: "center", alignContent: "center" }}>
                <Col md={6} className="colsFBButton">
                    <FacebookLoginComponent previousInCheckout={props.previousInCheckout} />
                </Col>

                <Col md={6} className="colsGButton">
                    <GoogleLoginComponent
                        onErrorMessage={(message) => setGoogleErrorMessage(message)}
                        previousInCheckout={props.previousInCheckout} />
                </Col>
            </Row>

            {
                googleErrorMessage != null && googleErrorMessage.length > 0 ?
                    <Row style={{ marginTop: "25px" }}>
                        <Col>
                            <label style={{ fontSize: "15px" }}>{googleErrorMessage}</label>
                        </Col>
                    </Row>
                    : null
            }
        </>
    )
}
