
class Routes {
  constructor() {

    var mainWebsiteURL = process.env.REACT_APP_MAIN_WEBSITE_URL;
    var oldWebsiteURL = process.env.REACT_APP_OLD_WEBSITE_URL;
    var shopWebsiteURL = process.env.REACT_APP_SHOP_WEBSITE_URL;

    this.home = {
      home: "/",
    };

    this.contactos = {
      contactos: mainWebsiteURL + "contactos"
    };

    this.account = {
      login: "/account/logon",
      recuperar: "/account/recover",
      registo: "/account/create",
      verify: "/account/verify",
      confirmEmail: "/account/confirm",
      define: "/account/define",
      changePassword: "/account/changePassword"
    };

    this.myIHTP = {
      home: "/myihtp"
    };

    this.shop = {
      home: shopWebsiteURL + "Loja/",
      loja: shopWebsiteURL + "Loja/",
      cart: shopWebsiteURL + "Carrinho/",
      checkout: shopWebsiteURL + "/Shop/Checkout"
    };

    this.redirectToProduct = (productId) => {
      let finalUrl = process.env.REACT_APP_MAIN_WEBSITE_PRODUCTDETAIL_URL_PT + "" + productId;
      return finalUrl;
    }

    this.consultor = {
      consultor: mainWebsiteURL + "ser-consultor/"
    };

    this.empresa = {
      fundador: mainWebsiteURL + "fundador/",
      testemunhos: mainWebsiteURL + "testemunhos-lista/",
      noticias: mainWebsiteURL + "noticias/",
      imprensa: mainWebsiteURL + "tv-radio-e-imprensa-pagina/",
      protecaoDados: mainWebsiteURL + "protecao-de-dados-e-utilizacao/",
      cancelamentoGarantia: mainWebsiteURL + "cancelamento-e-garantia-de-satisfacao/",
      sobreNos: mainWebsiteURL + "empresa/",
      associacao: mainWebsiteURL + "responsabilidade-social/",
    };

    this.recursosGratis = {
      desafios: mainWebsiteURL + "desafios-para-excelencia/",
      afiliados: mainWebsiteURL + "recursos-gratis/",
      frases: mainWebsiteURL + "frases-poderosas/",
    };

    this.nossoorgulho = {
      digupulse: "https://www.digipulse.pt",
      businessconsulting: "http://ihtpconsulting.com",
      wonderlives: shopWebsiteURL + "Loja/Catalogo/SA",
      nascidosparatriunfar: "https://nascidosparatriunfar.webnode.pt"
    };

  }
}

var RoutesObject = new Routes();

export default RoutesObject;
