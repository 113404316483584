import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";
import Configs from "../../../../../../helpers/Configs";
import { getSubscriptions } from "../../../../../../actions/myihtpActions";
import { cancelSubscription } from "../Helper";
import { FormattedMessage, injectIntl } from "react-intl";
let container;
class Subscricoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //subs:[],
      subscriptions: [],
      noDataGetSubscriptions: null,
      showPaginationGetSubscriptions: false,
      defaultPageSizeGetSubscriptions: 5,
      id_cancel: null
    };
  }

  componentDidMount() {
    getSubscriptions()
      .then(data => {

        if (data.success === true) {
          if (data.obj.Subscriptions.length > 0) {
            this.setState({ subscriptions: data.obj.Subscriptions });
          }
        }

      })
      .catch(() => {
        this.setState({ subscriptions: [] });
      });
  }

  render() {
    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <ReactTable
          className="-highlight tableReact"
          data={this.state.subscriptions}
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                minHeight: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader
              }
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: () => {
                if (!rowInfo.row.DataCancelamento && column.id === "totais") {

                  var confirm = window.confirm(
                    this.props.intl.formatMessage({
                      id: "myihtp.subscricoes.cancelar"
                    })
                  );

                  if (confirm) {
                    cancelSubscription(rowInfo.original.ID_Subscricao)
                      .then(data => {
                        if (data === true) {
                          getSubscriptions()
                            .then(data => {
                              if (data.success === true) {
                                this.setState({
                                  subscriptions: data.obj.Subscriptions
                                });
                              }
                            })
                            .catch(() => {
                              this.setState({ subscriptions: [] });
                            });

                          container.clear();
                          container.success(this.props.intl.formatMessage({ id: "myihtp.subscricoes.subscricaoInserida" }));
                        } else {
                          container.clear();
                          container.error(this.props.intl.formatMessage({ id: "myihtp.subscricoes.subscricaoCancelada" }));
                        }
                      });
                  }
                }
              },
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTheadThProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }
            };
          }}
          defaultSorted={[
            {
              id: "DataInicio",
              desc: true
            }
          ]}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.subscriptions.length >
              Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={this.props.intl.formatMessage({ id: "table.config.rowsText" })}
          columns={Configs.myIHTP.tableConfig.subscricoes.columns}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {};
};

export default injectIntl(connect(mapStateToProps)(Subscricoes));