import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress } from "material-ui";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { getUserContactsRede } from "../../../../../actions/myihtpActions";
import BigCloseIcon from "../../../../Icons/BigCloseIcon";

let container;

class DialogInformation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      contacts: []
    };
  }

  handleClose = () => {
    this.props.onClose();
  };

  handleListItemClick = value => {
    this.props.onClose(value);
  };

  componentDidUpdate(prevProps) {

    var previousOpenValue = false;

    //Se antes existir já as props vamos buscar o valor delas
    if (typeof prevProps !== 'undefined' && prevProps != null && typeof prevProps.open != 'undefined' && prevProps.open != null) {
      previousOpenValue = prevProps.open;
    }

    //Se antes estava fechado e agora está aberto
    if (previousOpenValue === false && this.props.open === true && this.props.useCustomData == false && this.props.clientId != null) {
      this.loadUserContacts();
    }
  }

  setLoading = value => {
    this.setState({
      loading: value
    })
  }

  loadUserContacts() {

    this.setLoading(true);
    getUserContactsRede(this.props.clientId)
      .then(response => {

        if (response.success === true) {
          this.setState({
            contacts: response.obj
          })
        } else {
          this.setState({
            contacts: []
          })
        }
        this.setLoading(false);
      })
      .catch(err => {
        this.setState({
          contacts: []
        })
        this.setLoading(false);
      });
  }

  render() {
    const { onClose, open, useCustomData, data } = this.props;

    var contacts = [];
    if (useCustomData === true) {

      if (typeof data !== 'undefined' && data != null) {
        contacts = data.data;
      }
    } else {
      contacts = this.state.contacts;
    }

    return (
      <div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          style={{ minHeight: "100%" }}
          open={open}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <Container>

            {/* Título e botão de fechar*/}
            <Row>

              {/* Título */}
              <Col xs="10" sm="10" md="10" lg="10">
                <DialogTitle id="simple-dialog-title">
                  <FormattedMessage id="dialog.contactosCliente" />
                </DialogTitle>
              </Col>

              {/* Botão de fechar */}
              <Col xs="2" sm="2" md="2" lg="2" onClick={this.handleClose}
                style={{
                  display: "flex",
                  alignItems: "center",
                  right: "10px",
                }}>
                <BigCloseIcon />
              </Col>
            </Row>

            {this.state.loading === true ? (
              <div style={{ display: "flex", justifyContent: "center", textAlign: "center", alignContent: "center", alignItems: "center", flexDirection: "column" }}>
                <CircularProgress thickness={2} size={35} disableShrink />
                <p style={{ fontSize: "18px", textAlign: "center", color: "black", fontWeight: "400" }}>
                  <FormattedMessage id="dialog.carregarInformacao" />
                </p>
              </div>
            ) : (
              <div>

                {contacts == null || (Array.isArray(contacts) && contacts.length <= 0) ? (
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                      <FormattedMessage id="dialog.naoTemContactos" />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                      {contacts.map(contact => {
                        return (
                          <span key={contact.Descricao}>
                            {" "}
                            {contact.TipoContacto + ": " + contact.Descricao}{" "}
                            <br />
                          </span>
                        );
                      })}
                    </Col>
                  </Row>
                )}
              </div>
            )}

            <br />
          </Container>
        </Dialog>
      </div >
    );
  }
}
export default injectIntl(DialogInformation);
