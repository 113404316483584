import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Configs from "../../../../../../helpers/Configs";
import { getAllLatestInscriptions } from "../../../../../../actions/myihtpActions";
import { injectIntl } from "react-intl";

class UltimasInscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latestInscriptions: [],
      noDataGetInscriptions: null,
      showPaginationGetInscriptions: false,
      defaultPageSizeGetInscriptions: 5
    };
  }

  componentDidMount() {
    getAllLatestInscriptions()
      .then(data => {
        if (data.success === true) {
          this.setState({ latestInscriptions: data.obj });
        }
      })
      .catch(err => {
        this.setState({ latestInscriptions: [] });
      });
  }

  render() {
    return (
      <div>
        <hr />
        <ReactTable
          className="-highlight tableReact"
          data={this.state.latestInscriptions}
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                height: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader
              }
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.latestInscriptions.length >
              Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={this.props.intl.formatMessage({ id: "table.config.rowsText" })}
          columns={Configs.myIHTP.tableConfig.ultimasinscricoes.columns}
        />
      </div>
    );
  }
}
export default injectIntl(UltimasInscriptions);
