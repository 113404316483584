export function isUserLoggedIn() {
    return new Promise((resolve, reject) => {
        return fetch("/user/isUserLoggedIn", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .then(json => {
                console.log(json)
                resolve(json);
                return json;
            })
            .catch(error => console.log(error.message));
    });
}

export function getPhoneNumbers() {
    return new Promise((resolve, reject) => {
        return fetch("/user/getContactsByContactType", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                TipoContacto: 2,
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
                return json;
            })
            .catch(error => console.log(error.message));
    });
}


export function addPhoneNumber(phonenumber) {
    return new Promise((resolve, reject) => {
        return fetch("/user/addContact", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                ID_TipoContacto: 2,
                Descricao: phonenumber,
                Principal: "N"
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
                return json;
            })
            .catch(error => console.log(error.message));
    });
}

//Ação para fazer login com Facebook
export function loginWithFacebook(email, facebookId, facebookAccessToken) {
    return new Promise((resolve, reject) => {
        return fetch("/user/facebookLogin", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                Email: email,
                FacebookId: facebookId,
                FacebookAccessToken: facebookAccessToken
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
                return json;
            })
            .catch(error => console.log(error.message));
    });
}

//Ação para fazer login com Facebook
export function registerWithFacebook(novoUtilizador, facebookId, facebookAccessToken) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/user/facebookRegister", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    FacebookId: facebookId,
                    FacebookAccessToken: facebookAccessToken,
                    NomeCompleto: novoUtilizador.nomeCompleto,
                    Email: novoUtilizador.email,
                    EmailRecomendacao: novoUtilizador.emailRecomendacao,
                    Telefone: novoUtilizador.telefone,
                    DataNascimento: novoUtilizador.dataNascimento,
                    Pais: novoUtilizador.pais,
                    Localidade: novoUtilizador.localidade,
                    Refer: novoUtilizador.refer,
                    GDPR: novoUtilizador.gdpr
                })
            })
                .then(res => res.json())
                .then(json => {
                    resolve(json);
                    dispatch(loading(false));
                    return json;
                })
                .catch(error => {
                    var response = {
                        success: false,
                        message: "Erro ao conectar com servidor. Por favor tente mais tarde."
                    }

                    dispatch(loading(false));
                    resolve(response);
                });
        });
    };
}

//Ação para ficar com as associações de uma conta
export function getSocialLoginAssociations() {
    return new Promise((resolve, reject) => {
        return fetch("/user/getSocialLoginAssociations", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
                return json;
            })
            .catch(error => {
                resolve({
                    success: false,
                    message: "Erro ao conectar ao servidor"
                })
            });
    });
}

//Ação para remover associação com Facebook
export function removeSocialLoginFromFacebook() {
    return new Promise((resolve, reject) => {
        return fetch("/user/removeSocialLoginFromFacebook", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
                return json;
            })
            .catch(error => {
                console.log(error.message);
                resolve({
                    success: false,
                    message: "Erro ao conectar ao servidor"
                })
            });
    });
}

//Ação para remover associação com Google
export function removeSocialLoginFromGoogle() {
    return new Promise((resolve, reject) => {
        return fetch("/user/removeSocialLoginFromGoogle", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
                return json;
            })
            .catch(error => {
                resolve({
                    success: false,
                    message: "Erro ao conectar ao servidor"
                })
            });
    });
}

//Ação para fazer login com Google
export function loginWithGoogle(googleCode) {
    return new Promise((resolve, reject) => {
        return fetch("/user/googleLogin", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                GoogleCode: googleCode,
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
                return json;
            })
            .catch(error => console.log(error.message));
    });
}

//Ação para fazer registar com Google
export function registerWithGoogle(novoUtilizador, googleAccessToken, googleRefreshToken, googleIdToken) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/user/googleRegister", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    GoogleAccessToken: googleAccessToken,
                    GoogleRefreshToken: googleRefreshToken,
                    GoogleIdToken: googleIdToken,
                    NomeCompleto: novoUtilizador.nomeCompleto,
                    Email: novoUtilizador.email,
                    EmailRecomendacao: novoUtilizador.emailRecomendacao,
                    Telefone: novoUtilizador.telefone,
                    DataNascimento: novoUtilizador.dataNascimento,
                    Pais: novoUtilizador.pais,
                    Localidade: novoUtilizador.localidade,
                    Refer: novoUtilizador.refer,
                    GDPR: novoUtilizador.gdpr
                })
            })
                .then(res => res.json())
                .then(json => {
                    resolve(json);
                    dispatch(loading(false));
                    return json;
                })
                .catch(error => {
                    var response = {
                        success: false,
                        message: "Erro ao conectar com servidor. Por favor tente mais tarde."
                    }

                    dispatch(loading(false));
                    resolve(response);
                });
        });
    };
}

export function getEmail() {
    return new Promise((resolve, reject) => {
        return fetch("/user/getEmail", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
                return json;
            })
            .catch(error => console.log(error.message))
    });
}

export function getAllUserTypes() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/user/getAllUserTypes", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(res => res.json())
                .then(json => {

                    if (json.success === true) {
                        dispatch(setUserTypes(json.obj));
                    }

                    resolve(json);
                    return json;
                })
                .catch(error => {
                    reject()
                });
        });
    };
}

export function loadUserTypeDescription() {
    return (dispatch, getState) => {

        let userTypeDescription = null;
        try {

            const language = GetUserLanguage(getState().user);

            //Ficamos com os tipos de contas a descrição para cada idioma
            const accountTypes = getState().userTypes

            //Vamos buscar o tipo de cliente que o user é 
            const userType = GetUserType(getState().user);

            if (accountTypes != null && Array.isArray(accountTypes) && accountTypes.length > 0) {

                //Vamos procurar o tipo de conta apropriada consoante o idioma 
                const accountType = accountTypes.find(at => at.ID_TipoCliente == userType && at.Idioma.toLowerCase() == language);

                if (accountType != null) {
                    userTypeDescription = accountType.Descricao;
                }
            }
        } catch (exp) {
            userTypeDescription = null;
        }

        dispatch(setUserTypeDescription(userTypeDescription));
    };
}

export function verifyEmailLogin(email) {
    return new Promise((resolve, reject) => {
        return fetch("/user/verifyEmailLogin", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                email: email,
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
                return json;
            })
            .catch(error => reject())
    });
}

function GetUserLanguage(userState) {
    try {
        if (typeof userState !== 'undefined' && userState != null && typeof userState.language !== 'undefined' && userState.language != null) {
            return userState.language;
        } else {
            return null;
        }
    } catch (exp) {
        return null;
    }
}

function GetUserType(userState) {
    try {
        if (typeof userState !== 'undefined' && userState != null && typeof userState.userType !== 'undefined' && userState.userType != null) {
            return userState.userType;
        } else {
            return null;
        }
    } catch {
        return null;
    }
}


export const LOADING = "LOADING";
export const loading = loadingState => ({
    type: LOADING,
    payload: { loadingState }
});


export const USER_TYPES = "USER_TYPES";
export const setUserTypes = userTypes => ({
    type: USER_TYPES,
    payload: { userTypes }
});


export const USER_TYPE_DESCRIPTION = "USER_TYPE_DESCRIPTION";
export const setUserTypeDescription = userTypeDescription => ({
    type: USER_TYPE_DESCRIPTION,
    payload: { userTypeDescription }
});
