import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { verifyEmail } from "../../actions/miniCartActions";
import Routes from "../../helpers/Routes";
import IHTPButton from "../Common/IHTPButton";
import IHTPInput from "../Common/IHTPInput";
import IHTPRecaptcha from "../Common/IHTPRecaptcha";
import IHTPSpacer from "../Common/IHTPSpacer";
import HeaderDesktop from "../Desktop/Header/Header";
import Footer from '../Footer/Footer';
import PasswordCheckList from "../Register/PasswordCheckList";
import { ToastContainer } from "react-toastr";

interface DefinePasswordDTO {
    link: string,
    password: string,
    confirmPassword: string
}

let container;

export default function DefinePassword() {

    const [password, setPassword] = useState<string>("");
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const [showPasswordErrors, setShowPasswordErrors] = useState<boolean>(false);

    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState<boolean>(false);
    const [showConfirmPasswordErrors, setShowConfirmPasswordErrors] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [recaptchaVerified, setRecaptchaVerified] = useState<boolean>(false);

    const intl = useIntl();

    const dispatch = useDispatch();

    const onButtonClick = async () => {
        if (recaptchaVerified == false) return;

        if (isPasswordValid == false) {
            setShowPasswordErrors(true);
            return;
        }

        if (isConfirmPasswordValid == false) {
            setShowConfirmPasswordErrors(true);
            return;
        }

        const userData: DefinePasswordDTO = {
            link: window.location.href,
            password: password,
            confirmPassword: confirmPassword
        }

        setLoading(true);
        const result: any = await dispatch(verifyEmail(userData));
        setLoading(false);

        if (result.success === true) {
            container.clear();
            container.success(`${result.message}`);
            setTimeout(function () {
                window.location.href = Routes.account.login;
            }, 1500);
        } else {
            container.clear();
            container.error(`${result.message}`);
        }
    }

    return (
        <div className="App">
            <HeaderDesktop />
            <Container className="mainContentContainer">
                <Row style={{ textAlign: "center" }} >
                    <Col xs="12" sm="12" md="12" lg="12">

                        <ToastContainer
                            ref={ref => (container = ref)}
                            className="toast-bottom-right"
                        />


                        <div className="verifyEmailTitleParent">
                            <div className="verifyEmailTitle">
                                <FormattedMessage id="validarEmail.titulo" />
                            </div>
                        </div>

                        <br />

                        <Row style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <Col xs={12} sm={12} md={6} lg={5} xl={3} style={{ textAlign: "left" }}>


                                <IHTPInput type='password' label={intl.formatMessage({ id: "validarEmail.pwdLabel" })}
                                    placeholder={intl.formatMessage({ id: "validarEmail.pwdPlaceholder" })} onChange={setPassword} />
                                <IHTPSpacer verticalSpace="10px" />

                                <PasswordCheckList minimumCharacters={8}
                                    shouldHaveAtLeastOneLetter={true}
                                    shouldHaveAtLeastOneNumber={true}
                                    shouldHaveAtLeastOneLowercaseLetter={true}
                                    shouldHaveAtLeastOneCapitalLetter={true}
                                    password={password}
                                    onChange={setIsPasswordValid}
                                    showComponent={showPasswordErrors}
                                />

                                <IHTPSpacer verticalSpace="20px" />

                                <IHTPInput type='password' label={intl.formatMessage({ id: "validarEmail.confirmPwdLabel" })}
                                    placeholder={intl.formatMessage({ id: "validarEmail.confirmPwdPlaceholder" })} onChange={setConfirmPassword} />

                                <IHTPSpacer verticalSpace="10px" />

                                <PasswordCheckList
                                    matchPasswords={true}
                                    confirmPassword={confirmPassword}
                                    password={password}
                                    onChange={setIsConfirmPasswordValid}
                                    showComponent={showConfirmPasswordErrors}
                                />

                                <IHTPSpacer verticalSpace="20px" />

                                <IHTPRecaptcha setRecaptchaVerified={setRecaptchaVerified} />

                                <IHTPSpacer verticalSpace="20px" />

                                <IHTPButton text={intl.formatMessage({ id: "validarEmail.button" })} onClick={onButtonClick} loading={loading}
                                    disabled={recaptchaVerified === false}
                                />

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}
