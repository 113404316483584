import Collapse from '@material-ui/core/Collapse/Collapse';
import Cookies from 'js-cookie';
import { createRef, useEffect, useState } from 'react';
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { loadReCaptcha } from "react-recaptcha-google";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from "react-toastr";
import { Col, Row } from "reactstrap";
import { getGoogleRecaptchaKey } from '../../../actions/geralActions';
import { getPaises, register } from '../../../actions/miniCartActions';
import { registerWithFacebook, registerWithGoogle } from '../../../actions/userActions';
import { funnelStep } from '../../../Analytics/DataLayer';
import CrossDomainEventHandler from '../../../helpers/CrossDomainEventHandler';
import Routes from '../../../helpers/Routes';
import { ConvertDateTimeToJustDate, isDate } from '../../../helpers/UsefulFunctions';
import { FacebookUserRegisterDTO, GoogleUserRegisterDTO, UserRegisterDTO, UserRegisterDTOSchema } from '../../../models/UserRegisterDTO';
import IHTPButton from '../../Common/IHTPButton';
import IHTPCheckbox from '../../Common/IHTPCheckbox';
import IHTPInput from '../../Common/IHTPInput';
import IHTPRecaptcha from '../../Common/IHTPRecaptcha';
import IHTPSelect, { IHTPSelectItem } from '../../Common/IHTPSelect';
import IHTPSpacer from '../../Common/IHTPSpacer';
import { VerifyEmailRouteState } from '../../VerifyEmail/VerifyEmail';
import PasswordCheckList from '../PasswordCheckList';
import "./Register.css";
import RegisterBenefits from './RegisterBenefits';

let container;

export interface RegisterRouteState {
    email?: string,
    previousInCheckout: boolean
}

export interface RegisterGoogleData {
    accessToken: string,
    email: string,
    idToken: string,
    name: string,
    refreshToken: string
}

export interface RegisterFacebookData {
    accessToken: string,
    email: string,
    id: string,
    name: string,
}

export interface RegisterProps {
    googleData?: RegisterGoogleData,
    facebookData?: RegisterFacebookData
}

export default function Register(props: RegisterProps) {

    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state: any) => state.user);
    const intl = useIntl();

    const fullNameInput = createRef<HTMLInputElement>();

    const { state: routeState } = useLocation<RegisterRouteState>();

    const [openExtraField, setOpenExtraField] = useState<boolean>(false);
    const [countries, setCountries] = useState<IHTPSelectItem[]>([]);
    const [email, setEmail] = useState<string>("");
    const [canChangeEmail, setCanChangeEmail] = useState<boolean>(true);

    const [fullName, setFullName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const [showPasswordErrors, setShowPasswordErrors] = useState<boolean>(false);
    const [recommendationEmail, setRecommendationEmail] = useState<string>("");
    const [country, setCountry] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [birthDate, setBirthDate] = useState<string>("");

    const [recaptchaKey, setRecaptchaKey] = useState<string | null>(null);
    const [recaptchaVerified, setRecaptchaVerified] = useState<boolean>(false);
    const [acceptedRGPD, setAcceptedRGPD] = useState<boolean>(false);

    const [loadingRegister, setLoadingRegister] = useState<boolean>(false);

    const [isRegisterFromFacebook, setIsRegisterFromFacebook] = useState<boolean>(false);
    const [isRegisterFromGoogle, setIsRegisterFromGoogle] = useState<boolean>(false);

    const loadCountries = async () => {
        setCountries([]);
        const data: any = await dispatch(getPaises());
        if (!data.data.error) {
            var paises: IHTPSelectItem[] = [];
            data.data.paises.forEach(item => {
                var selectItem: IHTPSelectItem = {
                    value: item.alpha3,
                    label: item.name
                }
                paises.push(selectItem);
            });
            setCountries(paises);
        }
    }

    const loadRecaptchaKey = async () => {
        const result = await getGoogleRecaptchaKey();
        if (result.success === true) {
            setRecaptchaKey(result.obj);
        }
    }

    const loadRouteState = () => {
        if (typeof routeState !== 'undefined' && routeState != null && typeof routeState == "object" && typeof routeState.email !== 'undefined') {
            setEmail(routeState.email);
            setCanChangeEmail(false);
            focusFullNameInput();
        }
    }

    const focusFullNameInput = () => {
        if (fullNameInput != null && fullNameInput.current != null) {
            fullNameInput.current.focus();
        }
    }

    const loadPropsState = () => {
        if (props.facebookData != null) {
            setIsRegisterFromFacebook(true);
            setEmail(props.facebookData.email);
            setCanChangeEmail(false);
            setFullName(props.facebookData.name)
            focusFullNameInput();
        } else if (props.googleData != null) {
            setIsRegisterFromGoogle(true);
            setEmail(props.googleData.email);
            setCanChangeEmail(false);
            setFullName(props.googleData.name)
            focusFullNameInput();
        }
    }

    useEffect(() => {
        loadRouteState();
        loadPropsState();
        loadRecaptchaKey();
        loadCountries();
        return () => { }
    }, [])

    useEffect(() => {
        if (typeof user !== 'undefined' && user != null && typeof user.userID !== 'undefined') {
            history.replace("/");
            return;
        }
        return () => { }
    }, [user])


    useEffect(() => {
        loadReCaptcha();
        return () => { }
    }, [recaptchaKey])

    const handleFormSubmit = async () => {

        const newUser: UserRegisterDTO = {
            email: email,
            nomeCompleto: fullName,
            password: password,
            emailRecomendacao: recommendationEmail,
            telefone: phoneNumber,
            dataNascimento: birthDate,
            pais: country,
            localidade: city,
            refer: Cookies.get("refer"),
            gdpr: acceptedRGPD === true ? "S" : "N"
        }

        const result = UserRegisterDTOSchema(intl).validate(newUser, { allowUnknown: true });
        if (result.error != null) {
            const message = result.error.details[0].message;
            container.clear();
            container.error(message);
            return;
        }

        if (isRegisterFromFacebook == false && isRegisterFromGoogle == false && isPasswordValid == false) {
            container.clear();
            container.error(intl.formatMessage({ id: "registar.passwordInvalida" }));
            setShowPasswordErrors(true);
            return;
        }

        //Se a data de nascimento estiver preenchida
        if (birthDate != null && birthDate.trim().length > 0) {

            //Se o valor da data de nascimento não for válido
            if (!isDate(birthDate)) {
                container.clear();
                container.error(<FormattedMessage id="registar.dataNascimento" />);
                return;
            }

            newUser.dataNascimento = ConvertDateTimeToJustDate(new Date(birthDate));
        }

        if (acceptedRGPD !== true) {
            container.clear();
            container.error(<FormattedMessage id="registar.avisoRGPD" />);
            return;
        }

        if (isRegisterFromFacebook === true) {
            const facebookUser: FacebookUserRegisterDTO = {
                ...newUser,
                facebookAccessToken: props.facebookData!.accessToken,
                facebookId: props.facebookData!.id
            }
            await facebookRegister(facebookUser);
        } else if (isRegisterFromGoogle === true) {

            const googleUser: GoogleUserRegisterDTO = {
                ...newUser,
                googleAccessToken: props.googleData!.accessToken,
                googleIdToken: props.googleData!.idToken,
                googleRefreshToken: props.googleData!.refreshToken
            }
            await googleRegister(googleUser);
        } else {
            await normalRegister(newUser);
        }
    }

    const facebookRegister = async (user: FacebookUserRegisterDTO) => {
        try {
            setLoadingRegister(true);
            const result: any = await dispatch(registerWithFacebook(user, user.facebookId, user.facebookAccessToken))
            setLoadingRegister(false);

            if (result.success === true) {
                container.success(<FormattedMessage id="registar.sucesso" />);

                CrossDomainEventHandler.sendEvent();
                setTimeout(function () {
                    window.location.href = "/";
                }, 1200);

            } else {
                container.clear();
                container.error(result.message);
            }

        } catch (ex) {
            container.clear();
            container.error(<FormattedMessage id="registar.erroFacebook" />);
        }
    }

    const googleRegister = async (user: GoogleUserRegisterDTO) => {
        try {
            setLoadingRegister(true);
            const result: any = await dispatch(registerWithGoogle(user, user.googleAccessToken, user.googleRefreshToken, user.googleIdToken))
            setLoadingRegister(false);

            if (result.success === true) {
                container.success(<FormattedMessage id="registar.sucesso" />);

                CrossDomainEventHandler.sendEvent();
                setTimeout(function () {
                    window.location.href = "/";
                }, 1200);

            } else {
                container.clear();
                container.error(result.message);
            }
        } catch (exp) {
            container.clear();
            container.error(<FormattedMessage id="registar.erroGoogle" />);
        }
    }

    const normalRegister = async (user: UserRegisterDTO) => {
        try {

            setLoadingRegister(true);
            const data: any = await dispatch(register(user));
            setLoadingRegister(false);

            if (data.success === true) {
                funnelStep(1);

                const state: VerifyEmailRouteState = {
                    email: email,
                    previousInCheckout: getPreviousInCheckout(),
                    shouldLoginAfterConfirm: true
                }

                history.push(Routes.account.verify, state);
            } else {
                container.clear();
                container.error(`${data.message}`);
            }
        } catch (exp) {
            setLoadingRegister(false);
            container.clear();
            container.error(<FormattedMessage id="registar.erroRegisto" />);
        }
    }

    const getPreviousInCheckout = (): boolean => {
        if (typeof routeState === "undefined" || routeState == null || typeof routeState != "object") return false;
        if (typeof routeState.previousInCheckout === "undefined") return false;
        return routeState.previousInCheckout;
    }

    return (
        <Row>
            <Col xs="12" sm="12" md="12" lg="12">

                <ToastContainer
                    ref={ref => (container = ref)}
                    className="toast-bottom-right"
                />

                <div className="registerTitleParent">
                    <div className="registerTitle">
                        {(isRegisterFromGoogle === true) ? (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.5rem" }}>
                                <FormattedMessage id="registar.registoAtraves" />
                                <span style={{ color: "#3B5998", "fontWeight": 500, "fontSize": "28px" }}>
                                    <FormattedMessage id="registar.googleTitle" />
                                </span>
                                <FcGoogle style={{ fontSize: "25px" }} />
                            </div>
                        ) : (isRegisterFromFacebook === true ? (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.5rem" }}>
                                <FormattedMessage id="registar.registoAtraves" />
                                <span style={{ color: "#3B5998", "fontWeight": 500, "fontSize": "28px" }}>
                                    <FormattedMessage id="registar.facebookTitle" />
                                </span>
                                <FaFacebookF style={{ color: "#3B5998", fontSize: "25px" }} />
                            </div>
                        ) : (
                            <FormattedMessage id="registar.titulo" />
                        ))}
                    </div>
                </div>
                <br />

                <Row style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <Col xs={12} sm={12} md={6} lg={5} xl={4} style={{ textAlign: "left" }}>
                        <IHTPInput controlValue={true} value={email} disabled={canChangeEmail == false} type='text' label='Email' placeholder='Insere o teu email' onChange={setEmail} />
                        <IHTPSpacer verticalSpace="10px" />
                        <IHTPInput ref={fullNameInput} controlValue={true} value={fullName} type='text' label={intl.formatMessage({ id: "registar.nomeCompletoTitle" })} placeholder={intl.formatMessage({ id: "registar.nomeCompletoPlaceholder" })} onChange={setFullName} />
                        <IHTPSpacer verticalSpace="10px" />

                        {isRegisterFromFacebook == false && isRegisterFromGoogle == false && (
                            <>
                                <IHTPInput type='password' label={intl.formatMessage({ id: "registar.passwordTitle" })} placeholder={intl.formatMessage({ id: "registar.passwordPlaceholder" })} onChange={setPassword} />
                                <IHTPSpacer verticalSpace="10px" />

                                <PasswordCheckList minimumCharacters={8}
                                    shouldHaveAtLeastOneLetter={true}
                                    shouldHaveAtLeastOneNumber={true}
                                    shouldHaveAtLeastOneLowercaseLetter={true}
                                    shouldHaveAtLeastOneCapitalLetter={true}
                                    password={password}
                                    onChange={setIsPasswordValid}
                                    showComponent={showPasswordErrors}
                                />

                                <IHTPSpacer verticalSpace="10px" />
                                <IHTPInput type='text' label={intl.formatMessage({ id: "registar.emailRecomendacaoPlaceholderTitle" })} placeholder={intl.formatMessage({ id: "registar.emailRecomendacaoPlaceholder" })} onChange={setRecommendationEmail} />
                                <IHTPSpacer verticalSpace="10px" />
                                <IHTPInput type='text' label={intl.formatMessage({ id: "registar.telefoneTitle" })} placeholder={intl.formatMessage({ id: "registar.telefonePlaceholder" })} onChange={setPhoneNumber} />
                                <IHTPSpacer verticalSpace="10px" />
                            </>
                        )}

                        <span onClick={() => setOpenExtraField(!openExtraField)} style={{ textAlign: "left", cursor: "pointer", fontWeight: "600", fontSize: "13px", fontFamily: "Montserrat", color: "rgba(71, 73, 79, 1)" }}>
                            {intl.formatMessage({ id: "registar.camposExtra" })} {openExtraField == false ? <MdExpandMore size={20} /> : <MdExpandLess size={20} />}
                        </span>

                        <Collapse in={openExtraField}>
                            <IHTPSpacer verticalSpace="10px" />
                            <div style={{ padding: "1rem", border: "1px solid rgba(71, 73, 79, 0.5)", borderRadius: "1rem" }}>
                                <IHTPSelect label={intl.formatMessage({ id: "registar.paisCountry" })} options={countries} placeholderItem={intl.formatMessage({ id: "registar.paisPlaceholder" })} onChange={setCountry} />
                                <IHTPSpacer verticalSpace="10px" />
                                <IHTPInput type='text' label={intl.formatMessage({ id: "registar.localidadeTitle" })} placeholder={intl.formatMessage({ id: "registar.localidadePlaceholder" })} onChange={setCity} />
                                <IHTPSpacer verticalSpace="10px" />
                                <IHTPInput type='date' label={intl.formatMessage({ id: "registar.dataNascimentoTitle" })} placeholder='' onChange={setBirthDate} />
                            </div>
                        </Collapse>

                        <IHTPSpacer verticalSpace="20px" />
                        <div style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                            <IHTPRecaptcha setRecaptchaVerified={setRecaptchaVerified} />
                        </div>

                        <IHTPSpacer verticalSpace="30px" />
                        <IHTPCheckbox value={acceptedRGPD} onChange={setAcceptedRGPD} textComponent={
                            <span style={{ fontSize: "13px", fontWeight: "500" }}>
                                <FormattedMessage id="registar.politicaDados1" />
                                <a href={Routes.empresa.protecaoDados} className="ihtpLink" target="_blank">
                                    <FormattedMessage id="registar.politicaDados2" />
                                </a>.
                            </span>
                        } />
                        <IHTPSpacer verticalSpace="10px" />
                        <IHTPButton text={intl.formatMessage({ id: "registar.registarButton" })} onClick={handleFormSubmit} loading={loadingRegister}
                            disabled={acceptedRGPD === false || recaptchaVerified === false}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={5} xl={4} >
                        <RegisterBenefits />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
