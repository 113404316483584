import Joi from "joi";
import { IntlShape } from "react-intl";
import { EmailSchema } from "../components/Helpers/JoiValidations";

export interface UserRegisterDTO {
    email: string,
    nomeCompleto: string,
    password: string,
    emailRecomendacao: string,
    telefone: string,
    dataNascimento: string,
    pais: string,
    localidade: string,
    refer: string,
    gdpr: string
}

export const UserRegisterDTOSchema = (intl: IntlShape) => Joi.object({
    email: EmailSchema(false, intl),
    nomeCompleto: Joi.string().required().empty().messages({
        'any.required': intl.formatMessage({ id: "registar.nomeCompletoObrigatorio" }),
        'string.base': intl.formatMessage({ id: "registar.nomeCompletoInvalido" }),
        'string.empty': intl.formatMessage({ id: "registar.nomeCompletoVazio" })
    }),
    emailRecomendacao: Joi.string().allow("").email({ tlds: false }).optional().messages({
        "string.base": intl.formatMessage({ id: "registar.emailRecomendacaoInvalido" }),
        "string.email": intl.formatMessage({ id: "registar.emailRecomendacaoInvalido" })
    })
});

export interface FacebookUserRegisterDTO extends UserRegisterDTO {
    facebookAccessToken: string,
    facebookId: string
}

export interface GoogleUserRegisterDTO extends UserRegisterDTO {
    googleAccessToken: string,
    googleRefreshToken: string,
    googleIdToken: string
}