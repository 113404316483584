import { Button, TextField } from "@material-ui/core";
import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";
import { logout } from "../../../../../../../actions/miniCartActions";
import { impersonate } from "../../../../../../../actions/myihtpActions";
import CrossDomainEventHandler from "../../../../../../../helpers/CrossDomainEventHandler";
import RoutesObject from "../../../../../../../helpers/Routes";
import IHTPButton from "../../../../../../Common/IHTPButton";

let container;
class Impersonate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id_cliente: '',
            isImpersonating: false,
            isRemovingImpersonate: false
        }

        this.handleInput = this.handleInput.bind(this);
    };

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    //Funcao para fazer impersonate
    verifyImper = () => async e => {
        e.stopPropagation();

        //Validar Id de Cliente
        if (this.state.id_cliente == null || !this.state.id_cliente || this.state.id_cliente.trim().length <= 0) {
            container.clear();
            container.error("ID de Cliente deve estar preenchido");
            return;
        }

        this.setState({
            isImpersonating: true
        });

        impersonate(this.state.id_cliente)
            .then(data => {
                if (data.success === true) {
                    container.clear();
                    container.success(data.message);
                    this.props.dispatch(logout()).then(() => {
                        CrossDomainEventHandler.sendEvent();
                        setTimeout(function () {
                            window.location = RoutesObject.account.login;
                        }, 2000);
                    });
                } else {
                    container.clear();
                    container.error(data.message);
                }
            })
            .catch(error => {
                container.clear();
                container.error("Erro ao fazer Impersonate");
            })
            .finally(() => {
                this.setState({
                    isImpersonating: false
                });
            });
    };

    //Funcao para fazer impersonate
    removerImpersonate = () => async e => {


        //Validar Id de Cliente
        if (this.state.id_cliente == null || !this.state.id_cliente || this.state.id_cliente.trim().length <= 0) {
            container.clear();
            container.error("ID de Cliente deve estar preenchido");
            return;
        }

        this.setState({
            isRemovingImpersonate: true
        });

        e.stopPropagation();
        impersonate(this.props.user.persona)
            .then(data => {
                if (data.success === true) {
                    container.clear();
                    container.success(data.message);
                    this.props.dispatch(logout()).then(() => {
                        CrossDomainEventHandler.sendEvent();
                        setTimeout(function () {
                            window.location = RoutesObject.account.login;
                        }, 2000);
                    });
                } else {
                    container.clear();
                    container.error(data.message);
                }
            })
            .catch(error => { })
            .finally(() => {
                this.setState({
                    isRemovingImpersonate: false
                });
            });
    };

    render() {
        return (
            <div>

                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                {/* ROW de impersonate*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b>Impersonate</b>
                        </span>
                        <div className="form-group">
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                    <TextField
                                        placeholder="ID do User no qual pretende entrar"
                                        name={"id_cliente"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row style={{ justifyContent: "center" }}>
                                <Col xs="6" sm="6" md="6" lg="4" xl="3" style={{ textAlign: "right" }} >
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.impersonate" })}
                                        toUpper={true}
                                        onClick={this.verifyImper()}
                                        loading={this.state.isImpersonating}
                                        backgroundColor="#4e9aec" />
                                </Col>
                                <Col xs="6" sm="6" md="6" lg="4" xl="3" style={{ textAlign: "right" }} >
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.impersonate.remover" })}
                                        toUpper={true}
                                        onClick={this.removerImpersonate()}
                                        loading={this.state.isRemovingImpersonate} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currencies: state.currencies,
        user: state.user
    };
};
export default injectIntl(connect(mapStateToProps)(Impersonate));
