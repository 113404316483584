import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { changeAccountType } from "../../../../../../../actions/myihtpActions";
import { ToastContainer } from "react-toastr";
import NativeSelect from "@material-ui/core/NativeSelect";
import IHTPButton from '../../../../../../Common/IHTPButton';
import { injectIntl } from 'react-intl';

let container;
class MudarTipoConta extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tipo_cliente: '',
            isChangingType: false
        }

        this.handleInput = this.handleInput.bind(this);
    };

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    // Funcao para mudar tipo de conta
    changeAccount = () => async e => {
        e.stopPropagation();

        //Validar Tipo de Cliente
        if (this.state.tipo_cliente == null || !this.state.tipo_cliente || this.state.tipo_cliente.trim().length <= 0) {
            container.clear();
            container.error("Tipo de Cliente deve estar preenchido");
            return;
        }

        this.setState({
            isChangingType: true
        });

        changeAccountType(this.state.tipo_cliente)
            .then(data => {
                if (data.message.messageType === "success") {
                    container.clear();
                    container.success(data.message.message);
                    setTimeout(function () {
                        window.location.reload();
                    }, 2000);
                } else {
                    container.clear();
                    container.error(data.message.message);
                }
            })
            .catch(error => {
                container.clear();
                container.error("Erro ao Mudar Tipo de Conta");
            })
            .finally(() => {
                this.setState({
                    isChangingType: false
                });
            });
    };

    render() {
        return (
            <div>

                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                {/* ROW de mudar de tipo de conta*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }} >
                        <span style={{ textAlign: "center" }}>
                            <b>Mudar tipo de conta</b>
                        </span>
                        <div className="form-group">
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                    <NativeSelect
                                        name={"tipo_cliente"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }}
                                        fullWidth={true} onChange={this.handleInput}>

                                        <option value=""> Tipo de cliente para visualização </option>
                                        <option value="CF">Cliente final</option>
                                        <option value="CSF">Consultor 20%</option>
                                        <option value="CSR">Consultor Desconto Reduzido</option>
                                        <option value="PR">Promotor</option>
                                        <option value="AF">Afiliado</option>
                                    </NativeSelect>
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row style={{ justifyContent: "center" }}>
                                <Col xs="6" sm="6" md="6" lg="4" xl="3">
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarTipoConta" })}
                                        toUpper={true}
                                        onClick={this.changeAccount()}
                                        loading={this.state.isChangingType} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default injectIntl(MudarTipoConta);