import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastr";
import { useHistory } from 'react-router-dom';
import { changePassword } from "../../../actions/miniCartActions";
import "../../../components/Register/VerifyEmail.css";
import Routes from "../../../helpers/Routes";
import IHTPButton from "../../Common/IHTPButton";
import IHTPInput from "../../Common/IHTPInput";
import IHTPRecaptcha from "../../Common/IHTPRecaptcha";
import IHTPSpacer from "../../Common/IHTPSpacer";
import HeaderDesktop from "../../Desktop/Header/Header";
import PasswordCheckList from "../../Register/PasswordCheckList";
import "./ChangePassword.css";

let container

interface ChangeUserPasswordDTO {
    link: string,
    password: string,
    confirmPassword: string
}

export default function ChangePassword() {

    const [password, setPassword] = useState<string>("");
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const [showPasswordErrors, setShowPasswordErrors] = useState<boolean>(false);

    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState<boolean>(false);
    const [showConfirmPasswordErrors, setShowConfirmPasswordErrors] = useState<boolean>(false);

    const [recaptchaVerified, setRecaptchaVerified] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const user = useSelector((state: any) => state.user);
    const history = useHistory();
    const intl = useIntl();
    const dispatch = useDispatch();

    const changePasswordClick = async () => {
        if (isPasswordValid == false) {
            setShowPasswordErrors(true);
            return;
        }

        if (isConfirmPasswordValid == false) {
            setShowConfirmPasswordErrors(true);
            return;
        }

        if (recaptchaVerified == false) return;

        const user: ChangeUserPasswordDTO = {
            link: window.location.href,
            password: password,
            confirmPassword: confirmPassword
        }

        setLoading(true);
        const response: any = await dispatch(changePassword(user));
        setLoading(false);

        if (response.success === true) {
            container.success(`${response.message}`);
            setTimeout(function () {
                window.location.href = Routes.account.login;
            }, 1250);
        } else {
            container.error(`${response.message}`);
        }
    }

    useEffect(() => {
        if (typeof user !== 'undefined' && user != null && typeof user.userID !== 'undefined') {
            history.replace("/");
            return;
        }

        return () => { }
    }, [user]);

    return (
        <div className="App">
            <HeaderDesktop />
            <ToastContainer
                ref={ref => (container = ref)}
                className="toast-bottom-right"
            />

            <Container className="mainContentContainer">
                <Row style={{ textAlign: "center" }} >
                    <Col xs="12" sm="12" md="12" lg="12">

                        <div className="changePasswordTitleParent">
                            <div className="changePasswordTitle">
                                <FormattedMessage id="mudarPassword.titulo" />
                            </div>
                        </div>
                        <br />

                        <Row style={{ display: "flex", justifyContent: "center" }}>
                            <Col xs={12} sm={12} md={8} lg={5} xl={3}>
                                <IHTPInput type="password" onChange={setPassword}
                                    label={intl.formatMessage({ id: "mudarPassword.pwd" })} placeholder={intl.formatMessage({ id: "mudarPassword.pwdPlaceholder" })} />

                                <IHTPSpacer verticalSpace="10px" />

                                <PasswordCheckList minimumCharacters={8}
                                    shouldHaveAtLeastOneLetter={true}
                                    shouldHaveAtLeastOneNumber={true}
                                    shouldHaveAtLeastOneLowercaseLetter={true}
                                    shouldHaveAtLeastOneCapitalLetter={true}
                                    password={password}
                                    onChange={setIsPasswordValid}
                                    showComponent={showPasswordErrors}
                                />

                                <IHTPSpacer verticalSpace="10px" />

                                <IHTPInput type="password" onChange={setConfirmPassword} onEnter={changePasswordClick}
                                    label={intl.formatMessage({ id: "mudarPassword.confirmPassword" })} placeholder={intl.formatMessage({ id: "mudarPassword.confirmPwdPlaceholder" })} />

                                <PasswordCheckList confirmPassword={confirmPassword}
                                    password={password}
                                    matchPasswords={true}
                                    onChange={setIsConfirmPasswordValid}
                                    showComponent={showConfirmPasswordErrors}
                                />

                                <IHTPSpacer verticalSpace="30px" />

                                <IHTPRecaptcha setRecaptchaVerified={setRecaptchaVerified} />

                                <IHTPSpacer verticalSpace="30px" />

                                <IHTPButton text={intl.formatMessage({ id: "mudarPassword.button" })} loading={loading} disabled={recaptchaVerified === false} onClick={changePasswordClick} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
