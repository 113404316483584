import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

class IncentivosRegras extends Component {
  render() {
    return (
      <Row className="justify incentivos">
        <Col xs={12} sm={12} md={12} lg={12}>
          <h3>
            <FormattedMessage id="myihtp.incentivosRegras.successmodel.title" />
          </h3>
          <br />
          <p>
            <FormattedMessage id="myihtp.incentivosRegras.successmodel.info1" />
            <br />
            <FormattedMessage id="myihtp.incentivosRegras.successmodel.info2" />
            <br />
            <FormattedMessage id="myihtp.incentivosRegras.successmodel.info3" />
            <br />
            <FormattedMessage id="myihtp.incentivosRegras.successmodel.info4" />
            <br />
            <FormattedMessage id="myihtp.incentivosRegras.successmodel.info5" />
            <br />
            <FormattedMessage id="myihtp.incentivosRegras.successmodel.info6" />
            <br />
            <FormattedMessage id="myihtp.incentivosRegras.successmodel.info7" />
            <br />
            <FormattedMessage id="myihtp.incentivosRegras.successmodel.info8" />
          </p>

          <h3>
            <FormattedMessage id="myihtp.incentivosRegras.premios.title" />
          </h3>
          <br />
          {/* <h3>
            "<FormattedMessage id="myihtp.incentivosRegras.premios.duplication.title1" />" <FormattedMessage id="myihtp.incentivosRegras.premios.duplication.title2" />
          </h3>
          <p>
            <FormattedMessage id="myihtp.incentivosRegras.premios.duplication.info1" />
          </p>
          <p>
            <FormattedMessage id="myihtp.incentivosRegras.premios.duplication.info2" />
          </p>
          <p>
            <FormattedMessage id="myihtp.incentivosRegras.premios.duplication.info3" />
          </p>
          <br /> */}
          <h3>"<FormattedMessage id="myihtp.incentivosRegras.premios.salesPerformance.title1" />" <FormattedMessage id="myihtp.incentivosRegras.premios.salesPerformance.title2" /></h3>
          <p>
            <FormattedMessage id="myihtp.incentivosRegras.premios.salesPerformance.info1" />
          </p>
          <br />
          <h3>"<FormattedMessage id="myihtp.incentivosRegras.premios.topExcel.title" />"</h3>
          <p>
            <FormattedMessage id="myihtp.incentivosRegras.premios.topExcel.info1" />
          </p>
          {/* <p>
            <FormattedMessage id="myihtp.incentivosRegras.premios.topExcel.info2" />
          </p> */}
          <br />
          {/* <h3>"<FormattedMessage id="myihtp.incentivosRegras.premios.oneMillion.title" />"</h3>
          <p>
            <FormattedMessage id="myihtp.incentivosRegras.premios.oneMillion.info1" />
          </p>
          <br /> */}

          <h3><FormattedMessage id="myihtp.incentivosRegras.calendarioConvencoes.title" /></h3>
          <br />
          <p><b><FormattedMessage id="myihtp.incentivosRegras.calendarioConvencoes.info1" /></b></p>
          <p><FormattedMessage id="myihtp.incentivosRegras.calendarioConvencoes.info2" /></p>
          <p><FormattedMessage id="myihtp.incentivosRegras.calendarioConvencoes.info3" /></p>
          <p><b><FormattedMessage id="myihtp.incentivosRegras.calendarioConvencoes.info4" /></b></p>
          <p><FormattedMessage id="myihtp.incentivosRegras.calendarioConvencoes.info5" /></p>
          <p><FormattedMessage id="myihtp.incentivosRegras.calendarioConvencoes.info6" /></p>
          <p><b><FormattedMessage id="myihtp.incentivosRegras.calendarioConvencoes.info7" /></b></p>
          <p><FormattedMessage id="myihtp.incentivosRegras.calendarioConvencoes.info8" /></p>
          <p><FormattedMessage id="myihtp.incentivosRegras.calendarioConvencoes.info9" /></p>
          <p><b><FormattedMessage id="myihtp.incentivosRegras.calendarioConvencoes.info10" /></b></p>
          <p><FormattedMessage id="myihtp.incentivosRegras.calendarioConvencoes.info11" /></p>
          <p><FormattedMessage id="myihtp.incentivosRegras.calendarioConvencoes.info12" /></p>
          <p><b><FormattedMessage id="myihtp.incentivosRegras.calendarioConvencoes.info13" /></b></p>
          <br />
        </Col>
      </Row>
    );
  }
}
export default IncentivosRegras;
