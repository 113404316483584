import { Component } from "react";
import SetDefaultCurrency from "./Components/SetDefaultCurrency";

export default class Produtos extends Component {
    render() {
        return (
            <div style={{ marginTop: "2em" }}>
                <SetDefaultCurrency />
            </div>
        )
    }
}