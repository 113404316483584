import React, { Component } from 'react'
import { connect } from "react-redux";
import { CartItemColour, CartItemBgColour } from './../Components/HeaderColour';

class CartQuantityIcon extends Component {
    render() {
        const { cartNumberItems, user } = this.props;

        return (
            !cartNumberItems || typeof cartNumberItems == 'undefined' || cartNumberItems == null || cartNumberItems == 0 ? (
                <span style={{ marginLeft: "5px" }}></span>
            ) : (
                <span
                    style={{
                        borderRadius: "67%",
                        padding: "2px 6px",
                        fontSize: "12px",
                        top: "-9px",
                        left: "-9px",
                        position: "relative",
                        backgroundColor: CartItemBgColour(user),
                        color: CartItemColour(user)
                    }}>
                    {cartNumberItems}
                </span>
            )
        )
    }
}

const mapStateToProps = state => {
    return {
        cartNumberItems: state.cartNumberItems,
        user: state.user
    };
};

export default connect(mapStateToProps)(CartQuantityIcon);
