import React, { Component } from 'react'

export default class LogoutIcon extends Component {
    render() {
        return (
            <svg version="1.1" id="Layer_1" x="0px" y="0px" width="28px" height="28px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30" >
                <g>
                    <g>
                        <g>
                            <path fill="#034275" d="M14.958,25.383H5.614c-0.573,0-1.038-0.466-1.038-1.039V5.655c0-0.572,0.465-1.038,1.038-1.038h9.344
   c0.574,0,1.039-0.464,1.039-1.038c0-0.574-0.465-1.038-1.039-1.038H5.614C3.897,2.541,2.5,3.938,2.5,5.655v18.688
   c0,1.718,1.397,3.115,3.114,3.115h9.344c0.574,0,1.039-0.465,1.039-1.039C15.997,25.846,15.532,25.383,14.958,25.383z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path fill="#034275" d="M27.189,14.261l-6.313-6.229c-0.406-0.402-1.064-0.397-1.467,0.011c-0.403,0.408-0.398,1.064,0.01,1.467
   l4.512,4.453H11.844c-0.574,0-1.039,0.464-1.039,1.037c0,0.574,0.464,1.038,1.039,1.038h12.087l-4.511,4.452
   c-0.409,0.404-0.412,1.061-0.01,1.469c0.202,0.205,0.471,0.309,0.739,0.309c0.263,0,0.525-0.099,0.728-0.297l6.313-6.23
   c0.197-0.194,0.31-0.461,0.31-0.738C27.5,14.722,27.389,14.456,27.189,14.261z"/>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}
