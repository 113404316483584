
export interface IHTPCheckboxProps {
    onChange: (value: boolean) => void,
    textComponent: JSX.Element,
    value: boolean
}

export default function IHTPCheckbox(props: IHTPCheckboxProps) {
    return (
        <div style={{
            display: "flex", justifyContent: "left", alignItems: "center",
            alignContent: "center", gap: "1rem"
        }}>
            <input type="checkbox" checked={props.value} onChange={(e) => props.onChange(e.target.checked)} />
            {props.textComponent}
        </div>
    )
}
