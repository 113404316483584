import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import $ from "jquery";
import "bootstrap";

import * as serviceWorker from "./serviceWorker";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";

import thunk from "redux-thunk";

import reducer from "./reducers/index";


import { getUser } from "./actions/miniCartActions";
import IntlProviderWrapper from './IntlProviderWrapper';

window.jQuery = $;
window.$ = $;

const store = createStore(reducer, applyMiddleware(thunk));

getUser().then(function (user) {
  ReactDOM.render(
    <Provider store={store}>
      <IntlProviderWrapper user={user} />
    </Provider>,
    document.getElementById("root")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
