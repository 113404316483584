import React, { Component } from 'react'
import GerarFaturaRecibo from './Components/GerarFaturaRecibo'
import Impersonate from './Components/Impersonate'
import MudarTipoConta from './Components/MudarTipoConta'
import StarsMesAnterior from './Components/StarsMesAnterior'
import Import from './Components/Import'

export default class Geral extends Component {
    render() {
        return (
            <div>
                <br />
                <br />
                <Impersonate user={this.props.user} />
                <br />
                <hr />
                <br />
                <GerarFaturaRecibo />
                <br />
                <hr />
                <br />
                <MudarTipoConta />
                <br />
                <hr />
                <br />
                <StarsMesAnterior />
                <br />
                <hr />
                <br />
                <Import />
                <br />
            </div>
        )
    }
}
