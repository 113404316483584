import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { obterStarsClient } from "../../../../../../../actions/myihtpActions";
import { ToastContainer } from "react-toastr";
import DialogStars from "../../../Helper/DialogStars";
import { injectIntl } from 'react-intl';
import IHTPButton from '../../../../../../Common/IHTPButton';

let container;
class StarsMesAnterior extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stars: [],
            isGettingStars: false
        }

    };

    //Funcao para obter stars mensais do mes anterior
    obterStars = () => async e => {
        e.stopPropagation();
        this.setState({
            isGettingStars: true
        });

        obterStarsClient()
            .then(data => {
                console.log(data);
                if (data.messageType === "success") {
                    this.handleClickOpenStars();
                    this.setState({ stars: data.data });
                } else {
                    container.clear();
                    container.error(data.message);
                }
            })
            .catch(error => { })
            .finally(() => {
                this.setState({
                    isGettingStars: false
                });
            });
    };

    handleClickOpenStars = () => {
        this.setState({
            openStars: true
        });
    };

    handleCloseStars = value => {
        this.setState({ selectedValueStars: value, openStars: false });
    };

    render() {
        return (
            <div>

                <DialogStars
                    selectedValue={this.state.selectedValueStars}
                    open={this.state.openStars}
                    onClose={this.handleCloseStars}
                    stars={this.state.stars}
                />

                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                {/* ROW para obter stars mensais do mes anterior*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b>Obter stars mês anterior</b>
                        </span>
                        <div className="form-group">
                            <br />
                            <Row style={{ justifyContent: "center" }}>
                                <Col xs="6" sm="6" md="6" lg="4" xl="3">
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.obterStars" })}
                                        toUpper={true}
                                        onClick={this.obterStars()}
                                        loading={this.state.isGettingStars} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default injectIntl(StarsMesAnterior);