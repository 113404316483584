import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap";
import { Label } from "reactstrap";
import { TextField, Button } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { adicionarProduto } from "../../../../../../../actions/myihtpActions";
import { ToastContainer } from "react-toastr";
import { getConsultantById } from '../../../../../../../actions/consultantActions';
import { FormattedMessage, injectIntl } from 'react-intl';
import IHTPButton from '../../../../../../Common/IHTPButton';

let container;
class AdicionarProdutoCarrinho extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id_cliente: '',
            id_produto: '',
            quantidade: '',
            consultantName: "",
            consultantNameFound: false,
            isAddingProduct: false
        }

        this.handleInput = this.handleInput.bind(this);
        this.getConsultantInfo = this.getConsultantInfo.bind(this);
    };

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    //Funcao para fazer adicionar produto ao carrinho
    adicionarProdutoCarrinho = () => async e => {
        e.stopPropagation();

        //Validar id de cliente
        if (this.state.id_cliente == null || this.state.id_cliente.trim().length <= 0) {
            container.clear();
            container.error(this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.adicionarProdutoCarrinho.clientePreenchido" }));
            return;
        }

        //Validar id de produto
        if (this.state.id_produto == null || this.state.id_produto.trim().length <= 0) {
            container.clear();
            container.error(this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.adicionarProdutoCarrinho.produtoPreenchido" }));
            return;
        }

        //Validar quantidade
        if (this.state.quantidade == null || this.state.quantidade <= 0) {
            container.clear();
            container.error(this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.adicionarProdutoCarrinho.quantidadeSuperior" }));
            return;
        }

        this.setState({
            isAddingProduct: true
        });

        adicionarProduto(this.state)
            .then(data => {
                if (data.success === true) {
                    container.clear();
                    container.success(data.message);

                    this.setState({
                        id_cliente: '',
                        id_produto: '',
                        quantidade: '',
                        consultantName: "",
                        consultantNameFound: false
                    });
                } else {
                    container.clear();
                    container.error(data.message);
                }
            })
            .catch(error => {
                container.clear();
                container.error(this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.adicionarProdutoCarrinho.erro" }));
            })
            .finally(() => {
                this.setState({
                    isAddingProduct: false
                });
            });
    };

    //Buscar a Informação do consultor
    getConsultantInfo() {
        getConsultantById(this.state.id_cliente)
            .then(result => {
                console.log(result);
                if (result.success === true) {
                    this.setState(prevState => ({
                        ...prevState,
                        consultantName: result.obj[0].NomeCompleto,
                        consultantNameFound: true
                    }));
                } else {
                    this.setState(prevState => ({
                        ...prevState,
                        consultantName: result.message,
                        consultantNameFound: false
                    }));
                }
            }).catch(err => {
                this.setState(prevState => ({
                    ...prevState,
                    consultantName: this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.subscricaoLeads.verificarConsultor" }),
                    consultantNameFound: false
                }));
            })
    }

    render() {
        return (
            <div>

                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                {/* ROW de para adicionar produto ao carrinho do cliente*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b><FormattedMessage id="myihtp.ferramentasBackOffice.adicionarProdutoCarrinho.title" /></b>
                        </span>
                        <div className="form-group">
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                    <TextField
                                        placeholder={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.adicionarProdutoCarrinho.cliente" })} name={"id_cliente"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        value={this.state.id_cliente} onChange={this.handleInput} onBlur={this.getConsultantInfo}
                                    />
                                </Col>
                            </Row>

                            {/* Mostrar nome de consultor*/}
                            <Row xs={12} sm={12} md={12} lg={12} hidden={this.state.consultantName?.length <= 0} style={{ display: "flex", justifyContent: "center" }}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ padding: "5px 0px 0px 6px", margin: "0px", textAlign: "left", maxWidth: isMobile ? "100%" : "50%" }} >
                                    <Label style={{ textAlign: "left", margin: "0px", padding: "0px", color: (this.state.consultantNameFound === true ? "green" : "#dc3545"), fontWeight: "400" }}>{this.state.consultantName}</Label>
                                </Col>
                            </Row>

                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                    <TextField
                                        placeholder={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.adicionarProdutoCarrinho.produto" })} name={"id_produto"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        value={this.state.id_produto} onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                    <TextField
                                        placeholder={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.adicionarProdutoCarrinho.quantidade" })} name={"quantidade"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        value={this.state.quantidade} onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row style={{ justifyContent: "center" }}>
                                <Col xs="6" sm="6" md="6" lg="4" xl="3" style={{ textAlign: "right" }} >
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.adicionarProdutoCarrinho.botao" })}
                                        toUpper={true}
                                        onClick={this.adicionarProdutoCarrinho()}
                                        loading={this.state.isAddingProduct} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </div>
        )
    }
}

export default injectIntl(AdicionarProdutoCarrinho);