import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import LoginIcon from "../Icons/LoginIcon";
import Routes from "../../../../helpers/Routes";

class LoginButton extends Component {
  render() {

    const { user } = this.props;
    return (
      typeof user.userID === 'undefined' || user.userID == false ? (
        <NavLink className="fake-link" id="fake-link-1" style={{ textDecoration: "none", padding: "0px 15px 0px 10px" }} to={Routes.account.login}>
          <span>
            <LoginIcon />
            <a className="headerLoginTitle" style={{ color: "#ffffff" }}><FormattedMessage id="header.Entrar" /></a>
          </span>
        </NavLink>
      ) : (
        null
      )
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default connect(mapStateToProps)(LoginButton);
