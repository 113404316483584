import React, { Component } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getAllLatestOrders, getOrderDetails } from "../../../../../../actions/myihtpActions";
import Configs from "../../../../../../helpers/Configs";
import OrderDetail from "../../../../../Common/Order/OrderDetail";

class UltimasOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latestOrders: [],
      noDataGetOrders: null,
      details: {},
      itens: [],
      estados: null,
      showPaginationGetOrders: false,
      defaultPageSizeGetOrders: 5,
      detailsOrder: {
        id: "",
        id_cliente: ""
      },
      expanded: {},
      loadingDetails: false
    };
  }

  componentDidMount() {
    getAllLatestOrders()
      .then(data => {
        if (!data.latestOrders.message) {
          this.setState({ latestOrders: data.latestOrders });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleRowExpanded(rowsState, index) {
    this.setState({
      expanded: {
        [index[0]]: !this.state.expanded[index[0]]
      }
    });
  }

  getOrderDetailsData = (id, id_cliente) => async e => {

    this.setState(prevState => ({
      ...prevState,
      loadingDetails: true
    }));

    getOrderDetails(id, id_cliente)
      .then(data => {

        this.setState(prevState => ({
          ...prevState,
          loadingDetails: false
        }));

        if (data.success === true) {
          this.setState({ details: data.obj.Detalhes[0] });
          this.setState({ itens: data.obj.Itens });
          this.setState({ estados: data.obj.Estados });
        }

      })
      .catch(error => {
        this.setState(prevState => ({
          ...prevState,
          loadingDetails: true
        }));
        console.log(error);
      });
  };

  getStates = () => {
    let result = [];

    if (this.state.estados == null || this.state.estados.length <= 0) return result;

    result = this.state.estados.map(e => {
      return {
        date: e.DataMudancaEstado,
        status: e.EstadoEncomenda,
        notes: e.Observacao,
        sentEmail: e.EnviadoMail
      }
    })

    return result;
  }

  getShippingAddress = () => {
    if (this.state.details.moradaEnvioMorada == null) return null;

    return {
      address: this.state.details.moradaEnvioMorada,
      addressName: this.state.details.moradaEnvioNome,
      postalCode: this.state.details.moradaEnvioCodigoPostal,
      location: this.state.details.moradaEnvioLocalidade,
      country: this.state.details.moradaEnvioPais
    }
  }

  render() {
    const { currencies, user } = this.props;
    const ultimasencomendas = {
      columns: [
        {
          Header: "ID_Cliente",
          accessor: "ID_Cliente",
          show: false,
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.id" />
          ),
          accessor: "ID_Encomenda",
          resizable: false,
          width: 85
        },
        {
          Header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.dataEncomenda" />
          ),
          accessor: "DataEncomenda",
          resizable: false,
          width: 100,
          Cell: row => {
            return row.original.DataEncomenda ? (
              <FormattedDate value={new Date(row.original.DataEncomenda)} />
            ) : (
              "-"
            );
          }
        },
        {
          Header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.estadoEncomenda" />
          ),
          accessor: "EstadoEncomenda",
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.nome" />
          ),
          accessor: "NomeCompleto",
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.nomeConsultor" />
          ),
          accessor: "NomeConsultor",
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.precoFinal" />
          ),
          accessor: "PrecoFinal",
          resizable: false,
          width: 100,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.PrecoFinal}
                style="currency"
                currency={row.original.ID_Moeda}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        }
      ]
    };
    return (
      <div>
        <hr />
        <ReactTable
          className="-highlight tableReact"
          expanded={this.state.expanded}
          onExpandedChange={(newExpanded, index, event) =>
            this.handleRowExpanded(newExpanded, index, event)
          }
          data={this.state.latestOrders}
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                height: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader
              }
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            var id_encomenda = null;
            var id_cliente = null;
            if (rowInfo) {
              id_encomenda = rowInfo.original.ID_Encomenda;
              id_cliente = rowInfo.original.ID_Cliente;
            }
            return {
              onClick: this.getOrderDetailsData(id_encomenda, id_cliente),
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          defaultSorted={[
            {
              id: "ID_Encomenda",
              desc: true
            }
          ]}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.latestOrders.length >
              Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={this.props.intl.formatMessage({ id: "table.config.rowsText" })}
          columns={ultimasencomendas.columns}
          SubComponent={row => {
            return (
              <OrderDetail
                loading={this.state.loadingDetails}
                orderId={row.original.ID_Encomenda}
                orderDate={this.state.details.DataEncomenda}
                state={this.state.details.EstadoEncomenda}
                transportTypeDescription={this.state.details.DescricaoTipoTransporte}
                paymentTypeId={this.state.details.ID_TipoPagamento}
                paymentTypeDescription={this.state.details.DescricaoTipoPagamento}
                paymentDate={this.state.details.DataPagamento}
                sentDate={this.state.details.DataEnvio}
                address={this.getShippingAddress()}
                billingAddress={{
                  address: this.state.details.moradaFaturacaoMorada,
                  addressName: this.state.details.moradaFaturacaoNome,
                  vat: this.state.details.moradaFaturacaoNif,
                  postalCode: this.state.details.moradaFaturacaoCodigoPostal,
                  location: this.state.details.moradaFaturacaoLocalidade,
                  country: this.state.details.moradaFaturacaoPais
                }}
                currency={this.state.details.ID_Moeda}
                totalPrice={this.state.details.PrecoTotal}
                totalDiscount={this.state.details.DescontoTotal}
                totalTax={this.state.details.IvaTotal}
                transportCost={this.state.details.CustoTransporte}
                userType={user.userType}
                creditDiscount={this.state.details.DescontoCredito}
                finalPrice={this.state.details.PrecoFinal}
                personalNetPoints={this.state.details.NetPointsPessoal}
                notes={this.state.details.Observacao}
                items={this.state.itens.map(i => {
                  return {
                    id: i.Id,
                    description: i.Descricao,
                    taxPercentage: i.TaxaIva,
                    quantity: i.Quantidade,
                    unitPriceWithoutTax: i.PrecoUnitarioSemIva,
                    unitPriceWithTax: i.PrecoUnitarioComIva
                  }
                })}
                states={this.getStates()}
                mbEntity={this.state.details.EntidadeMB}
                mbReference={this.state.details.ReferenciaMB}
              />
            );
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    currencies: state.currencies,
    user: state.user
  };
};
export default injectIntl(connect(mapStateToProps)(UltimasOrders));
