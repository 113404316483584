import { FormControl, FormHelperText, NativeSelect, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Search } from "@material-ui/icons";
import { IconButton } from 'material-ui';
import React, { Component } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer } from "react-toastr";
import { deleteCampaign, editCampaign, getCampaigns } from "../../../../../../../actions/myihtpActions";
import Configs from "../../../../../../../helpers/Configs";

let container;
var that;

const columns = [
    {
        Header: "Nome de Campanha",
        accessor: "Campanha",
        resizable: false,
        width: 200
    }, {
        Header: "Descrição",
        accessor: "Descricao",
        resizable: false,
    },
    {
        Header: "Anúncio Moosend",
        accessor: "AdMoosend",
        resizable: false,
    },
    {
        Header: "Anúncio Facebook",
        accessor: "AdFacebook",
        resizable: false,
    },
    {
        Header: "",
        accessor: "Remover",
        width: 50,
        Cell: row => {
            return (
                <div>
                    <a>
                        <svg height="15px" viewBox="0 0 512 512.00115" width="15px" xmlns="http://www.w3.org/2000/svg">
                            <path d="m441.378906 70.621094h-105.929687v-34.691406c0-19.808594-16.121094-35.929688-35.929688-35.929688h-140.003906c-19.808594 0-35.929687 16.121094-35.929687 35.929688v34.691406h-105.929688c-9.75 0-17.65625 7.894531-17.65625 17.65625 0 9.757812 7.90625 17.652344 17.65625 17.652344h18.40625l14.652344 336.96875c1.679687 38.757812 33.386718 69.101562 72.175781 69.101562h213.257813c38.785156 0 70.488281-30.34375 72.171874-69.101562l14.652344-336.96875h18.40625c9.75 0 17.65625-7.894532 17.65625-17.652344 0-9.761719-7.90625-17.65625-17.65625-17.65625zm-281.863281-35.3125h140.003906l.617188 35.3125h-141.03125zm213.527344 406.070312c-.863281 19.792969-17.066407 35.3125-36.898438 35.3125h-213.257812c-19.828125 0-36.035157-15.519531-36.898438-35.3125l-14.585937-335.449218h316.226562zm0 0" />
                            <path d="m229.515625 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                            <path d="m300.136719 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                            <path d="m158.898438 406.070312c-9.753907 0-17.65625-7.898437-17.65625-17.65625v-211.863281c0-9.757812 7.902343-17.652343 17.65625-17.652343 9.75 0 17.652343 7.894531 17.652343 17.652343v211.863281c0 9.757813-7.902343 17.65625-17.652343 17.65625zm0 0" />
                        </svg>
                    </a>
                </div>
            );
        }
    }
]

const campaignInitialState = {
    id_campaign: '',
    description: '',
    id_leadtype: '',
    country: '',
    campaignMoosend: {
        id_platform: 1,
        id_ad: '',
        name_ad: ''
    },
    campaignFacebook: {
        id_platform: 2,
        id_ad: '',
        name_ad: ''
    }
}
class VerCampanhas extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id_campaign: '',
            description: '',
            expanded: {},
            activeIndex: "",
            campaigns: [],
            currentCampaign: campaignInitialState
        };

        this.handleInput = this.handleInput.bind(this);
        this.editCampainButtonClick = this.editCampainButtonClick.bind(this);
        this.loadCampaigns = this.loadCampaigns.bind(this);
        this.handleInputMoosend = this.handleInputMoosend.bind(this);
        this.handleInputCampaign = this.handleInputCampaign.bind(this);
        this.handleInputFacebook = this.handleInputFacebook.bind(this);
        this.deleteCampaignClick = this.deleteCampaignClick.bind(this);
    }

    handleRowExpanded(rowsState, index) {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]]
            },
            activeIndex: [index[0]]
        });
    }

    loadCampaigns() {
        getCampaigns(this.state.id_campaign, this.state.description)
            .then(result => {
                if (result.success === true) {
                    this.setState(
                        prevState => ({
                            ...prevState,
                            campaigns: result.obj
                        })
                    );
                }
            })
    }

    componentDidUpdate(prevProps) {

        if (prevProps.isTabActive === false && this.props.isTabActive === true) {
            this.loadCampaigns();
        }

        if (prevProps.campaign !== this.props.campaign) {
            this.loadCampaigns();
        }

    }

    handleInputCampaign(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                currentCampaign: {
                    ...prevState.currentCampaign,
                    [name]: value
                }
            })
        );

        console.log(this.state);
    }

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    handleInputMoosend(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                currentCampaign: {
                    ...prevState.currentCampaign,
                    campaignMoosend: {
                        ...prevState.currentCampaign.campaignMoosend,
                        [name]: value
                    }
                }
            })
        );
    }

    handleInputFacebook(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                currentCampaign: {
                    ...prevState.currentCampaign,
                    campaignFacebook: {
                        ...prevState.currentCampaign.campaignFacebook,
                        [name]: value
                    }
                }
            })
        );
    }

    editCampainButtonClick() {

        var state = { ...this.state.currentCampaign };


        //Validamos os Campos da Campanha
        var result = this.validateCampaignFields(state.id_campaign, state.description, state.id_leadtype, state.country);
        if (result.length > 0) {
            container.clear();
            container.error(result);
            return;
        }

        //Validamos os dados de Anúncio Moosend
        result = this.validateMoosendFields(state.campaignMoosend.id_ad, state.campaignMoosend.name_ad);
        if (result.length > 0) {
            container.clear();
            container.error(result);
            return;
        }

        //Validamos os dados de Anúncio Facebook
        result = this.validateFacebookFields(state.campaignFacebook.id_ad, state.campaignFacebook.name_ad);
        if (result.length > 0) {
            container.clear();
            container.error(result);
            return;
        }

        var plataformas = [];
        plataformas.push({
            Plataforma: state.campaignMoosend.id_platform,
            Anuncio: state.campaignMoosend.id_ad,
            NomeAnuncio: state.campaignMoosend.name_ad
        });

        plataformas.push({
            Plataforma: state.campaignFacebook.id_platform,
            Anuncio: state.campaignFacebook.id_ad,
            NomeAnuncio: state.campaignFacebook.name_ad
        });

        console.log(plataformas);
        editCampaign(state.id_campaign, state.description, state.id_leadtype, state.country, plataformas)
            .then(result => {
                container.clear();
                if (result.success === true) {
                    container.success(result.message);

                    this.setState(
                        prevState => ({
                            ...prevState,
                            currentCampaign: campaignInitialState
                        })
                    );

                    this.handleRowExpanded('', this.state.activeIndex);
                    this.loadCampaigns();
                } else {
                    container.error(result.message);
                }

            }).catch(err => {
                container.clear();
                container.error("Erro ao comunicar com servidor para Editar Campanha");
            })
    }

    validateCampaignFields(id_campaign, description, id_leadtype, country) {

        //Validar se Id de Campanha está preenchido
        if (!id_campaign || id_campaign == null || id_campaign.trim().length <= 0) {
            return "Id de Campanha deve estar preenchido";
        }

        //Validar se Descrição de Campanha está preenchido
        if (!description || description == null || description.trim().length <= 0) {
            return "Descrição de Campanha deve estar preenchido";
        }

        //Validar se País pré-definido está preenchido
        if (!country || country == null || country.trim().length <= 0) {
            return "País pré-definido deve estar preenchido";
        }

        //Validar Tipo Lead preenchido
        if (!id_leadtype || id_leadtype == null || id_leadtype.trim().length <= 0) {
            return "Tipo de Lead deve estar preenchido";
        }

        return "";
    }

    validateMoosendFields(id_ad, name_ad) {

        //Validar se Id de Anúncio está preenchido
        if (!id_ad || id_ad == null || id_ad.trim().length <= 0) {
            return "Id de Mailing List do Moosend deve estar preenchido";
        }

        //Validar se Nome Anúncio está preenchido
        if (!name_ad || name_ad == null || name_ad.trim().length <= 0) {
            return "Nome de Mailing List do Moosend deve estar preenchido";
        }

        return "";
    }

    validateFacebookFields(id_ad, name_ad) {

        //Validar se Id de Anúncio está preenchido
        if (!id_ad || id_ad == null || id_ad.trim().length <= 0) {
            return "Id de Anúncio do Facebook deve estar preenchido";
        }

        //Validar se Nome Anúncio está preenchido
        if (!name_ad || name_ad == null || name_ad.trim().length <= 0) {
            return "Nome de Anúncio do Facebook deve estar preenchido";
        }

        return "";
    }

    deleteCampaignClick(id) {
        deleteCampaign(id)
            .then(result => {

                container.clear();
                if (result.success === true) {
                    container.success(result.message);
                    this.loadCampaigns();
                } else {
                    container.error(result.message);
                }
            })
            .catch(err => {
                container.clear();
                container.error("Erro ao eliminar a campanha");
            })
    }

    render() {
        return (
            <div>
                <label>Ver Campanhas</label>
                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />
                <br />
                <br />
                <Row style={{ display: "flex", alignItems: "center", justifyContent: "center", alignContent: "center" }}>
                    <Col md={2}>
                        <TextField label={"Nome Campanha"} variant="outlined" name="id_campaign" value={this.state.id_campaign} onChange={this.handleInput} />
                    </Col>
                    <Col md={3}>
                        <TextField label={"Descrição Campanha"} variant="outlined" name="description" value={this.state.description} onChange={this.handleInput} />
                    </Col>
                    <Col>
                        <Button variant="contained" color="primary" style={{ margin: "2px" }} onClick={this.loadCampaigns}>
                            Procurar
                            <IconButton style={{ marginLeft: "2px", padding: "0px", color: "white", width: "0px!important", height: "0px!important" }}>
                                <Search style={{ padding: "2px", fontSize: "12px" }} />
                            </IconButton>
                        </Button>
                    </Col>
                </Row>
                <br />
                <ReactTable
                    className="-highlight tableReact"
                    data={this.state.campaigns}
                    expanded={this.state.expanded}
                    onExpandedChange={(newExpanded, index, event) =>
                        this.handleRowExpanded(newExpanded, index, event)
                    }
                    getTheadProps={(state, rowInfo, column) => {
                        return {
                            style: {
                                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                                height: Configs.myIHTP.tableConfig.heightHeader,
                                color: Configs.myIHTP.tableConfig.colorHeader,
                                backgroundColor:
                                    Configs.myIHTP.tableConfig.backgroundColorHeader
                            }
                        };
                    }}
                    getPaginationProps={state => {
                        return {
                            style: {
                                color: Configs.myIHTP.tableConfig.colorHeader,
                                backgroundColor:
                                    Configs.myIHTP.tableConfig.backgroundColorHeader,
                                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
                            }
                        };
                    }}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: (e, handleOriginal) => {
                                if (column.id === "Remover") {

                                    var confirm = window.confirm("Pretende remover a campanha?");

                                    //Se a resposta for afirmativa para remover a campanha
                                    if (confirm) {
                                        this.deleteCampaignClick(rowInfo.original.Campanha);
                                    }

                                } else {
                                    if (handleOriginal) {
                                        handleOriginal();
                                        this.setState(
                                            prevState => ({
                                                ...prevState,
                                                currentCampaign: {
                                                    ...prevState.currentCampaign,
                                                    id_campaign: rowInfo.original.Campanha,
                                                    description: rowInfo.original.Descricao,
                                                    id_leadtype: rowInfo.original.TipoLead,
                                                    country: rowInfo.original.PaisDefault,
                                                    campaignMoosend: {
                                                        ...prevState.currentCampaign.campaignMoosend,
                                                        id_ad: rowInfo.original.IdMoosend,
                                                        name_ad: rowInfo.original.AdMoosend
                                                    },
                                                    campaignFacebook: {
                                                        ...prevState.currentCampaign.campaignFacebook,
                                                        id_ad: rowInfo.original.IdFacebook,
                                                        name_ad: rowInfo.original.AdFacebook
                                                    }
                                                }
                                            })
                                            , () => {
                                                console.log(this.state.currentCampaign);
                                            }
                                        );

                                    }
                                }
                            },
                            style: {
                                "text-align": "center",
                                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
                            }
                        };
                    }}
                    defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
                    showPagination={
                        this.state.campaigns.length >
                            Configs.myIHTP.tableConfig.defaultPageSize
                            ? Configs.myIHTP.tableConfig.showPagination
                            : false
                    }
                    previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
                    nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
                    noDataText={Configs.myIHTP.tableConfig.noDataText}
                    pageText={Configs.myIHTP.tableConfig.pageText}
                    ofText={Configs.myIHTP.tableConfig.ofText}
                    rowsText={this.props.intl.formatMessage({ id: "table.config.rowsText" })}
                    columns={columns}
                    SubComponent={row => {
                        return (
                            <Container style={{ marginTop: "15px", marginBottom: "15px", marginLeft: "15px", marginRight: "15px", alignItems: "center" }}>
                                <Row>

                                    {/* Nome da Campanha e Descrição da Campanha*/}
                                    <Col xs="12" sm="12" md="4" lg="4">
                                        <Row>
                                            <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                <TextField id="id_campaign" type="text" value={this.state.currentCampaign.id_campaign}
                                                    label={"Nome Campanha"} name="id_campaign" margin="normal"
                                                    variant="filled" style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                    readonly={true} disabled
                                                />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>

                                                <TextField id="description" type="text"
                                                    label={"Descrição Campanha"}
                                                    name="description" value={this.state.currentCampaign.description}
                                                    onChange={this.handleInputCampaign}
                                                    margin="normal" variant="outlined" style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center", marginBottom: "5px" }}>
                                                <FormControl style={{ width: isMobile ? "67vw" : "90%" }}>
                                                    <NativeSelect name="country" id="country" placeholder="País" fullWidth={true} onChange={this.handleInputCampaign} value={this.state.currentCampaign.country}>
                                                        <option value="">País</option>
                                                        {this.props.allPaises && this.props.allPaises.length > 0 ?
                                                            (this.props.allPaises.map((result, j) => {
                                                                return (
                                                                    <option value={result.alpha3}>
                                                                        {result.name}
                                                                    </option>
                                                                );
                                                            })
                                                            ) : null}
                                                    </NativeSelect>
                                                    <FormHelperText>País Pré-Definido Campanha</FormHelperText>
                                                </FormControl>
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                <FormControl style={{ width: isMobile ? "67vw" : "90%" }}>
                                                    <NativeSelect name={"id_leadtype"} id={"id_leadtype"} fullWidth={true} onChange={this.handleInputCampaign} value={this.state.currentCampaign.id_leadtype}>
                                                        <option value="">Tipo Lead</option>
                                                        {
                                                            this.props.allLeadTypes.length > 0 ?
                                                                (this.props.allLeadTypes.map(type => {
                                                                    return (<option value={type.Id}>{type.Descricao}</option>)
                                                                }))
                                                                : null
                                                        }
                                                    </NativeSelect>
                                                    <FormHelperText>Tipo Lead</FormHelperText>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs="12" sm="12" md="4" lg="4">
                                        <Row>
                                            <div style={{ border: "1px solid #F88158", borderRadius: "5px", width: isMobile ? "67vw" : "95%", padding: "5px 40px 10px 40px" }}>
                                                <span style={{ textAlign: "center", fontSize: "13.5px" }}>
                                                    Anúncio <span style={{ fontWeight: "600" }}> Moosend</span>
                                                </span>
                                                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center", margin: "0px", padding: "0px" }}>
                                                    <TextField id="id_ad" type="text" value={this.state.currentCampaign.campaignMoosend.id_ad}
                                                        label={"Id Mailing List"} name="id_ad" onChange={this.handleInputMoosend} margin="normal"
                                                        variant="outlined" style={{ fontSize: "12px" }}
                                                    />
                                                </Col>
                                                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center", margin: "0px", padding: "0px" }}>
                                                    <TextField
                                                        id="name_ad" type="text" label={"Nome Mailing List"} name="name_ad" value={this.state.currentCampaign.campaignMoosend.name_ad}
                                                        onChange={this.handleInputMoosend}
                                                        margin="normal" variant="outlined" style={{ fontSize: "12px" }}
                                                    />
                                                </Col>
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col xs="12" sm="12" md="4" lg="4">
                                        <Row>
                                            <div style={{ border: "1px solid #0080FE", borderRadius: "5px", width: isMobile ? "67vw" : "95%", padding: "5px 40px 10px 40px" }}>
                                                <span style={{ textAlign: "center", fontSize: "13.5px" }}>
                                                    Anúncio <span style={{ fontWeight: "600" }}> Facebook</span>
                                                </span>
                                                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                    <TextField id="id_ad" type="text" label={"Id Anúncio Facebook"}
                                                        value={this.state.currentCampaign.campaignFacebook.id_ad}
                                                        name="id_ad"
                                                        onChange={this.handleInputFacebook}
                                                        margin="normal" variant="outlined" style={{ fontSize: "12px" }}
                                                    />
                                                </Col>
                                                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                    <TextField id="name_ad" type="text"
                                                        label={"Nome Anúncio"}
                                                        name="name_ad" value={this.state.currentCampaign.campaignFacebook.name_ad}
                                                        onChange={this.handleInputFacebook}
                                                        margin="normal" variant="outlined"
                                                        style={{ fontSize: "12px" }}
                                                    />
                                                </Col>
                                            </div>
                                        </Row>
                                    </Col>

                                </Row>
                                <br />
                                <br />
                                <Row style={{ alignItems: "center" }}>
                                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                        <Button variant="contained" color="secondary" onClick={this.editCampainButtonClick}>
                                            Guardar Alterações Campanha
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        );
                    }}></ReactTable>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        campaign: state.campaign,
        allPaises: state.allPaises,
        allLeadTypes: state.allLeadTypes
    };
};

export default injectIntl(withRouter(connect(mapStateToProps)(VerCampanhas)));