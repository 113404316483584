import { Button, TextField } from "@material-ui/core";
import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Input } from 'reactstrap';
import { getPaises } from '../../../../../../../actions/miniCartActions';
import { getCompany } from "../../../../../../../actions/myihtpActions";
import IHTPButton from "../../../../../../Common/IHTPButton";
import CriarEmpresaBackOffice from './CriarEmpresaBackOffice';

//Função para validar o NIF através do CheckSum
function validateNIF(value) {
    const nif = typeof value === "string" ? value : value.toString();
    const validationSets = {
        one: ["1", "2", "3", "5", "6", "8"],
        two: [
            "45",
            "70",
            "71",
            "72",
            "74",
            "75",
            "77",
            "79",
            "90",
            "91",
            "98",
            "99"
        ]
    };

    if (nif.length !== 9) {
        return false;
    }

    if (
        !validationSets.one.includes(nif.substr(0, 1)) &&
        !validationSets.two.includes(nif.substr(0, 2))
    ) {
        return false;
    }

    let total =
        nif[0] * 9 +
        nif[1] * 8 +
        nif[2] * 7 +
        nif[3] * 6 +
        nif[4] * 5 +
        nif[5] * 4 +
        nif[6] * 3 +
        nif[7] * 2;
    let modulo11 = Number(total) % 11;

    const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;

    return checkDigit === Number(nif[8]);
}

let color = 'grey';
class VerificarEmpresaAtribuidaBackOffice extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nif: '',
            nifMessage: '',
            nifError: true,
            nifIsVerified: false,
            countries: [],
            country: 'PRT',
            countryName: 'Portugal',
            companyName: '',
            dialogIsOpen: false,
            companyAvailable: false,
            isCreating: false,
            isVerificating: false
        }

        this.handleInput = this.handleInput.bind(this);
        this.verifyNifIsAssociated = this.verifyNifIsAssociated.bind(this);
        this.openCreateCompanyDialog = this.openCreateCompanyDialog.bind(this);
        this.closeCompanyDialog = this.closeCompanyDialog.bind(this);
    };

    componentDidMount() {
        this.props.dispatch(getPaises())
            .then(result => {
                this.setState(
                    prevState => ({
                        ...prevState,
                        countries: result.data.paises
                    })
                );
            })
            .catch(err => {
            })
    }

    handleInput(e) {

        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value,
                nifIsVerified: false,
                nifError: true,
                nifMessage: ''
            })
        );

    }

    //Verificamos o nif 
    verifyNifIsAssociated() {

        //Ficamos com o state
        var state = { ...this.state };
        //Colocamos a informação que estamos a verificar o Nif
        this.setState(
            prevState => ({
                ...prevState,
                nifMessage: 'A verificar...',
                nifError: true,
                nifIsVerified: false,
                companyAvailable: false
            })
        );

        //Se for portugal nif deve ter 9 dígitos
        if (state.country === 'PRT' && (state.nif.length !== 9 || !validateNIF(state.nif))) {
            this.setState(
                prevState => ({
                    ...prevState,
                    nifMessage: 'NIF inválido',
                    nifError: true,
                    nifIsVerified: true,
                    companyAvailable: false
                })
            );
            return;
        }

        if (state.country !== 'PRT' && state.nif.length <= 0) {
            this.setState(
                prevState => ({
                    ...prevState,
                    nifMessage: 'NIF inválido',
                    nifError: true,
                    nifIsVerified: true,
                    companyAvailable: false
                })
            );
            return;
        }


        this.setState({
            isVerificating: true
        });

        //Validamos se a empresa existe
        getCompany(this.state.nif, this.state.country, true)
            .then(result => {

                //Se a resposta da API for bem sucedida
                if (result.success === true) {

                    //Se a lenght for maior que 0 significa que chegou a informação da Empresa
                    if (result.obj.length > 0) {

                        var clientType = "Cliente";
                        if (result.obj[0].TipoCliente == "CS") {
                            clientType = "Consultor"
                        }

                        this.setState(
                            prevState => ({
                                ...prevState,
                                nifMessage: `Empresa já existe e está atribuída ao ${clientType} ${result.obj[0].NomeCliente}`,
                                nifError: true,
                                nifIsVerified: true,
                                companyAvailable: false
                            })
                        );

                        //Significa que não se encontrou a empresa portanto esta pode ser usada
                    } else {
                        this.setState(
                            prevState => ({
                                ...prevState,
                                nifMessage: "Válido para uso",
                                nifError: false,
                                nifIsVerified: true,
                                companyAvailable: true,
                                companyName: result.obj2.NomeEmpresa
                            })
                        );
                    }
                } else {
                    this.setState(
                        prevState => ({
                            ...prevState,
                            nifMessage: result.message,
                            nifError: true,
                            nifIsVerified: true,
                            companyAvailable: false
                        })
                    );
                }
            })
            .catch(err => {
                this.setState(
                    prevState => ({
                        ...prevState,
                        nifMessage: "Erro ao ligar ao servidor",
                        nifError: true,
                        nifIsVerified: true,
                        companyAvailable: false
                    })
                );
            })
            .finally(() => {
                this.setState({
                    isVerificating: false
                })
            })
    }

    //Abrir o ecrã de criar empresa
    openCreateCompanyDialog() {
        this.setState(
            prevState => ({
                ...prevState,
                dialogIsOpen: true,
                isCreating: true
            })
        );
    }

    //Fechar ecrã de criar empresa
    closeCompanyDialog() {
        this.setState(
            prevState => ({
                ...prevState,
                dialogIsOpen: false,
                isCreating: false
            })
        );
    }

    render() {

        //Se o nif estiver verificado
        if (this.state.nifIsVerified) {
            color = (this.state.nifError === true) ? 'red' : 'green';
        } else {
            color = 'grey'
        }

        return (
            <div>
                <CriarEmpresaBackOffice isOpen={this.state.dialogIsOpen}
                    nif={this.state.nif} name={this.state.companyName}
                    country={this.state.country} countryName={this.state.countryName}
                    toggle={this.closeCompanyDialog} />

                {/* Verificar se Empresa está atribuida a alguem*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b>Verificar Empresa Atribuída a Consultor</b>
                        </span>
                        <div className="form-group">
                            <br />

                            {/* Linha Input NIF */}
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={6} lg={6} style={{ textAlign: "right" }} >
                                    <TextField placeholder="NIF Empresa" name={"nif"} id={"nif"} style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        onChange={this.handleInput}
                                        onBlur={this.verifyNifIsAssociated}
                                        value={this.state.nif}
                                    />
                                </Col>
                                <br />
                                <Col xs={12} sm={12} md={6} lg={6} style={{ textAlign: "left" }} >
                                    <Input type="select" name="country" id="country" placeholder="País" style={{ maxWidth: isMobile ? "100%" : "50%" }} onChange={this.handleInput} value={this.state.country}>
                                        <option value="">País</option>
                                        {this.state.countries && this.state.countries.length > 0 ?
                                            (this.state.countries.map((result, j) => {
                                                return (
                                                    <option value={result.alpha3}>
                                                        {result.name}
                                                    </option>
                                                );
                                            })
                                            ) : null}

                                    </Input>
                                </Col>
                            </Row>

                            <br />

                            {/* Linha Resposta API */}
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <label style={{ margin: "0px 5px 0px" }}>Resultado:</label><br />
                                    <label style={{ width: "100%", maxWidth: isMobile ? "100%" : "50%", border: `1px solid ${color}`, color: color, padding: "5px", marginTop: "5px" }}>
                                        <b>{(this.state.nifMessage?.length <= 0) ? "Resultado" : this.state.nifMessage}</b></label>
                                </Col>
                            </Row>

                            <br />
                            <br />

                            {/* Botão para carregar */}
                            <Row style={{ justifyContent: "center" }}>
                                <Col xs="6" sm="6" md="6" lg="4" xl="3" style={{ textAlign: "right" }} >
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "ferramentasUtilizador.empresaAtribuida.verificarEmpresa" })}
                                        toUpper={true}
                                        onClick={this.verifyNifIsAssociated}
                                        loading={this.state.isVerificating} />
                                </Col>
                                <Col xs="6" sm="6" md="6" lg="4" xl="3" style={{ textAlign: "right" }} >
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "ferramentasUtilizador.empresaAtribuida.criarEmpresa" })}
                                        toUpper={true}
                                        disabled={!this.state.companyAvailable}
                                        onClick={this.openCreateCompanyDialog}
                                        loading={this.state.isCreating} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
    };
};

export default injectIntl(connect(mapStateToProps)(VerificarEmpresaAtribuidaBackOffice));