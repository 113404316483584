import React, { Component } from 'react';
import { connect } from "react-redux";
import { changeCurrencyFE } from "../../../../actions/miniCartActions";
import { HeaderColour } from './HeaderColour';

class SelectCurrency extends Component {

    constructor(props) {
        super(props);
        this.handleInput = this.handleInput.bind(this);
    }

    handleInput(e) {
        let value = e.target.value;
        this.props.dispatch(changeCurrencyFE(value));
    }

    render() {
        const { currencies, user } = this.props;

        return (
            <div className="selectCurrencyContainer">
                <select
                    onChange={this.handleInput}
                    name="currency"
                    className="select-css selectCurrencyInput"
                    style={{
                        display: "block",
                        //mudar a cor do header para saber se tem impersonate ou nao
                        backgroundColor: HeaderColour(user)
                    }}>
                    {currencies.selected ? (
                        <option key={currencies.selectedDescription} value={currencies.selected}>
                            {currencies.selectedDescription}{" "}
                        </option>
                    ) : null}
                    {currencies.list
                        ? currencies.list.map((currency, j) => {
                            return currency.ID_Moeda ===
                                currencies.selected ? null : (
                                <option key={currency.ID_Moeda} value={currency.ID_Moeda}>
                                    {currency.Descricao}
                                </option>
                            );
                            // );
                        })
                        : null}
                </select>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        currencies: state.currencies,
    };
};

export default connect(mapStateToProps)(SelectCurrency);