import React from 'react'
import { Input } from 'reactstrap'

export interface IHTPSelectProps {
    label: string,
    placeholderItem: string,
    options: IHTPSelectItem[],
    onChange: (text: string) => void,
}

export interface IHTPSelectItem {
    value: "string" | "number",
    label: string
}

export default function IHTPSelect(props: IHTPSelectProps) {
    return (
        <>
            <span style={{ fontWeight: "600", fontSize: "13px", fontFamily: "Montserrat", color: "rgba(71, 73, 79, 1)" }}>{props.label}</span>
            <Input type="select" style={{ fontSize: "13px" }} placeholder="Teste" onChange={(e) => props.onChange(e.target.value)}>
                <option value={""}>{props.placeholderItem}</option>
                {props.options.length > 0 && props.options.map(o => {
                    return <option value={o.value}>{o.label}</option>
                })}
            </Input>
        </>
    )
}
