export function getAllOccurrences() {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/getAllOccurrences", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function tratarOcorrencia(id_ocorrencia) {
  return new Promise((resolve, reject) => {
    return fetch("/dashboard/tratarOcorrencia", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Ocorrencia: id_ocorrencia
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve();
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
