import React, { Component } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";
import Campanhas from "./Campanhas/Campanhas";
import Clientes from "./Clientes/Clientes";
import Consultores from "./Consultores/Consultores";
import Empresas from "./Empresas/Empresas";
import Geral from "./Geral/Geral";
import Leads from "./Leads/Leads";
import Multimedia from "./Multimedia/Multimedia";
import SubscricaoLeads from "./SubscricaoLeads/SubscricaoLeads";
import Produtos from "./Produtos/Produtos";

let container;
class FerramentasBackOffice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "geral"
    };
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs="12" sm="12" md="12" lg="12">
            <Tabs activeKey={this.state.tab} onSelect={(k) => this.setState({ tab: k })}>
              <Tab eventKey="geral" title={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.tab.geral" })}>
                <Geral user={this.props.user} />
              </Tab>
              <Tab eventKey="clientes" title={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.tab.clientes" })}>
                <Clientes active={(this.state.tab === 'clientes') ? true : false} />
              </Tab>
              <Tab eventKey="campanhas" title={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.tab.campanhas" })}>
                <Campanhas active={(this.state.tab === 'campanhas') ? true : false} />
              </Tab>
              <Tab eventKey="consultores" title={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.tab.consultores" })}>
                <Consultores />
              </Tab>
              <Tab eventKey="empresas" title={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.tab.empresas" })}>
                <Empresas />
              </Tab>
              <Tab eventKey="multimedia" title={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.tab.multimedia" })}>
                <Multimedia />
              </Tab>
              <Tab eventKey="subscricao" title={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.tab.subscricaoLeads" })}>
                <SubscricaoLeads active={(this.state.tab === 'subscricao') ? true : false} />
              </Tab>
              <Tab eventKey="leads" title={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.tab.negociosLeads" })}>
                <Leads active={(this.state.tab === 'leads') ? true : false} />
              </Tab>
              <Tab eventKey="produtos" title={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.tab.produtos" })}>
                <Produtos active={(this.state.tab === 'produtos') ? true : false} />
              </Tab>
            </Tabs>
          </Col>
        </Row>

        <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    currencies: state.currencies,
    user: state.user
  };
};
export default injectIntl(connect(mapStateToProps)(FerramentasBackOffice));
