import React, { Component } from 'react';
import { connect } from "react-redux";
import { changeLanguageFE, setLoadingAllPageContent } from '../../../../actions/miniCartActions';
import { IntlContext } from "../../../../IntlProviderWrapper";
import { HeaderColour } from './HeaderColour';

class SelectLanguage extends Component {

    constructor(props) {
        super(props);
    }

    async handleInputLingua(e) {
        let value = e.target.value;

        this.props.dispatch(setLoadingAllPageContent(true));
        await this.props.dispatch(changeLanguageFE(value));

        if (typeof this.props.callback !== 'undefined') {
            this.props.callback();
        }
    }

    render() {
        const { languages, user } = this.props;

        const isLanguageSelected = (typeof languages.selected != 'undefined' && languages.selected != null);
        const languageSelected = isLanguageSelected ? languages.selected.toUpperCase() : null;

        return (
            <div>
                {/* Botão de selecionar idioma */}
                <IntlContext.Consumer>
                    {({ switchTo }) => (
                        <div className="selectLanguageContainer">
                            <select
                                onChange={async (e) => {
                                    var value = e.target.value;
                                    await this.handleInputLingua(e);
                                    switchTo(value);
                                }
                                }
                                name="language"
                                className="select-css selectLanguageInput"
                                style={{
                                    color: "white",
                                    backgroundColor: HeaderColour(user),
                                    boxShadow: "none"
                                }}
                            >
                                {isLanguageSelected === true ? (
                                    <option key={languageSelected} value={languageSelected}>
                                        {languageSelected}{" "}
                                    </option>
                                ) : null}
                                {languages.list
                                    ? languages.list.map((lingua, j) => {
                                        return isLanguageSelected === true && lingua.ID_Lingua === languageSelected ?
                                            (
                                                null
                                            )
                                            : (
                                                <option key={lingua.ID_Lingua} value={lingua.ID_Lingua.toUpperCase()}>
                                                    {lingua.ID_Lingua.toUpperCase()}
                                                </option>
                                            );
                                    })
                                    : null}
                            </select>
                        </div>
                    )}
                </IntlContext.Consumer>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        languages: state.languages
    };
};

export default connect(mapStateToProps)(SelectLanguage);