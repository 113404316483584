import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { injectIntl } from "react-intl";
// import Footer from "../../../components/Footer/Footer";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";


let container;
class CancelarEncomendaBotao extends Component {
  render() {
    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <svg
          height="20px"
          viewBox="0 0 174.239 174.239"
          width="20px"
          xmlns="http://www.w3.org/2000/svg"
          id="cancelButton"
          onClick={() => {
            this.props.cancelOrder(this.props.id, this.props.estado);
          }}
        >
          <g>
            <path
              fill=""
              d="M87.12,0C39.082,0,0,39.082,0,87.12s39.082,87.12,87.12,87.12s87.12-39.082,87.12-87.12S135.157,0,87.12,0z M87.12,159.305
      c-39.802,0-72.185-32.383-72.185-72.185S47.318,14.935,87.12,14.935s72.185,32.383,72.185,72.185S126.921,159.305,87.12,159.305z"
            />
            <path
              fill=""
              d="M120.83,53.414c-2.917-2.917-7.647-2.917-10.559,0L87.12,76.568L63.969,53.414c-2.917-2.917-7.642-2.917-10.559,0
      s-2.917,7.642,0,10.559l23.151,23.153L53.409,110.28c-2.917,2.917-2.917,7.642,0,10.559c1.458,1.458,3.369,2.188,5.28,2.188
      c1.911,0,3.824-0.729,5.28-2.188L87.12,97.686l23.151,23.153c1.458,1.458,3.369,2.188,5.28,2.188c1.911,0,3.821-0.729,5.28-2.188
      c2.917-2.917,2.917-7.642,0-10.559L97.679,87.127l23.151-23.153C123.747,61.057,123.747,56.331,120.83,53.414z"
            />
          </g>
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
        </svg>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default injectIntl(
  withCookies(connect(mapStateToProps)(CancelarEncomendaBotao))
);
