import Joi from "joi";
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { createCompany, dispatchNewCompany } from "../../../../../../../actions/myihtpActions";

var schema = [];

const initialState = {
    name: '',
    nif: '',
    city: '',
    email: '',
    phonenumber: '',
    country: 'PRT',
    countryName: 'Portugal',
};

let container;
class CriarEmpresa extends Component {

    constructor(props) {
        super(props)

        this.state = initialState;

        this.handleInput = this.handleInput.bind(this);
        this.createCompanyClick = this.createCompanyClick.bind(this);

        this.loadJoiSchema();
    }

    loadJoiSchema() {
        schema = Joi.object().keys({
            nome: Joi.string()
                .trim()
                .min(2)
                .max(100)
                .required()
                .messages({
                    'string.min': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.nomeMin2Caracteres" }),
                    'string.max': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.nomeMax" }),
                    'any.required': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.nomeObrigatorio" }),
                    'string.empty': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.nomeVazio" })
                }),
            telemovel: Joi.string()
                .trim()
                .empty()
                .required()
                .regex(/^[+0-9 ]+$/)
                .messages({
                    'any.trim': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.telemovelTrim" }),
                    'any.required': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.telemovelObrigatorio" }),
                    'string.empty': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.telemovelVazio" }),
                    'string.pattern.base': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.telemovelInvalido" })
                }),
            pais: Joi.string()
                .trim()
                .min(3)
                .max(3)
                .required()
                .messages({
                    'string.min': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.paisMin" }),
                    'string.max': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.paisMax" }),
                    'any.required': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.paisObrigatorio" }),
                }),
            localidade: Joi.string()
                .trim()
                .empty()
                .min(1)
                .max(100)
                .required()
                .messages({
                    'string.min': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.localidadeMin" }),
                    'string.max': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.localidadeMax" }),
                    'any.required': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.localidadeObrigatoria" }),
                    'string.empty': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.localidadeVazia" }),
                }),
            email: Joi.string()
                .trim()
                .required()
                .email({ minDomainSegments: 2, tlds: { allow: false } })
                .messages({
                    'string.email': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.emailValido" }),
                    'any.required': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.emailObrigatorio" }),
                }),
            nif: Joi.string()
                .trim()
                .empty()
                .min(1)
                .required()
                .messages({
                    'string.min': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.nifMin" }),
                    'string.empty': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.nifVazio" }),
                    'any.required': this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.nifObrigatorio" }),
                })
        });
    }

    //Lidar com os inputs de texto
    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    //Ação do botão de criar empresa
    createCompanyClick() {

        //Criamos uma cópia do objeto lead
        let empresa = { ...this.state };

        this.validarDados(empresa)
            .then(() => {
                createCompany(empresa.nif, empresa.name, empresa.city, empresa.email, empresa.phonenumber, empresa.country, null)
                    .then(result => {

                        container.clear();
                        if (result.success === true) {
                            container.success(result.message);
                            this.props.dispatch(dispatchNewCompany(this.props.nif));
                            this.props.toggle();
                        } else {
                            container.error(result.message);
                        }
                    })
                    .catch(err => {
                        container.clear();
                        container.error(this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.erro" }));
                    })
            })
            .catch(err => {
                container.clear();
                container.error(err.message);
            })
    }

    //Validamos os dados preenchidos
    validarDados(empresa) {
        var promise = new Promise(function (resolve, reject) {
            try {
                var validationResult = schema.validate(
                    {
                        nif: empresa.nif,
                        nome: empresa.name,
                        email: empresa.email,
                        localidade: empresa.city,
                        telemovel: empresa.phonenumber,
                        pais: empresa.country,
                    },
                    { abortEarly: true });

                //Se error==null significa que não houve erro
                if (validationResult.error == null) {
                    resolve();

                    // Se houver erro vamos mostrar o erro e mostramos ao utilizadro
                } else {
                    var errorMessage = validationResult.error.details[0].message;
                    reject(new Error(errorMessage));
                }

            } catch (err) {
                console.log(err);
                reject(new Error(this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.preenchaTodosCampos" })));
            }
        });
        return promise;
    }

    //Quando chegam dados de novos componentes
    componentDidUpdate(prevProps) {
        if (typeof prevProps.isOpen !== 'undefined' && prevProps.isOpen === false && this.props.isOpen === true) {
            this.setState(initialState);
            this.setState(
                prevState => ({
                    ...prevState,
                    nif: this.props.nif,
                    country: this.props.country,
                    name: this.props.name,
                    countryName: this.props.countryName
                })
            );
        }
    }

    render() {
        return (
            <div>

                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}
                    style={{ minWidth: "35%" }}>
                    <ModalHeader toggle={this.props.toggle}>
                        <Label><FormattedMessage id="ferramentasUtilizador.criarEmpresa.titulo" /></Label>
                    </ModalHeader>
                    <ModalBody>
                        <div>

                            {/* Input para NIF*/}
                            <FormGroup >
                                <Label for="examplePassword" className="mr-sm-2"> <FormattedMessage id="ferramentasUtilizador.criarEmpresa.nif" /> </Label>
                                <Input type="text" name="nif" id="nif" disabled value={this.state.nif} placeholder={this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.nif" })} />

                                {/* Mostrar se NIF é inválido*/}
                                < FormGroup hidden={!this.state.nifIsVerified} style={{ display: "flex", flexWrap: "nowrap", margin: "10px 0px 0px 0px", alignItems: "center", justifyContent: "start" }}>
                                    <Label style={{ color: (this.state.nifError === false ? "green" : "#dc3545"), fontSize: "25px", marginRight: "4px", padding: "0" }} className={this.state.nifError === false ? "lnr-checkmark-circle" : "lnr-cross-circle"}></Label>
                                    <Label style={{ color: (this.state.nifError === false ? "green" : "#dc3545"), fontWeight: "400" }}>{this.state.nifMessage}</Label>
                                </FormGroup>
                            </FormGroup>

                            {/* Campo Nome */}
                            <FormGroup>
                                <Label for="examplePassword" className="mr-sm-2"><FormattedMessage id="ferramentasUtilizador.criarEmpresa.nome" /></Label>
                                <Input type="text" name="name" id="name" placeholder={this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.nome" })}
                                    onChange={this.handleInput}
                                    value={this.state.name} />
                            </FormGroup>

                            {/* Campo Email */}
                            <FormGroup>
                                <Label for="examplePassword" className="mr-sm-2"><FormattedMessage id="ferramentasUtilizador.criarEmpresa.email" /></Label>
                                <Input type="text" name="email" id="email" placeholder={this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.email" })}
                                    onChange={this.handleInput}
                                    value={this.state.email} />
                            </FormGroup>

                            {/* Campo Localidade */}
                            <FormGroup>
                                <Label for="examplePassword" className="mr-sm-2"><FormattedMessage id="ferramentasUtilizador.criarEmpresa.localidade" /></Label>
                                <Input type="text" name="city" id="city" placeholder={this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.localidade" })}
                                    onChange={this.handleInput}
                                    value={this.state.city} />
                            </FormGroup>

                            {/* Campo Telemóvel */}
                            <FormGroup>
                                <Label for="examplePassword" className="mr-sm-2"> <FormattedMessage id="ferramentasUtilizador.criarEmpresa.telemovel" /></Label>
                                <Input type="text" name="phonenumber" id="phonenumber" placeholder={this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.telemovel" })}
                                    onChange={this.handleInput}
                                    value={this.state.phonenumber} />
                            </FormGroup>

                            {/* País  */}
                            <FormGroup>
                                <Label for="examplePassword" className="mr-sm-2"> <FormattedMessage id="ferramentasUtilizador.criarEmpresa.pais" /></Label>
                                <Input type="text" name="pais" id="pais" disabled value={this.state.countryName} placeholder={this.props.intl.formatMessage({ id: "ferramentasUtilizador.criarEmpresa.pais" })} />
                            </FormGroup>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="link" onClick={this.props.toggle} >  <FormattedMessage id="ferramentasUtilizador.criarEmpresa.botaoCancelar" /> </Button>
                        <Button color="primary" onClick={this.createCompanyClick}>
                            <FormattedMessage id="ferramentasUtilizador.criarEmpresa.botaoCriar" />
                        </Button>{" "}
                    </ModalFooter>
                </Modal >
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

export default injectIntl(connect(mapStateToProps)(CriarEmpresa));