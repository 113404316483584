import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";

import Configs from "../../../../../../helpers/Configs";
import {
  getAllOccurrences,
  tratarOcorrencia
} from "./actions/OcorrenciasActions";
import Button from "@material-ui/core/Button";

import { Row, Col } from "react-bootstrap";

// import renderHTML from "react-render-html";

import { isMobile } from "react-device-detect";

import { FormattedMessage, FormattedDate, injectIntl } from "react-intl";

import DashboardAnalytics from "../../../../../../helpers/ConfigsMyIHTP/Dashboard/DashboardAnalytics";

class Ocorrencias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      occurrences: [],
      expanded: {}
    };
  }

  componentDidMount() {
    this.loadOccurences();
  }

  loadOccurences() {
    getAllOccurrences()
      .then(data => {
        if (data.success === true) {
          this.setState({ occurrences: data.obj });
        }
      }).catch(err => {
        this.setState({ occurrences: [] });
      });
  }

  handleRowExpanded(rowsState, index) {
    this.setState({
      expanded: {
        [index[0]]: !this.state.expanded[index[0]]
      }
    });
  }

  mudarEstadoOcorrencia = id => async e => {
    tratarOcorrencia(id).then(data => {
      this.loadOccurences();
    });
  };

  load
  render() {
    return (
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ReactTable
            className="-highlight tableReact"
            data={this.state.occurrences}
            expanded={this.state.expanded}
            onExpandedChange={(newExpanded, index, event) =>
              this.handleRowExpanded(newExpanded, index, event)
            }
            getTheadProps={(state, rowInfo, column) => {
              return {
                style: {
                  fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                  fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                  height: Configs.myIHTP.tableConfig.heightHeader,
                  color: Configs.myIHTP.tableConfig.colorHeader,
                  backgroundColor:
                    Configs.myIHTP.tableConfig.backgroundColorHeader
                }
              };
            }}
            getPaginationProps={state => {
              return {
                style: {
                  color: Configs.myIHTP.tableConfig.colorHeader,
                  backgroundColor:
                    Configs.myIHTP.tableConfig.backgroundColorHeader,
                  fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
                }
              };
            }}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                style: {
                  "text-align": "center",
                  "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
                  background:
                    (rowInfo && rowInfo.row.tratado === "Sim"
                      ? Configs.myIHTP.tableConfig.encomendas.backgroundColor
                        .green
                      : null) ||
                    (rowInfo && rowInfo.row.tratado === "Não"
                      ? Configs.myIHTP.tableConfig.encomendas.backgroundColor
                        .red
                      : null)
                }
              };
            }}
            defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
            showPagination={
              this.state.occurrences.length >
                Configs.myIHTP.tableConfig.defaultPageSize
                ? Configs.myIHTP.tableConfig.showPagination
                : false
            }
            previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
            nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
            noDataText={Configs.myIHTP.tableConfig.noDataText}
            pageText={Configs.myIHTP.tableConfig.pageText}
            ofText={Configs.myIHTP.tableConfig.ofText}
            rowsText={this.props.intl.formatMessage({ id: "table.config.rowsText" })}
            columns={Configs.myIHTP.tableConfig.ocorrencias.columns}
            SubComponent={row => {
              DashboardAnalytics.visualizarDetalhesOcorrencia();
              return (
                <div
                  style={{
                    "font-size": "14px",
                    padding: "10px",
                    backgroundColor: "#f8f9fa"
                  }}
                >
                  <Row>
                    <Col xs="12" sm="12" md="3" lg="3">
                      <FormattedMessage id="myihtp.ocorrencias.table.subComponent.dataHora" />
                    </Col>
                    <Col xs="12" sm="12" md="9" lg="9">
                      <FormattedDate value={new Date(row.row.dataHora)} />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs="12" sm="12" md="3" lg="3">
                      <FormattedMessage id="myihtp.ocorrencias.table.subComponent.tipoOcorrencia" />
                    </Col>
                    <Col xs="12" sm="12" md="9" lg="9">
                      {row.row.tipo}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs="12" sm="12" md="3" lg="3">
                      <FormattedMessage id="myihtp.ocorrencias.table.subComponent.mensagem" />
                    </Col>
                    <Col xs="12" sm="12" md="9" lg="9">
                      {/* {renderHTML(row.row.mensagem)} */}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        onClick={this.mudarEstadoOcorrencia(
                          row.row.idOcorrencia
                        )}
                        style={{
                          backgroundColor:
                            row.row.tratado === "Sim" ? "#dc3545" : "#28a745",
                          color: "white",
                          width: !isMobile ? "25vw" : "100%"
                        }}
                      >
                        {row.row.tratado === "Sim" ? (
                          <FormattedMessage id="myihtp.ocorrencias.table.subComponent.button.tornarPendente" />
                        ) : (
                          <FormattedMessage id="myihtp.ocorrencias.table.subComponent.button.tratar" />
                        )}
                      </Button>
                    </Col>
                  </Row>
                </div>
              );
            }}
          />
        </Col>
      </Row>
    );
  }
}
export default injectIntl(Ocorrencias);
