import { TextField } from "@material-ui/core";
import { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer } from "react-toastr";
import { getOrderDetailsBackOffice, getOrdersBackOffice, insertCreditNote } from "../../../../../actions/myihtpActions";
// import { refund } from "../../../../../Analytics/DataLayer";
import Configs from "../../../../../helpers/Configs";
import { Sleep } from "../../../../../helpers/UsefulFunctions";
import IHTPButton from "../../../../Common/IHTPButton";
import OrderDetail from "../../../../Common/Order/OrderDetail";
import CancelarEncomendaBotao from "../Encomendas/CancelarEncomendaBotao";
import "../Encomendas/Encomendas.css";
import FaturaBotao from "../Encomendas/FaturaBotao";
import { cancelOrderBackOffice } from "../Encomendas/Helper";
import NotaCreditoBotao from "../Encomendas/NotaCreditoBotao";
import PagamentoBotao from "../Encomendas/PagamentoBotao";
import ReciboBotao from "../Encomendas/ReciboBotao";

let container;

const estadosConcluido = ['PR', 'EC', 'DO', 'EV', 'FE'];
class Encomendas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          Header:
            Configs.myIHTP.tableConfig.encomendas.columns.idEncomenda.Header,
          accessor:
            Configs.myIHTP.tableConfig.encomendas.columns.idEncomenda.accessor,
          width: 150
        },
        {
          Header: Configs.myIHTP.tableConfig.encomendas.columns.idCliente.Header,
          accessor:
            Configs.myIHTP.tableConfig.encomendas.columns.idCliente.accessor,
          width: 300,
          Cell: row => {
            return (
              <span>
                {row.original.ID_Cliente} - {row.original.NomeCompleto}
              </span>
            );
          }
        },
        {
          Header:
            Configs.myIHTP.tableConfig.encomendas.columns.idEstadoEncomenda
              .Header,
          accessor:
            Configs.myIHTP.tableConfig.encomendas.columns.idEstadoEncomenda
              .accessor,
          show:
            Configs.myIHTP.tableConfig.encomendas.columns.idEstadoEncomenda.show,
          width: 250
        },
        {
          Header:
            Configs.myIHTP.tableConfig.encomendas.columns.estadoEncomenda.Header,
          accessor:
            Configs.myIHTP.tableConfig.encomendas.columns.estadoEncomenda
              .accessor,
          show: !isMobile,
          width: 250
        },
        {
          Header:
            Configs.myIHTP.tableConfig.encomendas.columns.dataEncomenda.Header,
          accessor:
            Configs.myIHTP.tableConfig.encomendas.columns.dataEncomenda.accessor,
          show: !isMobile,
          width: 150,
          Cell: row => {
            return row.original.DataEncomenda ? (
              <FormattedDate value={new Date(row.original.DataEncomenda)} />
            ) : (
              "-"
            );
          }
        },
        {
          Header: Configs.myIHTP.tableConfig.encomendas.columns.precoFinal.Header,
          accessor:
            Configs.myIHTP.tableConfig.encomendas.columns.precoFinal.accessor,
          width: 124,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.PrecoFinal}
                style="currency"
                currency={row.original.ID_Moeda}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        },
        {
          Header: "F",
          accessor: "fatura",
          width: 50,
          Cell: row => {
            if (row.original.UrlFatura) {
              return (
                <div>
                  <FaturaBotao url={row.original.UrlFatura} />
                </div>
              );
            } else {
              return <div />;
            }
          },
          resizable: false
        },
        {
          Header: "R",
          accessor: "recibo",
          width: 50,
          Cell: row => {
            if (row.original.UrlRecibo) {
              return <ReciboBotao url={row.original.UrlRecibo} />;
            } else {
              return <div />;
            }
          },
          resizable: false
        },
        {
          Header: "NC",
          accessor: "notaCredito",
          width: 50,
          Cell: row => {
            if (row.original.UrlNotaCredito) {
              return <NotaCreditoBotao url={row.original.UrlNotaCredito} />;
            } else {
              return <div />;
            }
          },
          resizable: false
        },
        {
          Header: "P",
          accessor: "pagamento",
          width: 50,
          Cell: row => {
            if (
              !row.original.UrlFatura &&
              !row.original.UrlRecibo &&
              !row.original.NotaCredito
            ) {
              return <PagamentoBotao hash={row.original.HashEncomenda} />;
            } else {
              return <div />;
            }
          },
          resizable: false
        },
        {
          Header: "C",
          accessor: "cancelarEncomenda",
          width: 50,
          Cell: row => {
            if (row.original.ID_EstadoEncomenda === "AP" || estadosConcluido.includes(row.original.ID_EstadoEncomenda) == true) {
              return (
                <CancelarEncomendaBotao
                  id={row.original.ID_Encomenda}
                  estado={row.original.ID_EstadoEncomenda}
                  cancelOrder={this.cancelOrder}
                />
              );
            } else {
              return <div />;
            }
          },
          resizable: false
        }
      ],
      orders: [],
      loadingDetails: false,
      successGetOrderDetails: false,
      orderDetailsErrorMessage: "",
      details: {},
      itens: [],
      estados: null,
      expanded: {},
      filtered: [],
      recordsPerPage: 10,
      currentPage: 0,
      totalPages: 0,
      orderField: null,
      orderFieldAsc: false,
      loading: false,
      searchOrderId: null,
      successGetOrders: true,
      idEncomendaToCancelInGA4: ''
    };

    this.cancelOrder = this.cancelOrder.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.loadOrders = this.loadOrders.bind(this);
    this.fetchNewInfo = this.fetchNewInfo.bind(this);
    this.handleCancelOrderInGA4 = this.handleCancelOrderInGA4.bind(this);
    this.handleChangeInputOrderIdToCancelInGA4 = this.handleChangeInputOrderIdToCancelInGA4.bind(this);
  }

  componentDidMount() {
    this.loadOrders(this.state.currentPage, this.state.recordsPerPage, this.state.orderField, this.state.orderFieldAsc, this.state.searchOrderId);
  }

  handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
      expanded: {},
      currentPage: 0
    }), () => {
      this.loadOrders(this.state.currentPage, this.state.recordsPerPage, this.state.orderField, this.state.orderFieldAsc,
        this.state.searchOrderId)
    });
  }

  loadOrders(page, pageSize, orderField, orderFieldAsc, orderId) {

    this.setState(prevState => ({
      ...prevState,
      loading: true
    }));

    getOrdersBackOffice(page, pageSize, orderField, orderFieldAsc, orderId)
      .then(data => {
        if (data.success === true) {
          this.setState({
            totalPages: Math.ceil(data.obj.total / pageSize),
            currentPage: page,
            recordsPerPage: pageSize,
            orders: data.obj.dados,
            loading: false,
            orderField: orderField,
            orderFieldAsc: orderFieldAsc,
            successGetOrders: true,
          });
        } else {
          this.setState({
            successGetOrders: false,
            totalPages: 0,
            currentPage: 0,
            recordsPerPage: pageSize,
            orders: [],
            loading: false,
            orderField: orderField,
            orderFieldAsc: orderFieldAsc
          });
        }
      }).catch(err => {
        this.setState({
          successGetOrders: false,
          totalPages: 0,
          currentPage: 0,
          recordsPerPage: pageSize,
          orders: [],
          loading: false,
          orderField: orderField,
          orderFieldAsc: orderFieldAsc
        });
      });
  }

  //CANCELAR ENCOMENDA
  cancelOrder = (id, estado) => {
    var confirm = window.confirm(
      this.props.intl.formatMessage({
        id: "myihtp.encomenda.cancelar"
      })
    );
    if (confirm) {
      //Encomendas que aguardam pagamento
      if (estado == "AP") {
        cancelOrderBackOffice(id).then(result => {
          this.handleResultCancelOrder(id, result);
        });
        return;
      }

      //Se for uma encomenda concluida, então vai fazer o pedido para criar a nota de crédito
      if (estadosConcluido.includes(estado)) {
        insertCreditNote(id).then(data => {
          this.handleResultCancelOrder(id, data);
        });
        return;
      }
    }
  };

  handleResultCancelOrder(id, result) {
    if (result.success === true) {
      //Cancela no GA4    
      // refund(id);

      //Vamos buscar as encomendas atualizadas
      this.loadOrders(this.state.currentPage, this.state.recordsPerPage, this.state.orderField, this.state.orderFieldAsc, this.state.searchOrderId);
      container.clear();
      container.success(result.message);
    } else {
      container.clear();
      container.error(result.message);
    }
  }

  handleRowExpanded(rowsState, index) {
    this.setState({
      expanded: {
        [index[0]]: !this.state.expanded[index[0]]
      }
    });
  }

  getOrderDetailsData = id => async e => {

    //Se tentarmos abrir uma encomenda que já está aberta, não vamos carregar a informação
    if (id == this.state.details.ID_Encomenda) {
      return;
    }

    this.setState(prevState => ({
      ...prevState,
      loadingDetails: true
    }));

    getOrderDetailsBackOffice(id)
      .then(data => {
        if (data.success === true) {

          this.setState(prevState => (
            {
              ...prevState,
              details: data.obj.Detalhes[0],
              itens: data.obj.Itens,
              estados: data.obj.Estados,
              successGetOrderDetails: true,
              orderDetailsErrorMessage: "",
              loadingDetails: false
            }
          ));
        } else {
          this.setState(prevState => (
            {
              ...prevState,
              successGetOrderDetails: false,
              orderDetailsErrorMessage: data.message,
              loadingDetails: false
            }
          ));
        }
      }).catch(error => {

        this.setState(prevState => (
          {
            ...prevState,
            successGetOrderDetails: false,
            orderDetailsErrorMessage: this.props.intl.formatMessage({ id: "myihtp.encomendas.ErroObterEncomendas" }),
            loadingDetails: false
          }
        ));
      });
  };

  fetchNewInfo(page, pageSize, orderField, orderFieldAsc) {

    if (pageSize !== this.state.recordsPerPage) page = 0;
    if (orderField !== this.state.orderField) page = 0;
    if (orderFieldAsc !== this.state.orderFieldAsc) page = 0;

    this.loadOrders(page, pageSize, orderField, orderFieldAsc, this.state.searchOrderId);
  }

  getStates = () => {
    let result = [];

    if (this.state.estados == null || this.state.estados.length <= 0) return result;

    result = this.state.estados.map(e => {
      return {
        date: e.DataMudancaEstado,
        status: e.EstadoEncomenda,
        notes: e.Observacao,
        sentEmail: e.EnviadoMail
      }
    })

    return result;
  }

  getShippingAddress = () => {
    if (this.state.details.moradaEnvioMorada == null) return null;

    return {
      address: this.state.details.moradaEnvioMorada,
      addressName: this.state.details.moradaEnvioNome,
      postalCode: this.state.details.moradaEnvioCodigoPostal,
      location: this.state.details.moradaEnvioLocalidade,
      country: this.state.details.moradaEnvioPais
    }
  }

  handleCancelOrderInGA4 = (id) => {
    getOrderDetailsBackOffice(id).then(result => {
      //Se houver algum erro então mostra mensagem e não cancela no GA4
      if (result.success !== true) {
        container.clear();
        container.error(result.message);
        return;
      }

      //Se a encomenda ainda não tiver sido cancelada na sistema dá erro
      if (result.obj.Detalhes[0].ID_EstadoEncomenda !== 'CC') {
        container.clear();
        container.error("A encomenda ainda não se encontra cancelada");
        return;
      }

      // refund(id);
      container.clear();
      container.success("Encomenda cancelada com sucesso no GA4");
    })
  }


  handleChangeInputOrderIdToCancelInGA4 = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        ...prevState,
        [name]: value
      })
    );
  }

  render() {
    const { user } = this.props;

    return (
      <Row>
        <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

        <Col xs="12" sm="12" md="12" lg="12">

          {/* Input para colocar o id da encomenda que se pretende buscar*/}
          <Row style={{ textAlign: "right", alignItems: "center" }}>
            <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "right", display: "flex", alignItems: "center" }}>
              <span style={{ minHeight: "100%" }}>
                <FormattedMessage id="myihtp.encomendas.ID_Encomenda" />: &nbsp;
              </span>
              <TextField variant="outlined" onChange={this.handleChange} name="searchOrderId" value={this.state.searchOrderId} id="searchOrderId" />
            </Col>
          </Row>

          <br />
          {this.state.successGetOrders === false ?
            <Row>
              <Col xs="12" sm="12" md="12" lg="12">
                <span style={{ color: "red" }}> <FormattedMessage id="myihtp.encomendas.ErroObterEncomendas" /></span>
              </Col>
            </Row>
            : null}

          {/* Resultados da Pesquisa*/}
          <Row style={{ textAlign: "left" }}>
            <Col xs="12" sm="12" md="12" lg="12">

              <br />
              <ReactTable
                className="-highlight tableReact"
                data={this.state.orders}
                expanded={this.state.expanded}
                filtered={this.state.filtered}
                loading={this.state.loading}
                onExpandedChange={(newExpanded, index, event) =>
                  this.handleRowExpanded(newExpanded, index, event)
                }
                getTheadProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                      fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                      height: Configs.myIHTP.tableConfig.heightHeader,
                      color: Configs.myIHTP.tableConfig.colorHeader,
                      backgroundColor:
                        Configs.myIHTP.tableConfig.backgroundColorHeader
                    }
                  };
                }}
                getPaginationProps={state => {
                  return {
                    style: {
                      color: Configs.myIHTP.tableConfig.colorHeader,
                      backgroundColor:
                        Configs.myIHTP.tableConfig.backgroundColorHeader,
                      fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
                    }
                  };
                }}
                getTrProps={(state, rowInfo, column, instance) => {
                  var id_encomenda = null;
                  if (rowInfo) {
                    id_encomenda = rowInfo.original.ID_Encomenda;
                  }

                  return {
                    onClick: this.getOrderDetailsData(id_encomenda),
                    style: {
                      "text-align": "center",
                      "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
                      background:
                        (rowInfo && rowInfo.row.ID_EstadoEncomenda === "AP"
                          ? Configs.myIHTP.tableConfig.encomendas
                            .backgroundColor.yellow
                          : null) ||
                        (rowInfo &&
                          (rowInfo.row.ID_EstadoEncomenda === "PA" ||
                            rowInfo.row.ID_EstadoEncomenda === "PR" ||
                            rowInfo.row.ID_EstadoEncomenda === "EC")
                          ? Configs.myIHTP.tableConfig.encomendas
                            .backgroundColor.green
                          : null) ||
                        (rowInfo &&
                          (rowInfo.row.ID_EstadoEncomenda === "CC" ||
                            rowInfo.row.ID_EstadoEncomenda === "PC")
                          ? Configs.myIHTP.tableConfig.encomendas
                            .backgroundColor.red
                          : null) ||
                        (rowInfo && rowInfo.row.ID_EstadoEncomenda === "FE"
                          ? Configs.myIHTP.tableConfig.encomendas
                            .backgroundColor.cyan
                          : null)
                    }
                  };
                }}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    style: {
                      "text-align": "center",
                      "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
                    }
                  };
                }}
                defaultSorted={[
                  {
                    id: "ID_Encomenda",
                    desc: true
                  }
                ]}
                collapseOnDataChange={
                  Configs.myIHTP.tableConfig.collapseOnDataChange
                }
                pageSizeOptions={[5, 10, 20]}
                defaultPageSize={10}
                showPageJump={false}
                page={this.state.currentPage}
                pages={this.state.totalPages}
                pageSize={this.state.recordsPerPage}
                showPagination={true}
                previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
                nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
                noDataText={Configs.myIHTP.tableConfig.noDataText}
                pageText={Configs.myIHTP.tableConfig.pageText}
                ofText={Configs.myIHTP.tableConfig.ofText}
                rowsText={this.props.intl.formatMessage({ id: "table.config.rowsText" })}
                columns={this.state.columns}
                manual
                onFetchData={(state, instance) => {
                  this.fetchNewInfo(state.page, state.pageSize, state.sorted[0].id, !state.sorted[0].desc);
                }}
                SubComponent={row => {

                  return (
                    <OrderDetail
                      loading={this.state.loadingDetails}
                      errorLoading={!this.state.successGetOrderDetails}
                      orderId={row.original.ID_Encomenda}
                      orderDate={this.state.details.DataEncomenda}
                      state={this.state.details.EstadoEncomenda}
                      transportTypeDescription={this.state.details.DescricaoTipoTransporte}
                      paymentTypeId={this.state.details.ID_TipoPagamento}
                      paymentTypeDescription={this.state.details.DescricaoTipoPagamento}
                      paymentDate={this.state.details.DataPagamento}
                      sentDate={this.state.details.DataEnvio}
                      address={this.getShippingAddress()}
                      billingAddress={{
                        address: this.state.details.moradaFaturacaoMorada,
                        addressName: this.state.details.moradaFaturacaoNome,
                        vat: this.state.details.moradaFaturacaoNif,
                        postalCode: this.state.details.moradaFaturacaoCodigoPostal,
                        location: this.state.details.moradaFaturacaoLocalidade,
                        country: this.state.details.moradaFaturacaoPais
                      }}
                      currency={this.state.details.ID_Moeda}
                      totalPrice={this.state.details.PrecoTotal}
                      totalDiscount={this.state.details.DescontoTotal}
                      totalTax={this.state.details.IvaTotal}
                      transportCost={this.state.details.CustoTransporte}
                      userType={user.userType}
                      creditDiscount={this.state.details.DescontoCredito}
                      finalPrice={this.state.details.PrecoFinal}
                      personalNetPoints={this.state.details.NetPointsPessoal}
                      notes={this.state.details.Observacao}
                      items={this.state.itens.map(i => {
                        return {
                          id: i.Id,
                          description: i.Descricao,
                          taxPercentage: i.TaxaIva,
                          quantity: i.Quantidade,
                          unitPriceWithoutTax: i.PrecoUnitarioSemIva,
                          unitPriceWithTax: i.PrecoUnitarioComIva
                        }
                      })}
                      states={this.getStates()}
                      mbEntity={this.state.details.EntidadeMB}
                      mbReference={this.state.details.ReferenciaMB}
                    />
                  );
                }}
              />

            </Col>
          </Row>

          <br />
          <br />
          <hr></hr>
          <br />
          {/* Input para colocar o id da encomenda que se pretende buscar*/}
          {/* <Row style={{ textAlign: "left" }}>
            <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }} >
              <span style={{ textAlign: "center" }}>
                <b><FormattedMessage id="myihtp.encomendas.ID_EncomendaCancelarGA4Title" /></b>
              </span>
              <div className="form-group">
                <br />
                <Row xs={12} sm={12} md={12} lg={12}>
                  <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                    <TextField
                      placeholder={this.props.intl.formatMessage({ id: "myihtp.encomendas.ID_EncomendaCancelarGA4" })} name={"idEncomendaToCancelInGA4"}
                      style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                      onChange={this.handleChangeInputOrderIdToCancelInGA4}
                    />
                  </Col>
                </Row>
                <Row style={{ justifyContent: "center", paddingTop: "1em" }}>
                  <Col xs="6" sm="6" md="6" lg="4" xl="3">
                    <IHTPButton text={this.props.intl.formatMessage({ id: "common.button.cancelar" })}
                      toUpper={true}
                      onClick={() => this.handleCancelOrderInGA4(this.state.idEncomendaToCancelInGA4)}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row> */}

        </Col>
      </Row >
    );
  }
}

const mapStateToProps = state => {
  return {
    currencies: state.currencies,
    user: state.user
  };
};

export default injectIntl(withRouter(connect(mapStateToProps)(Encomendas)));
