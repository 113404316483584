import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
// import Footer from "../../../components/Footer/Footer";
import { withRouter } from "react-router-dom";
import { fetchUser } from "../../../../../actions/miniCartActions";
import HeaderDesktop from "../../../../../components/Desktop/Header/Header";
import Encomendas from "../../../../../components/Desktop/MyIHTP/Conteudo/Encomendas/Encomendas";
import Sidebar from "../../../../../components/Desktop/MyIHTP/Sidebar/Sidebar";
import "../../MyIHTP.css";

class MyIHTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panelIndex: 0
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchUser()).then(data => {
    });
  }

  getBtnId = e => {
    if (e.target && e.target.nodeName === "BUTTON") {
      this.setState({
        panelIndex: Number(e.target.id)
      });
    }
  };

  render() {
    return (
      <div className="App">
        <HeaderDesktop />
        <br />
        <Container className="mainContentContainer">
          <Row
            style={{
              textAlign: "center"
            }}
          >
            <Col xs="12" sm="12" md="12" lg="3">
              <Sidebar page="Encomendas" />
            </Col>
            <Col xs="12" sm="12" md="12" lg="9">
              {" "}
              <h5 align="center">
                <FormattedMessage id="myihtp.encomendas.title" />
              </h5>
              <hr />
              <Encomendas />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

export default withRouter(connect(mapStateToProps)(MyIHTP));
