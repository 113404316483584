import { NativeSelect } from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import ImageUploader from "react-images-upload";
import { importFile } from "../../../../../../../actions/myihtpActions";
import Toast from "../../../../../../../helpers/Toast";
import IHTPButton from "../../../../../../Common/IHTPButton";

const importOptions = [{
    name: "Clientes",
    endpoint: "admin/importFileClients"
}]

export default function Import() {
    const [selectedOption, setSelectedOption] = useState<string>();
    const [isImporting, setIsImporting] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>();

    const disableImportButton = selectedOption === null || typeof selectedOption === "undefined" || selectedOption === "" || file === null || typeof file === "undefined";

    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(e.target.value);
    }

    const handleClickImport = async () => {
        if (selectedOption === null || typeof selectedOption === "undefined" || selectedOption === "") {
            return;
        }

        setIsImporting(true);

        var endpoint: string = importOptions.find(a => a.name === selectedOption)!.endpoint;
        var resultImportFile = await importFile(file, endpoint);
        setIsImporting(false);

        if (resultImportFile.success === false) {
            Toast.Show("error", resultImportFile.message);
            return;
        }

        Toast.Show("success", "O resultado da importação será enviado por email.");
        setFile(null);
    }

    return (
        <>
            <Row style={{ textAlign: "left" }}>
                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                    <span style={{ textAlign: "center" }}>
                        <b>Importação</b>
                    </span>
                </Col>
            </Row>

            <Row style={{ textAlign: "left" }}>
                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                    <NativeSelect
                        name={"tipo_importacao"}
                        style={{ maxWidth: isMobile ? "100%" : "50%" }}
                        fullWidth={true}
                        onChange={handleSelectChange}>

                        <option value={""} disabled selected>Selecione uma opção</option>

                        {importOptions.map(element => {
                            return (
                                <option value={element.name}>{element.name}</option>
                            )
                        })}
                    </NativeSelect>
                </Col>
            </Row>

            <Row style={{ justifyContent: "center" }}>
                <Col xs="6" sm="6" md="6" lg="4" xl="3">
                    <ImageUploader
                        withIcon={false}
                        withPreview={false}
                        style={{ maxWidth: "100%" }}
                        name="importFile"
                        accept=".xlsx"
                        label={file?.name ?? ""}
                        buttonText={"Escolha um ficheiro"}
                        onChange={(file: File[]) => setFile(file[0])}
                        imgExtension={[".xlsx"]}
                        maxFileSize={999999999}
                        singleImage={true}
                    />
                </Col>
            </Row>

            <Row style={{ justifyContent: "center" }}>
                <Col xs="6" sm="6" md="6" lg="4" xl="3">
                    <IHTPButton text="Importar"
                        toUpper={true}
                        onClick={handleClickImport}
                        loading={isImporting}
                        disabled={disableImportButton} />
                </Col>
            </Row>
        </>
    )
}