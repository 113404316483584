import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";

import Configs from "../../../../../../helpers/Configs";
import { getResaleProducts } from "../../../../../../actions/myihtpActions";
import { injectIntl } from "react-intl";

class ProdutosRevenda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resaleProducts: [],
      noDataGetProducts: null,
      showPaginationGetProducts: false,
      defaultPageSizeGetProducts: 5
    };
  }

  componentDidMount() {
    getResaleProducts()
      .then(data => {
        if (data.resaleProducts.length > 0) {
          this.setState({ resaleProducts: data.resaleProducts });
        }
      })
      .catch(() => {
        this.setState({ resaleProducts: [] });
      });
  }
  render() {
    return (
      <div>
        <ReactTable
          className="-highlight tableReact"
          data={this.state.resaleProducts}
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                height: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader
              }
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.resaleProducts.length >
              Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={this.props.intl.formatMessage({ id: "table.config.rowsText" })}
          columns={Configs.myIHTP.tableConfig.produtosrevenda.columns}
        />
      </div>
    );
  }
}
export default injectIntl(ProdutosRevenda);
