import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import MainMenuIcon from "../Icons/MainMenuIcon";

export default class MenuButtons extends Component {
  render() {
    return (
      <a>
        <MainMenuIcon color={this.props.color} />
        <a className="buttonLabels" style={{ color: "#ffffff", margin: "0px 0px 0px 11px" }}><FormattedMessage id="header.titulo" /></a>
      </a>
    );
  }
}