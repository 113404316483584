import React, { CSSProperties } from "react";
import { CircularProgress, TextField } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { Col, Row } from "react-bootstrap";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import ReactTable from "react-table";
import Configs from "../../../helpers/Configs";

export interface OrderDetailsProps {
    loading?: boolean,
    errorLoading?: boolean,
    orderId: number,
    orderDate: string,
    state: string,
    transportTypeDescription: string,
    paymentTypeId: string,
    paymentTypeDescription: string,
    paymentDate: string,
    sentDate: string,
    address?: OrderAddress,
    billingAddress?: OrderAddress,
    totalPrice: number,
    totalDiscount: number,
    totalTax: number,
    transportCost: number
    currency: string,
    userType: string,
    creditDiscount: number,
    finalPrice: number,
    personalNetPoints: number,
    notes: string,
    items: OrderItem[],
    states: OrderState[],
    mbEntity: string,
    mbReference: string
}

export interface OrderAddress {
    addressName: string,
    address: string,
    postalCode: string,
    location: string,
    country: string,
    vat?: string
}

export interface OrderItem {
    id: number,
    description: string,
    taxPercentage: number,
    quantity: number,
    unitPriceWithoutTax: number,
    unitPriceWithTax: number
}

export interface OrderState {
    date: string,
    status: string,
    notes: string,
    sentEmail: string
}


export default function OrderDetail(props: OrderDetailsProps) {


    const styleTitle: CSSProperties = {
        fontSize: "22px",
        textAlign: "center",
        backgroundColor: "rgba(27, 114, 181, 0.81)",
        color: "rgba(255, 255, 255, 1)"
    };

    const styleSubtitle: CSSProperties = {
        fontSize: "18px",
        textAlign: "center",
        backgroundColor: "#ffe4c4",
        color: "black"
    };

    const styleData: CSSProperties = {
        fontSize: "16px",
        textAlign: "center"
    }

    const styleTable: CSSProperties = {
        backgroundColor: "#ffe4c4",
        color: "black",
        textAlign: "center"
    };

    const styleData2: CSSProperties = {
        fontSize: "16px",
        textAlign: isMobile ? "center" : "left"
    }

    if (props.loading === true) {
        return (
            <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <Col xs="12" sm="12" md="12" lg="12" style={{ padding: "50px", display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <CircularProgress thickness={2} size={35} disableShrink />
                    <p style={{ fontSize: "18px", textAlign: "center", color: "black", fontWeight: 400 }}>
                        <FormattedMessage id="myihtp.encomendas.CarregarDetalhe" />
                    </p>
                </Col>
            </Row>
        );
    }

    if (props.errorLoading === true) {
        return (
            <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
                <Col xs="12" sm="12" md="12" lg="12">
                    <p style={{ fontSize: "18px", textAlign: "center", color: "red", fontWeight: 400, padding: "50px" }}>Erro ao obter os detalhes da Encomenda</p>
                </Col>
            </Row>
        );
    }

    return (
        <div style={{ fontSize: "14px", padding: "10px", backgroundColor: "#f8f9fa" }}>
            <p style={styleTitle} >
                <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.title" />
            </p>
            {props.paymentTypeId === "MB" ? (
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <p style={styleSubtitle}>
                            <FormattedMessage id="myihtp.encomendas.DadosPagamento.Titulo" />
                        </p>

                        <p style={styleData} />
                        <Row>
                            <Col xs="12" sm="12" md="6" lg="6" style={{ textAlign: isMobile ? "center" : "right" }}>
                                <img src="https://my.ihavethepower.net/Content/imagens/emails/multibanco.png" />
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="6" style={{
                                textAlign: isMobile ? "center" : "left", margin: "auto", top: "0px", bottom: "0px"
                            }}>
                                <p style={styleData2}>
                                    <b><FormattedMessage id="myihtp.encomendas.DadosPagamento.mb.Entidade" />:</b>{" "}
                                    {props.mbEntity}
                                </p>
                                <p style={styleData2}>
                                    <b><FormattedMessage id="myihtp.encomendas.DadosPagamento.mb.Referencia" />:</b>{" "}
                                    {props.mbReference}
                                </p>
                                <p style={styleData2}>
                                    <b><FormattedMessage id="myihtp.encomendas.DadosPagamento.mb.Valor" />:</b>{" "}
                                    <FormattedNumber
                                        value={props.finalPrice}
                                        style="currency"
                                        currency={props.currency}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                    />
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ) : null}
            <Row>
                <Col xs="12" sm="12" md="6" lg="6">
                    <p style={styleSubtitle}> <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.IDEncomenda" /></p>
                    <p style={styleData} >
                        {props.orderId}
                    </p>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                    <p style={styleSubtitle}>
                        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.DataEncomenda" />
                    </p>
                    <p style={styleData}>
                        {props.orderDate ? (
                            <FormattedDate value={new Date(props.orderDate)}
                            />
                        ) : null}
                    </p>
                </Col>
            </Row>
            <br />
            <Row>
                <Col xs="12" sm="12" md="6" lg="6">
                    <p style={styleSubtitle}>
                        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.EstadoEncomenda" />
                    </p>
                    <p style={styleData}>
                        {props.state}
                    </p>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                    <p style={styleSubtitle}>
                        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.TipoTransporte" />
                    </p>
                    <p style={styleData}>
                        {props.transportTypeDescription}
                    </p>
                </Col>
            </Row>
            <br />
            <Row>
                <Col xs="12" sm="12" md="6" lg="6">
                    <p style={styleSubtitle}>
                        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.TipoPagamento" />
                    </p>
                    <p style={styleData} >
                        {props.paymentTypeDescription}
                    </p>
                </Col>
                <Col xs="12" sm="12" md="3" lg="3">
                    <p style={styleSubtitle}>
                        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.DataPagamento" />
                    </p>
                    <p style={styleData}>
                        {props.paymentDate ? (
                            <FormattedDate
                                value={new Date(props.paymentDate)}
                            />
                        ) : (
                            <p>-</p>
                        )}
                    </p>
                </Col>
                <Col xs="12" sm="12" md="3" lg="3">
                    <p style={styleSubtitle} >
                        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.DataEnvio" />
                    </p>
                    <p style={styleData} >
                        {props.sentDate ? (
                            <FormattedDate
                                value={new Date(props.sentDate)}
                            />
                        ) : (
                            <p>-</p>
                        )}
                    </p>
                </Col>
            </Row>
            <br />
            <Row>
                {/* Dados de Envio */}
                <Col xs="12" sm="12" md="6" lg="6">
                    <p style={styleSubtitle} >
                        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.DadosEnvio" />
                    </p>
                    <p style={styleData}>
                        {props.address ? (
                            <div>
                                <p>{props.address.addressName}</p>
                                <p>{props.address.address}</p>
                                <p>
                                    {props.address.postalCode}{" "}
                                    {props.address.location}
                                </p>
                                <p>{props.address.country}</p>{" "}
                            </div>
                        ) : (
                            <p><FormattedMessage id="myihtp.encomendas.SemMoradaEnvio" /></p>
                        )}
                    </p>
                </Col>
                {/* Dados de Faturacao */}
                <Col xs="12" sm="12" md="6" lg="6">
                    <p style={styleSubtitle} >
                        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.DadosFaturacao" />
                    </p>
                    <p style={styleData} >
                        {props.billingAddress ? (
                            <div>
                                <p>{props.billingAddress.addressName}</p>
                                <p>{props.billingAddress.vat}</p>
                                <p>{props.billingAddress.address}</p>
                                <p>
                                    {props.billingAddress.postalCode}{" "}
                                    {props.billingAddress.location}
                                </p>
                                <p>{props.billingAddress.country}</p>
                            </div>
                        ) : (
                            <p><FormattedMessage id="myihtp.encomendas.SemMoradaFaturacao" /></p>
                        )}
                    </p>
                </Col>
            </Row>
            <br />

            <Row>
                <Col xs="12" sm="12" md="6" lg="6">
                    <p style={styleSubtitle}>
                        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.DescontoCreditos" />
                    </p>
                    <p style={styleData}>
                        <FormattedNumber
                            value={props.creditDiscount}
                            style="currency"
                            currency={props.currency}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </p>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                    <p style={styleSubtitle} >
                        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.DescontoTotal" />
                    </p>
                    <p style={styleData}>
                        <FormattedNumber
                            value={props.totalDiscount}
                            style="currency"
                            currency={props.currency}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </p>
                </Col>
            </Row>
            <br />
            <Row>
                <Col xs="12" sm="12" md="6" lg="6">
                    <p style={styleSubtitle}>
                        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.IvaTotal" />
                    </p>
                    <p style={styleData}>
                        <FormattedNumber
                            value={props.totalTax}
                            style="currency"
                            currency={props.currency}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </p>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                    <p style={styleSubtitle}>
                        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.CustoTransporte" />
                    </p>
                    <p style={styleData} >
                        <FormattedNumber
                            value={props.transportCost}
                            style="currency"
                            currency={props.currency}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </p>
                </Col>
            </Row>
            <br />
            {props.userType === "CS" ? (
                <Row>
                    <Col xs="12" sm="12" md="4" lg="4">
                        <p style={styleSubtitle}>
                            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.DescontoCreditos" />
                        </p>
                        <p style={styleData}>
                            <FormattedNumber
                                value={props.creditDiscount}
                                style="currency"
                                currency={props.currency}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        </p>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4">
                        <p style={styleSubtitle}>
                            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.PrecoFinal" />
                        </p>
                        <p style={styleData}>
                            <FormattedNumber
                                value={props.finalPrice}
                                style="currency"
                                currency={props.currency}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        </p>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4">
                        <p style={styleSubtitle}>
                            Net Points
                        </p>
                        <p style={styleData}>
                            <FormattedNumber
                                value={props.personalNetPoints}
                                style="decimal"
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        </p>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col xs="12" sm="12" md="6" lg="6">
                        <p style={styleSubtitle} >
                            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.PrecoTotal" />
                        </p>
                        <p style={styleData}>
                            <FormattedNumber
                                value={props.totalPrice}
                                style="currency"
                                currency={props.currency}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        </p>
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="6">
                        <p style={styleSubtitle}>
                            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.PrecoFinal" />
                        </p>
                        <p style={styleData}>
                            <FormattedNumber
                                value={props.finalPrice}
                                style="currency"
                                currency={props.currency}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        </p>
                    </Col>
                </Row>
            )}
            <br />
            <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                    <p style={styleSubtitle} >
                        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.Observacoes" />
                    </p>
                    <p style={styleData}>
                        {props.notes
                            ? props.notes
                            : <FormattedMessage id='myihtp.encomendas.SemObservacoes' />}
                    </p>
                </Col>
            </Row>
            <br />
            <p style={styleTitle}>
                <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.itensEncomenda.title" />
            </p>
            <ReactTable
                getTheadProps={(state, rowInfo, column) => {
                    return {
                        style: styleTable
                    };
                }}
                getTbodyProps={(state, rowInfo, column) => {
                    return {
                        style: {
                            textAlign: "center"
                        }
                    };
                }}
                getPaginationProps={state => {
                    return {
                        style: {
                            color: Configs.myIHTP.tableConfig.colorHeader,
                            backgroundColor:
                                Configs.myIHTP.tableConfig.backgroundColorHeader,
                            fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
                        }
                    };
                }}
                data={props.items}
                columns={[
                    {
                        Header: <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.itensEncomenda.Nome" />,
                        accessor: "description",
                        resizable: false,
                        Cell: row => {
                            return (
                                <a className="fake-link" id="fake-link-1" href={process.env.REACT_APP_MAIN_WEBSITE_PRODUCTDETAIL_URL_PT + row.original.id}>
                                    {row.original.description}
                                </a>
                            );
                        }
                    },
                    {
                        Header: <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.itensEncomenda.Iva" />,
                        accessor: "taxPercentage",
                        width: 100,
                        resizable: false,
                        Cell: row => {
                            return (
                                <FormattedNumber
                                    value={row.original.taxPercentage}
                                    style="percent"
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                />
                            );
                        }
                    },
                    {
                        Header: <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.itensEncomenda.Quantidade" />,
                        accessor: "quantity",
                        width: 100,
                        resizable: false
                    },
                    {
                        Header: <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.itensEncomenda.PrecoUnitario" />,
                        accessor: "unitPriceWithoutTax",
                        width: 100,
                        resizable: false,
                        Cell: row => {
                            return (
                                <FormattedNumber
                                    value={row.original.unitPriceWithTax}
                                    style="currency"
                                    currency={props.currency}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                />
                            );
                        }
                    }
                ]}
                showPagination={Configs.myIHTP.tableConfig.showPagination}
                defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
            />
            <br />
            <p style={styleTitle}>
                <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.evolucaoEncomenda.title" />
            </p>
            {props.states ? (
                <ReactTable
                    getTheadProps={(state, rowInfo, column) => {
                        return {
                            style: styleTable
                        };
                    }}
                    getTbodyProps={(state, rowInfo, column) => {
                        return {
                            style: {
                                textAlign: "center"
                            }
                        };
                    }}
                    getPaginationProps={state => {
                        return {
                            style: {
                                color: Configs.myIHTP.tableConfig.colorHeader,
                                backgroundColor: Configs.myIHTP.tableConfig.backgroundColorHeader,
                                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
                            }
                        };
                    }}
                    data={props.states}
                    columns={[
                        {
                            Header: <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.evolucaoEncomenda.Data" />,
                            accessor: "date",
                            resizable: false,
                            Cell: row => {
                                return row.original.date ? (
                                    <FormattedDate
                                        value={new Date(row.original.date)}
                                    />
                                ) : (
                                    "-"
                                );
                            }
                        },
                        {
                            Header: <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.evolucaoEncomenda.EstadoEncomenda" />,
                            accessor: "status",
                            resizable: false
                        },
                        {
                            Header: <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.evolucaoEncomenda.Observacao" />,
                            accessor: "notes",
                            resizable: false,
                            Cell: row => {
                                return row.original.notes
                                    ? row.original.notes
                                    : <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.evolucaoEncomenda.NaoHaObservacoes" />
                            }
                        },
                        {
                            Header: <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.evolucaoEncomenda.EnviadoMail" />,
                            accessor: "sentEmail",
                            resizable: false,
                            Cell: row => {
                                return (
                                    <span>
                                        {row.original.sentEmail.startsWith("S") ? (
                                            <FormattedMessage id="myihtp.infopessoal.moradas.sim" />
                                        ) : (
                                            <FormattedMessage id="myihtp.infopessoal.moradas.nao" />
                                        )}
                                    </span>
                                )
                            }
                        }
                    ]}
                    showPagination={true}
                    defaultPageSize={5}
                />
            ) : null}
        </div>
    )
}