import { TextField } from "@material-ui/core";
import { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage, injectIntl } from 'react-intl';
import { ToastContainer } from "react-toastr";
import { changeCompanyConsultant, getCompany } from "../../../../../../../actions/myihtpActions";
import IHTPButton from '../../../../../../Common/IHTPButton';

let container;
class MudarConsultorEmpresa extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nif: '',
            id_consultor: '',
            mensagem: '',
            isChangingConsultant: false
        }

        this.handleInput = this.handleInput.bind(this);
        this.changeCompanyConsultantButtonClick = this.changeCompanyConsultantButtonClick.bind(this);
        this.getCompanyNameOnTextChange = this.getCompanyNameOnTextChange.bind(this);
    };

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    //Função para buscar o nome da empresa
    getCompanyNameOnTextChange() {

        getCompany(this.state.nif)
            .then(result => {
                if (result.success === true) {

                    var nome = "";
                    if (result.obj.length > 0) {
                        nome = result.obj[0].Nome;
                    } else {
                        nome = this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarConsultorEmpresa.empresaNaoExiste" });
                    }

                    //Alteramos o valor do campo         
                    this.setState(
                        prevState => ({
                            ...prevState,
                            mensagem: nome
                        })
                    );
                }
            })
            .catch(err => {

            })

    }

    //Função para mudar o Consultor de uma lead
    changeCompanyConsultantButtonClick = () => async e => {

        //Validamos se o Campo NIF está preenchido
        if (this.state.nif.length <= 0) {
            container.clear();
            container.error(this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarConsultorEmpresa.nifPreenchido" }));
            return;
        }

        //Validamos se o Campo Consultor está preenchido
        if (this.state.id_consultor == null || this.state.id_consultor.trim().length <= 0) {
            container.clear();
            container.error(this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarConsultorEmpresa.consultorPreenchido" }));
            return;
        }

        this.setState({
            isChangingConsultant: true
        });

        changeCompanyConsultant(this.state.nif, this.state.id_consultor)
            .then(result => {

                container.clear();
                if (result.success === true) {
                    container.success(result.message);

                    this.setState({
                        nif: '',
                        id_consultor: '',
                        mensagem: '',
                    });

                } else {

                    if (typeof result.message === 'undefined') {
                        container.error(this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarConsultorEmpresa.erroComunicarServidor" }));
                    } else {
                        container.error(result.message);
                    }
                }
            })
            .catch(err => {
                container.clear();
                container.error(this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarConsultorEmpresa.erroComunicarServidor" }));
            })
            .finally(() => {
                this.setState({
                    isChangingConsultant: false
                });
            })
    }


    render() {
        return (
            <div>

                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                {/* ROW alterar empresa a que consultor está atribuido*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b><FormattedMessage id="myihtp.ferramentasBackOffice.mudarConsultorEmpresa.title" /></b>
                        </span>
                        <div className="form-group">
                            <br />

                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <TextField placeholder={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarConsultorEmpresa.nifEmpresa" })} name={"nif"} id={"nif"} style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        onChange={this.handleInput}
                                        onBlur={this.getCompanyNameOnTextChange}
                                        value={this.state.nif}
                                    />
                                </Col>
                            </Row>

                            <br />

                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <label style={{ margin: "0px 5px 0px" }}><FormattedMessage id="myihtp.ferramentasBackOffice.mudarConsultorEmpresa.nomeEmpresa" /></label><br />
                                    <label style={{ width: "100%", maxWidth: isMobile ? "100%" : "50%", border: "1px solid grey", color: "grey", padding: "5px", marginTop: "5px" }}>
                                        <b>{(this.state.mensagem.length <= 0) ? this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarConsultorEmpresa.nomeEmpresa" }) : this.state.mensagem}</b>
                                    </label>
                                </Col>
                            </Row>

                            <br />

                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <TextField placeholder={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarConsultorEmpresa.consultor" })} name={"id_consultor"} style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        onChange={this.handleInput}
                                        value={this.state.id_consultor}
                                    />
                                </Col>
                            </Row>

                            <br />
                            <br />

                            <Row style={{ justifyContent: "center" }}>
                                <Col xs="6" sm="6" md="6" lg="4" xl="3" style={{ textAlign: "right" }} >
                                    <IHTPButton text={this.props.intl.formatMessage({ id: "myihtp.ferramentasBackOffice.mudarConsultorEmpresa.botao" })}
                                        toUpper={true}
                                        onClick={this.changeCompanyConsultantButtonClick()}
                                        loading={this.state.isChangingConsultant} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default injectIntl(MudarConsultorEmpresa);