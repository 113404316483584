import {
  injectIntl,
  FormattedNumber,
  FormattedDate,
  FormattedMessage
} from "react-intl";
import React from "react";
import { Row, Col } from "react-bootstrap";
import ArrowDownStatus from "../../components/Desktop/MyIHTP/Conteudo/Dashboard/IndicadoresDashboard/ArrowDownStatus";
import ArrowStatus from "../../components/Desktop/MyIHTP/Conteudo/Dashboard/IndicadoresDashboard/ArrowStatus";
import InformationIcon from "../../components/Desktop/MyIHTP/Conteudo/Comissoes/Helper/InformationIcon";
import Routes from "../Routes";

import "moment/locale/pt";
var moment = require("moment");
moment.locale("pt");

class DashboardConfigs {
  constructor() {
    // Função para previsões mensais
    function BusinessCircleState(state) {
      if (state < 25) {
        return (
          <span>
            <svg
              height="15px"
              viewBox="0 0 512 512.00115"
              width="15px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: "#FF0000" }}
            >
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
            </svg>
          </span>
        );
      } else if (state >= 25 && state < 50) {
        return (
          <span>
            <svg
              height="15px"
              viewBox="0 0 512 512.00115"
              width="15px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: "#FC8701" }}
            >
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
            </svg>
          </span>
        );
      } else if (state >= 50 && state < 75) {
        return (
          <span>
            <svg
              height="15px"
              viewBox="0 0 512 512.00115"
              width="15px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: "#FCFF00" }}
            >
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
            </svg>
          </span>
        );
      } else if (state >= 75 && state < 100) {
        return (
          <span>
            <svg
              height="15px"
              viewBox="0 0 512 512.00115"
              width="15px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: "#1600FF" }}
            >
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
            </svg>
          </span>
        );
      } else if (state >= 100) {
        return (
          <span>
            <svg
              height="15px"
              viewBox="0 0 512 512.00115"
              width="15px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: "#01FF01" }}
            >
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
            </svg>
          </span>
        );
      }
    }

    this.informacoesBase = {
      consultor: (
        <FormattedMessage id="myihtp.informacaoBase.consultor.title" />
      ),
      dataValidade: (
        <FormattedMessage id="myihtp.informacaoBase.dataValidade.title" />
      ),
      seuUpline: (
        <FormattedMessage id="myihtp.informacaoBase.consultorSeuUpline.title" />
      ),
      gestorConta: (
        <FormattedMessage id="myihtp.informacaoBase.gestorConta.title" />
      ),
      seusDadosConsultor: (
        <FormattedMessage id="myihtp.informacaoBase.seusDadosConsultor.title" />
      ),
      dashboard: <FormattedMessage id="myihtp.dashboard.title" />
    };
    // Tabela de Ocorrências
    this.ocorrencias = {
      columns: [
        {
          Header: (
            <FormattedMessage id="myihtp.ocorrencias.table.ID de Ocorrencia" />
          ),
          accessor: "idOcorrencia",
          show: false,
          resizable: false
        },
        {
          Header: <FormattedMessage id="myihtp.ocorrencias.table.DataHora" />,
          accessor: "dataHora",
          resizable: false,
          Cell: row => {
            return <FormattedDate value={new Date(row.original.dataHora)} />;
          } /* </span> */
          // <span>
          //   {c.original.dataHora}
          /*c.original.dataHora &&
                moment(c.original.dataHora).format("MM/DD/YYYY")*/
          // )
        },
        {
          Header: (
            <FormattedMessage id="myihtp.ocorrencias.table.TipoOcorrencia" />
          ),
          accessor: "tipo",
          resizable: false
        },
        {
          Header: <FormattedMessage id="myihtp.ocorrencias.table.Mensagem" />,
          accessor: "mensagem",
          show: false,
          resizable: false
        },
        {
          Header: <FormattedMessage id="myihtp.ocorrencias.table.Tratado" />,
          accessor: "tratado",
          show: false,
          resizable: false
        }
      ]
    };

    // Tabela de Previsão de Mês Atual
    this.previsaomensalatual = {
      columns: [
        {
          Header: "ID_Forecast",
          accessor: "ID_Forecast",
          show: false,
          resizable: false
        },
        {
          Header: <FormattedMessage id="myihtp.previsaoMesAtual.table.Ano" />,
          accessor: "Ano",
          width: 50,
          resizable: false
        },
        {
          Header: <FormattedMessage id="myihtp.previsaoMesAtual.table.Mes" />,
          accessor: "Mes",
          width: 50,
          resizable: false
        },
        {
          Header: "Volume Pessoal",
          accessor: "VolumePessoal_P",
          show: false,
          resizable: false
        },
        {
          Header: "Volume Pessoal R",
          accessor: "VolumePessoal_R",
          show: false,
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.VolumePessoal_R}
                style="currency"
                currency={row.original.ID_Moeda}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        },
        {
          Header: "Volume Pessoal Percentagem Alcancada",
          accessor: "VolumePessoalPercentagemAlcancada",
          show: false,
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.VolumePessoalPercentagemAlcancada}
                style="currency"
                currency={row.original.ID_Moeda}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        },
        {
          Header: (
            <FormattedMessage id="myihtp.previsaoMesAtual.table.VolumesPessoais" />
          ),
          filterable: false,
          sortable: false,
          Cell: row => {
            return (
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  style={{ textAlign: "center" }}
                >
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumePessoal_P}
                        style="currency"
                        currency={row.original.ID_Moeda}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {Math.round(row.row.VolumePessoal_R)}
                      {"€"}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {BusinessCircleState(
                        row.row.VolumePessoalPercentagemAlcancada
                      )}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {Math.round(row.row.VolumePessoalPercentagemAlcancada)}{" "}
                      {"%"}
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          },
          resizable: false
        },
        {
          Header: "Volume Kits",
          accessor: "VolumeKits_P",
          show: false,
          resizable: false
        },
        {
          Header: "Volume Kits R",
          accessor: "VolumeKits_R",
          show: false,
          resizable: false
        },
        {
          Header: "Volume Kits Percentagem Alcancada",
          accessor: "VolumeKitsPercentagemAlcancada",
          show: false,
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.previsaoMesAtual.table.VolumesKits" />
          ),
          filterable: false,
          sortable: false,
          Cell: row => {
            return (
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  style={{ textAlign: "center" }}
                >
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      {Math.round(row.row.VolumeKits_P)}
                      {"€"}
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {Math.round(row.row.VolumeKits_R)}
                      {"€"}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {BusinessCircleState(
                        row.row.VolumeKitsPercentagemAlcancada
                      )}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {Math.round(row.row.VolumeKitsPercentagemAlcancada)}
                      {"%"}
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          },
          resizable: false
        },
        {
          Header: "Volume Clientes",
          accessor: "VolumeClientes_P",
          show: false,
          resizable: false
        },
        {
          Header: "Volume Clientes R",
          accessor: "VolumeClientes_R",
          show: false,
          resizable: false
        },
        {
          Header: "Volume Clientes Percentagem Alcancada",
          accessor: "VolumeClientesPercentagemAlcancada",
          show: false,
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.previsaoMesAtual.table.VolumesClientes" />
          ),
          filterable: false,
          sortable: false,
          Cell: row => {
            return (
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  style={{ textAlign: "center" }}
                >
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      {Math.round(row.row.VolumeClientes_P)}
                      {"€"}
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {Math.round(row.row.VolumeClientes_R)}
                      {"€"}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {BusinessCircleState(
                        row.row.VolumeClientesPercentagemAlcancada
                      )}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {Math.round(row.row.VolumeClientesPercentagemAlcancada)}
                      {"%"}
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          },
          resizable: false
        },
        {
          Header: "Totais",
          accessor: "Totais_P",
          show: false,
          resizable: false
        },
        {
          Header: "Totais R",
          accessor: "Totais_R",
          show: false,
          resizable: false
        },
        {
          Header: "Total Percentagem Alcancada",
          accessor: "TotalPercentagemAlcancada",
          show: false,
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.previsaoMesAtual.table.Totais" />
          ),
          filterable: false,
          sortable: false,
          Cell: row => {
            return (
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  style={{ textAlign: "center" }}
                >
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      {Math.round(row.row.Totais_P)}
                      {"€"}
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {Math.round(row.row.Totais_R)}
                      {"€"}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {BusinessCircleState(row.row.TotalPercentagemAlcancada)}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {Math.round(row.row.TotalPercentagemAlcancada)}
                      {"%"}
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          },
          resizable: false
        },
        {
          Header: "",
          accessor: "totais",
          width: 50,
          Cell: row => {
            return (
              <div>
                <a>
                  <svg
                    height="15px"
                    viewBox="0 0 512 512.00115"
                    width="15px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m441.378906 70.621094h-105.929687v-34.691406c0-19.808594-16.121094-35.929688-35.929688-35.929688h-140.003906c-19.808594 0-35.929687 16.121094-35.929687 35.929688v34.691406h-105.929688c-9.75 0-17.65625 7.894531-17.65625 17.65625 0 9.757812 7.90625 17.652344 17.65625 17.652344h18.40625l14.652344 336.96875c1.679687 38.757812 33.386718 69.101562 72.175781 69.101562h213.257813c38.785156 0 70.488281-30.34375 72.171874-69.101562l14.652344-336.96875h18.40625c9.75 0 17.65625-7.894532 17.65625-17.652344 0-9.761719-7.90625-17.65625-17.65625-17.65625zm-281.863281-35.3125h140.003906l.617188 35.3125h-141.03125zm213.527344 406.070312c-.863281 19.792969-17.066407 35.3125-36.898438 35.3125h-213.257812c-19.828125 0-36.035157-15.519531-36.898438-35.3125l-14.585937-335.449218h316.226562zm0 0" />
                    <path d="m229.515625 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                    <path d="m300.136719 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                    <path d="m158.898438 406.070312c-9.753907 0-17.65625-7.898437-17.65625-17.65625v-211.863281c0-9.757812 7.902343-17.652343 17.65625-17.652343 9.75 0 17.652343 7.894531 17.652343 17.652343v211.863281c0 9.757813-7.902343 17.65625-17.652343 17.65625zm0 0" />
                  </svg>
                </a>
              </div>
            );
          },
          resizable: false
        }
      ]
    };

    // Tabela de Comparativo Mensal
    this.comparativomensal = {
      columns: [
        {
          Header: () => {
            return (
              <div>
                <FormattedMessage id="myihtp.comparativoMensal.table.AnoMes" />{" "}
              </div>
            );
          },
          accessor: "Ano",
          resizable: false,
          Cell: row => (
            <div>
              <span title={row.value}>{row.value}</span>
            </div>
          )
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.NPC" />,
          accessor: "NPC",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPC} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPC} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.NPP" />,
          accessor: "NPP",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPP} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPP} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.NPR" />,
          accessor: "NPR",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPR} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPR} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.CRD" />,
          accessor: "CRD",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.CRD} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusCRD} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.VCM" />,
          accessor: "VCM",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VCM} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVCM} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.NEN" />,
          accessor: "NEN",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NEN} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNEN} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.VEN" />,
          accessor: "VEN",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VEN} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVEN} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.NPT" />,
          accessor: "NPT",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPT} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPT} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.TBG" />,
          accessor: "TBG",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.TBG} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusTBG} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.VBG" />,
          accessor: "VBG",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VBG} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVBG} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.NRT" />,
          accessor: "NRT",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NRT} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNRT} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.TBL" />,
          accessor: "TBL",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.TBL} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusTBL} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.VBLD" />,
          accessor: "VBLD",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VBLD} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVBLD} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.VBL" />,
          accessor: "VBL",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VBL} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVBL} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.NLB" />,
          accessor: "NLB",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NLB} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNLB} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.VLB" />,
          accessor: "VLB",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VLB} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVLB} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoMensal.table.VTL" />,
          accessor: "VTL",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VTL} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVTL} />
                ) : null}
              </div>
            );
          }
        }
      ]
    };

    // Tabela de Comparativo Anual
    this.comparativoanual = {
      columns: [
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.Ano" />,
          accessor: "Ano",
          resizable: false
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.NPC" />,
          accessor: "NPC",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPC} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPC} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.NPP" />,
          accessor: "NPP",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPP} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPP} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.NPR" />,
          accessor: "NPR",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPR} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPR} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.CRD" />,
          accessor: "CRD",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.CRD} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusCRD} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.VCM" />,
          accessor: "VCM",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VCM} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVCM} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.NEN" />,
          accessor: "NEN",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NEN} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNEN} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.VEN" />,
          accessor: "VEN",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VEN} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVEN} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.NPT" />,
          accessor: "NPT",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPT} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPT} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.TBG" />,
          accessor: "TBG",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.TBG} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusTBG} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.VBG" />,
          accessor: "VBG",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VBG} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVBG} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.NRT" />,
          accessor: "NRT",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NRT} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNRT} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.TBL" />,
          accessor: "TBL",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.TBL} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusTBL} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.VBLD" />,
          accessor: "VBLD",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VBLD} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVBLD} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.VBL" />,
          accessor: "VBL",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VBL} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVBL} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.NLB" />,
          accessor: "NLB",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NLB} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNLB} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.VLB" />,
          accessor: "VLB",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VLB} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVLB} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.VTL" />,
          accessor: "VTL",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VTL} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVTL} />
                ) : null}
              </div>
            );
          }
        }
      ]
    };

    // Tabela de Aniversários
    this.aniversarios = {
      columns: {
        nome: {
          header: <FormattedMessage id="myihtp.listaAniversarios.table.nome" />,
          accessor: "nome",
          resizable: false
        },
        anos: {
          header: <FormattedMessage id="myihtp.listaAniversarios.table.anos" />,
          accessor: "anos",
          resizable: false,
          width: 100
        },
        dataNascimento: {
          header: (
            <FormattedMessage id="myihtp.listaAniversarios.table.dataNascimento" />
          ),
          accessor: "dataDeNascimento",
          resizable: false,
          width: 100,
          Cell: row => {
            return (
              <FormattedDate value={new Date(row.original.dataDeNascimento)} />
            );
          }
        },
        IdClienteRecomendacao: {
          header: (
            <FormattedMessage id="myihtp.listaAniversarios.table.clienteRecomendacao" />
          ),
          accessor: "nomeRecomendacao",
          resizable: false
        }
      }
    };

    // Tabela de ultimas encomendas
    this.ultimasencomendas = {
      columns: [
        {
          Header: "ID_Cliente",
          accessor: "ID_Cliente",
          show: false,
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.id" />
          ),
          accessor: "ID_Encomenda",
          resizable: false,
          width: 85
        },
        {
          Header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.dataEncomenda" />
          ),
          accessor: "DataEncomenda",
          resizable: false,
          width: 100,
          Cell: row => {
            return (
              <FormattedDate value={new Date(row.original.DataEncomenda)} />
            );
          }
        },
        {
          Header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.estadoEncomenda" />
          ),
          accessor: "EstadoEncomenda",
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.nome" />
          ),
          accessor: "NomeCompleto",
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.nomeConsultor" />
          ),
          accessor: "NomeConsultor",
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.precoFinal" />
          ),
          accessor: "PrecoFinal",
          resizable: false,
          width: 100,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.PrecoFinal}
                style="currency"
                currency={row.original.ID_Moeda}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        }
      ]
    };

    //Tabela de detalhe da encomenda
    this.orderDetails = {
      title: (
        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.title" />
      ),
      styleTitle: {
        fontSize: "22px",
        textAlign: "center",
        backgroundColor: "rgba(27, 114, 181, 0.81)",
        color: "rgba(255, 255, 255, 1)"
      },
      styleSubtitle: {
        fontSize: "18px",
        textAlign: "center",
        backgroundColor: "#ffe4c4",
        color: "black"
      },
      styleData: {
        fontSize: "16px",
        textAlign: "center"
      },
      data: {
        idEncomenda: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.IDEncomenda" />
          )
        },
        dataEncomenda: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.DataEncomenda" />
          )
        },
        estadoEncomenda: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.EstadoEncomenda" />
          )
        },
        tipoTransporte: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.TipoTransporte" />
          )
        },
        tipoPagamento: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.TipoPagamento" />
          )
        },
        dadosPagamento: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.DataPagamento" />
          )
        },
        dadosEnvio: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.DadosEnvio" />
          )
        },
        dataEnvio: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.DataEnvio" />
          )
        },
        dadosFaturacao: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.DadosFaturacao" />
          )
        },
        precoTotal: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.PrecoTotal" />
          )
        },
        descontoTotal: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.DescontoTotal" />
          )
        },
        ivaTotal: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.IvaTotal" />
          )
        },
        custoTransporte: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.CustoTransporte" />
          )
        },
        descontoCredito: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.DescontoCreditos" />
          )
        },
        precoFinal: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.PrecoFinal" />
          )
        },
        netPoints: {
          title: "Net Points"
        },
        observacao: {
          title: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.Observacoes" />
          )
        }
      }
    };

    //Tabela de itens de encomenda
    this.itensDetails = {
      title: (
        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.itensEncomenda.title" />
      ),
      styleTitle: {
        fontSize: "22px",
        textAlign: "center",
        backgroundColor: "rgba(27, 114, 181, 0.81)",
        color: "rgba(255, 255, 255, 1)"
      },
      styleTable: {
        backgroundColor: "#ffe4c4",
        color: "black"
      },
      columns: {
        nome: {
          header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.itensEncomenda.Nome" />
          ),
          accessor: "nome",
          resizable: false
        },
        iva: {
          header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.itensEncomenda.Iva" />
          ),
          accessor: "taxaiva",
          resizable: false,
          width: 100
        },
        quantidade: {
          header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.itensEncomenda.Quantidade" />
          ),
          accessor: "Quantidade",
          resizable: false,
          width: 100
        },
        precoUnitario: {
          header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.itensEncomenda.PrecoUnitario" />
          ),
          accessor: "PrecoUnitarioComIva",
          resizable: false,
          width: 100
        },
        precoUnitarioCS: {
          header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.itensEncomenda.PrecoUnitario" />
          ),
          accessor: "PrecoUnitarioComIva",
          resizable: false,
          width: 100
        }
      }
    };

    this.changeOrderState = {
      title: (
        <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.evolucaoEncomenda.title" />
      ),
      styleTitle: {
        fontSize: "22px",
        textAlign: "center",
        backgroundColor: "rgba(27, 114, 181, 0.81)",
        color: "rgba(255, 255, 255, 1)"
      },
      styleTable: {
        backgroundColor: "#ffe4c4",
        color: "black"
      },
      columns: {
        data: {
          header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.evolucaoEncomenda.Data" />
          ),
          accessor: "DataMudancaEstado",
          resizable: false
        },
        estadoEncomenda: {
          header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.evolucaoEncomenda.EstadoEncomenda" />
          ),
          accessor: "EstadoEncomenda",
          resizable: false
        },
        observacao: {
          header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.evolucaoEncomenda.Observacao" />
          ),
          accessor: "Observacao",
          resizable: false
        },
        emailEnviado: {
          header: (
            <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.evolucaoEncomenda.EnviadoMail" />
          ),
          accessor: "EnviadoMail",
          resizable: false,
        }
      }
    };

    // Tabela de próximos cursos
    this.proximoscursos = {
      columns: [
        {
          Header: <FormattedMessage id="myihtp.proximosCursos.table.curso" />,
          accessor: "Curso",
          resizable: false,
          Cell: row => {
            return (
              <span
                className="fake-link"
                id="fake-link-1"
                onClick={() => {
                  window.open(Routes.redirectToProduct(row.original.ID_Produto), "_blank");
                }}
              >
                {row.original.Curso}
              </span>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.proximosCursos.table.local" />,
          accessor: "Local",
          resizable: false,
          width: 115
        },
        {
          Header: (
            <FormattedMessage id="myihtp.proximosCursos.table.dataInicio" />
          ),
          accessor: "DataInicio",
          resizable: false,
          width: 115
        }
      ]
    };

    // Tabela de próximos cursos para academy manager
    this.proximoscursosmanagers = {
      columns: [
        {
          Header: <FormattedMessage id="myihtp.proximosCursos.table.curso" />,
          accessor: "Curso",
          resizable: false,
          Cell: row => {
            return (
              <span
                className="fake-link"
                id="fake-link-1"
                onClick={() => {
                  window.open(Routes.redirectToProduct(row.original.ID_Produto), "_blank");
                }}
              >
                {row.original.Curso}
              </span>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.proximosCursos.table.local" />,
          accessor: "Local",
          resizable: false,
          width: 115
        },
        {
          Header: (
            <FormattedMessage id="myihtp.proximosCursos.table.dataInicio" />
          ),
          accessor: "DataInicio",
          resizable: false,
          width: 115
        },
        {
          Header: (
            <FormattedMessage id="myihtp.proximosCursos.table.NInscricoes" />
          ),
          accessor: "NInscritos",
          resizable: false,
          width: 115
        },
        {
          Header: (
            <FormattedMessage id="myihtp.proximosCursos.table.JaPagas" />
          ),
          accessor: "NPagos",
          resizable: false,
          width: 115
        }
      ]
    };


    // Tabela de Ultimas Inscrições
    this.ultimasinscricoes = {
      columns: [
        {
          Header: (
            <FormattedMessage id="myihtp.ultimasInscricoesCursosEventos.table.cursoEvento" />
          ),
          accessor: "cursoEvento",
          resizable: false,
          Cell: row => {
            return (
              <span
                className="fake-link"
                id="fake-link-1"
                onClick={() => {
                  window.open(Routes.redirectToProduct(row.original.ID_Produto), "_blank");
                }}
              >
                {row.original.cursoEvento}
              </span>
            );
          }
        },
        {
          Header: (
            <FormattedMessage id="myihtp.ultimasInscricoesCursosEventos.table.nomeEncomenda" />
          ),
          accessor: "nomeEncomenda",
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.ultimasInscricoesCursosEventos.table.nomePresenca" />
          ),
          accessor: "nomePresenca",
          resizable: false
        }
      ]
    };
  }
}

var Config = new DashboardConfigs();

export default Config;
