import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { FormattedDate, injectIntl } from "react-intl";
import Configs from "../../../../../../helpers/Configs";
import { getAllBirthday, getUserContactsRede } from "../../../../../../actions/myihtpActions";
import DialogInformation from "../../Helper/DialogInformation";
import InformationIcon from "../../Comissoes/Helper/InformationIcon";

class Aniversarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Birthdays: [],
      noDataGetBirthday: null,
      showPaginationGetBirthday: false,
      defaultPageSizeGetBirthday: 5,
      clientId: null
    };
  }

  componentDidMount() {
    getAllBirthday().then(data => {
      if (
        data.Birthdays.message !== "Não tem nenhum aniversario" &&
        !data.Birthdays.error
      ) {
        this.setState({ Birthdays: data.Birthdays });
      }
    });
  }

  getUserContacts(id_cliente) {
    getUserContactsRede(id_cliente)
      .then(data => {
        this.setState({ user: data });
      })
      .catch(err => {
        console.log(err);
      });
  }

  openContactInformationDialog = (clientId) => {
    this.setState({
      clientId: clientId,
      open: true
    });
  }
  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
  };

  render() {
    const columnsGetBirthday = [
      {
        Header: "",
        accessor: Configs.myIHTP.tableConfig.aniversarios.columns.nome.accessor,
        resizable: false,
        width: 32,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo && rowInfo.original.ID_Cliente
                  ? "rgba(180, 206, 239, 0.54)"
                  : null
            }
          };
        },
        Cell: row => {
          return (
            <span
              className="fake-link"
              id="fake-link-1"
              onClick={() => {
                this.openContactInformationDialog(row.original.ID_Cliente);
              }}
            >
              <InformationIcon />
            </span>
          );
        }
      },
      {
        Header: Configs.myIHTP.tableConfig.aniversarios.columns.nome.header,
        accessor: Configs.myIHTP.tableConfig.aniversarios.columns.nome.accessor,
        resizable: false
        // Cell: row => {
        //   return (
        //     <span
        //       class="fake-link"
        //       id="fake-link-1"
        //       onClick={() => {
        //         this.handleClickOpen();
        //         this.getUserContacts(row.original.ID_Cliente);
        //       }}
        //     >
        //       <InformationIcon />
        //       {row.original.nome}
        //     </span>
        //   );
        // },
      },
      {
        Header: Configs.myIHTP.tableConfig.aniversarios.columns.anos.header,
        accessor: Configs.myIHTP.tableConfig.aniversarios.columns.anos.accessor,
        resizable: false
      },
      {
        Header:
          Configs.myIHTP.tableConfig.aniversarios.columns.dataNascimento.header,
        accessor:
          Configs.myIHTP.tableConfig.aniversarios.columns.dataNascimento
            .accessor,
        Cell: row => {
          return (
            <FormattedDate value={new Date(row.original.dataDeNascimento)} />
          );
        },
        resizable: false
      },
      {
        Header:
          Configs.myIHTP.tableConfig.aniversarios.columns.IdClienteRecomendacao
            .header,
        accessor:
          Configs.myIHTP.tableConfig.aniversarios.columns.IdClienteRecomendacao
            .accessor,
        resizable: false
      }
    ];

    return (
      <div>
        <hr />
        <DialogInformation
          open={this.state.open}
          onClose={this.handleClose}
          useCustomData={false}
          clientId={this.state.clientId}
        />
        <ReactTable
          className="-highlight tableReact"
          data={this.state.Birthdays}
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                height: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader
              }
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.Birthdays.length >
              Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={this.props.intl.formatMessage({ id: "table.config.rowsText" })}
          columns={columnsGetBirthday}
        />
      </div>
    );
  }
}
export default injectIntl(Aniversarios);
