import ReactGA from "react-ga";

class DashboardAnalytics {
  constructor() {
    this.visualizarDetalhesOcorrencia = this.visualizarDetalhesOcorrencia.bind(
      this
    );
    this.tratarOcorrencia = this.tratarOcorrencia.bind(this);
    this.tornarOcorrenciaPendente = this.tornarOcorrenciaPendente.bind(this);
    this.proximasOcorrencias = this.proximasOcorrencias.bind(this);
    this.anterioresOcorrencias = this.anterioresOcorrencias.bind(this);
    this.selecionarPaginaçãoOcorrencias = this.selecionarPaginaçãoOcorrencias.bind(
      this
    );
    this.selecionarNumeroItensOcorrencias = this.selecionarNumeroItensOcorrencias.bind(
      this
    );
  }

  visualizarDetalhesOcorrencia() {
    ReactGA.event({
      category: "[MyIHTP][Dashboard][Ocorrencias]",
      action: "click",
      label: "Visualizar detalhes ocorrência"
    });
  }

  tratarOcorrencia() {
    ReactGA.event({
      category: "[MyIHTP][Dashboard][Ocorrencias]",
      action: "click",
      label: "Tratar ocorrência"
    });
  }

  tornarOcorrenciaPendente() {
    ReactGA.event({
      category: "[MyIHTP][Dashboard][Ocorrencias]",
      action: "click",
      label: "Tornar ocorrência pendente"
    });
  }

  proximasOcorrencias() {
    ReactGA.event({
      category: "[MyIHTP][Dashboard][Ocorrencias]",
      action: "click",
      label: "Botão próxima"
    });
  }

  anterioresOcorrencias() {
    ReactGA.event({
      category: "[MyIHTP][Dashboard][Ocorrencias]",
      action: "click",
      label: "Botão anterior"
    });
  }

  selecionarPaginaçãoOcorrencias() {
    ReactGA.event({
      category: "[MyIHTP][Dashboard][Ocorrencias]",
      action: "click",
      label: "Selecionar paginação"
    });
  }

  selecionarNumeroItensOcorrencias() {
    ReactGA.event({
      category: "[MyIHTP][Dashboard][Ocorrencias]",
      action: "click",
      label: "Selecionar número de itens"
    });
  }
}

const analytics = new DashboardAnalytics();

export default analytics;
