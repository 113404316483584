import React, { Component } from 'react'
import { injectIntl } from "react-intl";
import { Col, Row } from 'react-bootstrap';
import ReactTable from 'react-table';
import Configs from "../../../../../../helpers/Configs";
import { ToastContainer } from "react-toastr";
import { getPersonalStockNotifications } from '../../../../../../actions/myihtpActions';
import { removePersonalStockNotification } from '../../../../../../actions/miniCartActions';
import { FormattedMessage } from "react-intl";

const columns = [
    {
        Header: <FormattedMessage id="myihtp.infopessoal.gestaoAvisos.table.idProduto" />,
        accessor: "Id",
        resizable: false,
        width: 200
    }, {
        Header: <FormattedMessage id="myihtp.infopessoal.gestaoAvisos.table.nomeProduto" />,
        accessor: "Name",
        resizable: false,
    },
    {
        Header: <FormattedMessage id="myihtp.infopessoal.gestaoAvisos.table.imagem" />,
        accessor: "Image",
        resizable: false,
        Cell: row => {
            return (<img style={{ maxWidth: "130px" }} src={row.value} alt="Imagem do Produto" />);
        }
    },
    {
        Header: <FormattedMessage id="myihtp.infopessoal.gestaoAvisos.table.email" />,
        accessor: "Email",
        resizable: false,
    },
    {
        Header: "",
        accessor: "Remover",
        width: 50,
        Cell: row => {
            return (
                <div>
                    <a>
                        <svg height="15px" viewBox="0 0 512 512.00115" width="15px" xmlns="http://www.w3.org/2000/svg">
                            <path d="m441.378906 70.621094h-105.929687v-34.691406c0-19.808594-16.121094-35.929688-35.929688-35.929688h-140.003906c-19.808594 0-35.929687 16.121094-35.929687 35.929688v34.691406h-105.929688c-9.75 0-17.65625 7.894531-17.65625 17.65625 0 9.757812 7.90625 17.652344 17.65625 17.652344h18.40625l14.652344 336.96875c1.679687 38.757812 33.386718 69.101562 72.175781 69.101562h213.257813c38.785156 0 70.488281-30.34375 72.171874-69.101562l14.652344-336.96875h18.40625c9.75 0 17.65625-7.894532 17.65625-17.652344 0-9.761719-7.90625-17.65625-17.65625-17.65625zm-281.863281-35.3125h140.003906l.617188 35.3125h-141.03125zm213.527344 406.070312c-.863281 19.792969-17.066407 35.3125-36.898438 35.3125h-213.257812c-19.828125 0-36.035157-15.519531-36.898438-35.3125l-14.585937-335.449218h316.226562zm0 0" />
                            <path d="m229.515625 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                            <path d="m300.136719 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                            <path d="m158.898438 406.070312c-9.753907 0-17.65625-7.898437-17.65625-17.65625v-211.863281c0-9.757812 7.902343-17.652343 17.65625-17.652343 9.75 0 17.652343 7.894531 17.652343 17.652343v211.863281c0 9.757813-7.902343 17.65625-17.652343 17.65625zm0 0" />
                        </svg>
                    </a>
                </div>
            );
        }
    }
]

let container;
class GestaoAvisos extends Component {

    constructor() {
        super();

        this.state = {
            avisos: [],
            page: 0,
            pageSize: 5,
            numberOfTotalPages: 1
        }
    }

    loadNotifications(page, pageSize) {

        //Se estivermos a mudar o numero de paginas, resetamos a pagina
        if (pageSize !== this.state.pageSize) page = 0;

        getPersonalStockNotifications(page, pageSize)
            .then(result => {

                //Se conseguirmos fazer o pedido com sucesso
                if (result.success === true) {
                    var data = [];

                    //Percorremos o objeto e colocamos se é produto ou pack
                    result.obj.dados.forEach(o => {
                        data.push({
                            Id: (o.ProdutoId != null ? o.ProdutoId : o.PackId),
                            Email: o.Email,
                            Name: (o.ProdutoId != null ? o.ProdutoNome : o.PackNome),
                            Image: (o.ProdutoId != null ? o.ProdutoNomeImagem : o.PackNomeImagem),
                            Hash: o.Hash
                        });
                    });

                    //Atualizamos a informação das páginas
                    this.setState(prevState => ({
                        ...prevState,
                        avisos: data,
                        page: page,
                        pageSize: pageSize,
                        numberOfTotalPages: Math.ceil(result.obj.total / prevState.pageSize)
                    }))
                } else {
                    container.clear();
                    container.error(result.message);
                }
            })
            .catch(err => {
                container.clear();
                container.error(this.props.intl.formatMessage({ id: "myihtp.infopessoal.gestaoAvisos.obterNotificacaoErro" }));
            })
    }

    //Método para remover a notificação
    removeNotification(hash) {
        removePersonalStockNotification(hash)
            .then(result => {
                if (result.success === true) {

                    this.loadNotifications(this.state.page, this.state.pageSize);
                    container.clear();
                    container.success(this.props.intl.formatMessage({ id: "myihtp.infopessoal.gestaoAvisos.removerNotificacao" }));
                } else {
                    container.clear();
                    container.error(result.message);
                }
            }).catch(err => {
                container.clear();
                container.error(this.props.intl.formatMessage({ id: "myihtp.infopessoal.gestaoAvisos.removerNotificacaoErro" }));
            })
    }

    render() {
        return (
            <div>
                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />
                <span style={{ display: "flex", justifyContent: "center" }}>
                    <b> <FormattedMessage id="myihtp.infopessoal.gestaoAvisos.titulo" /></b>
                </span>

                <Row style={{ marginTop: "20px" }}>
                    <Col>
                        <ReactTable
                            className="-highlight tableReact"
                            data={this.state.avisos}
                            getTheadProps={(state, rowInfo, column) => {
                                return {
                                    style: {
                                        fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                                        fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                                        height: Configs.myIHTP.tableConfig.heightHeader,
                                        color: Configs.myIHTP.tableConfig.colorHeader,
                                        backgroundColor:
                                            Configs.myIHTP.tableConfig.backgroundColorHeader
                                    }
                                };
                            }}
                            getPaginationProps={state => {
                                return {
                                    style: {
                                        color: Configs.myIHTP.tableConfig.colorHeader,
                                        backgroundColor:
                                            Configs.myIHTP.tableConfig.backgroundColorHeader,
                                        fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
                                    }
                                };
                            }}
                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    onClick: (e, handleOriginal) => {
                                        if (column.id === "Remover") {
                                            var confirm = window.confirm(this.props.intl.formatMessage({ id: "myihtp.infopessoal.gestaoAvisos.confirmacaoRemoverNotificacao" }));

                                            if (confirm) this.removeNotification(rowInfo.original.Hash);
                                        }
                                    },
                                    style: {
                                        "text-align": "center",
                                        "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
                                    }
                                };
                            }}
                            pageSizeOptions={[5, 15, 30]}
                            defaultPageSize={5}
                            showPagination={true}
                            showPaginationTop={false}
                            showPaginationBottom={true}
                            showPageJump={false}
                            sortable={false}
                            page={this.state.page}
                            pages={this.state.numberOfTotalPages}
                            pageSize={this.state.pageSize}
                            manual={true}
                            onFetchData={(state, instance) => {
                                this.loadNotifications(state.page, state.pageSize);
                            }}
                            previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
                            nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
                            noDataText={Configs.myIHTP.tableConfig.noDataText}
                            pageText={Configs.myIHTP.tableConfig.pageText}
                            ofText={Configs.myIHTP.tableConfig.ofText}
                            rowsText={this.props.intl.formatMessage({ id: "table.config.rowsText" })}
                            columns={columns}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default injectIntl(GestaoAvisos);