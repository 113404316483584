import React, { useEffect, useState } from 'react'
import { FaFacebookF } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import CrossDomainEventHandler from '../../../../helpers/CrossDomainEventHandler';
import { funnelStep } from '../../../../Analytics/DataLayer';
import { ToastContainer } from "react-toastr";
import { loginWithFacebook } from '../../../../actions/userActions';
import { getFacebookAppId } from '../../../../actions/geralActions';
import Routes from '../../../../helpers/Routes';

interface FacebookLoginProps {
    facebookAppId: string,
}

export interface FacebookLoginComponentProps {
    previousInCheckout: boolean
}

let container;
export default function FacebookLoginComponent(props: FacebookLoginComponentProps) {

    const [facebookLogin, setFacebookLogin] = useState<FacebookLoginProps | null>(null);

    const history = useHistory();
    const user = useSelector((state: any) => {
        return state.user;
    });

    useEffect(() => {
        loadFacebookAppId();
        return () => { }
    }, [])

    const loadFacebookAppId = async () => {
        try {
            var result = await getFacebookAppId();
            setFacebookLogin({
                facebookAppId: result.obj
            })
        } catch (exp) {
            setFacebookLogin(null);
        }
    }

    //Método de resposta de entrar com facebook
    const loginFacebookResponse = (response) => {
        try {
            if (response) {
                loginFacebook(response.name, response.email, response.id, response.accessToken);
            } else {
                container.clear();
                container.error(<FormattedMessage id="login.erro.dadosFacebook" />);
            }
        } catch (err) {
            container.clear();
            container.error(<FormattedMessage id="login.erro.dadosFacebook" />);
        }
    }

    //Método de erro ao tentar entrar com facebook
    const loginFacebookFailure = (error) => {
        if (error.status !== 'unknown') {
            container.clear();
            container.error(<FormattedMessage id="login.erro.dadosFacebook" />);
            return;
        }

        container.clear();
        container.error(<FormattedMessage id="login.erro.dadosFacebook" />);
    }

    const loginFacebook = (name: string, email: string, id: string, accessToken: string) => {
        loginWithFacebook(email, id, accessToken)
            .then(result => {

                //Se o pedido tiver sido efetuado com erro
                if (result.success !== true) {
                    container.clear();
                    container.warning(`${result.message}`);

                    const x: any = window;

                    // Enviar mensagem para GTM
                    x.dataLayer = x.dataLayer || [];
                    x.dataLayer.push({
                        event: "Login",
                        loginMessage: `Erro - ${result.message}`
                    });

                    //Se pedido efetuado com sucesso 
                } else {

                    //Se utilizador está registado
                    if (result.obj.isUserRegistered === true) {

                        const x: any = window;

                        funnelStep(2);
                        // Enviar mensagem para GTM
                        x.dataLayer = x.dataLayer || [];
                        x.dataLayer.push({
                            event: "Login",
                            loginMessage: "Sucesso",
                            userType: user.userType,
                            userID: user.userID
                        });

                        CrossDomainEventHandler.sendEvent();

                        //Se antes estavamos no checkout e viemos para aqui por causa do login
                        //mandamos de volta para o checkout apos fazer login
                        if (props.previousInCheckout === true) {
                            x.location.href = "/shop/checkout";
                        } else {
                            x.location.href = process.env.REACT_APP_MAIN_WEBSITE_SHOP_URL;
                        }

                        //Se utilizador não registado
                    } else {

                        //Mandamos as props do facebook para a página de registar
                        const pageType = {
                            pathname: Routes.account.registo,
                            state: {
                                facebookData: {
                                    id: id,
                                    name: name,
                                    email: email,
                                    accessToken: accessToken
                                }
                            }
                        }

                        history.push(pageType);
                    }
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <>
            <ToastContainer
                ref={ref => (container = ref)}
                className="toast-bottom-right"
            />

            {facebookLogin != null ? (
                <FacebookLogin
                    autoLoad={false}
                    appId={facebookLogin.facebookAppId}
                    fields="name,email,picture"
                    callback={loginFacebookResponse}
                    onFailure={loginFacebookFailure}
                    cssClass="facebookLoginButton"
                    disableMobileRedirect={true}
                    icon={<FaFacebookF />}
                    textButton={<FormattedMessage id="login.entrarFacebook" />}
                />) : null}
        </>
    )
}
